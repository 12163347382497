import React from 'react'
import * as Yup from 'yup';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField, MenuItem, Select, InputLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { User } from '../../../context/context'
import Page from '../../../components/Page';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import './test.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,

        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const AddTestSeries = () => {
    const navigate = useNavigate();
    // const { setRole } = useContext(User)

    const [category, setCategory] = React.useState('');
    const [type, setType] = React.useState('');
    const [users, usersSet] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [streamCategory, setStreamCategory] = useState([])
    const theme = useTheme();


    const LoginSchema = Yup.object().shape({

        testseries_name: Yup.string().required('Name is required'),
        exam_type: Yup.string().required('Exam type is required'),
        starting_date: Yup.string().required('Starting Date is required'),
        charges: Yup.string().required('charges is required'),
        language: Yup.string().required('language is required'),
        stream: Yup.string().required('Stream is required'),
        // no_of_test: Yup.string().required('No. Of Test is required'),
        validity: Yup.string().required('Validity is required'),
        description: Yup.string().required('Description is required'),
        // discount: Yup.string().required('Discount is required'),
        // charges: Yup.string().required('Charges is required'),
        is_active: Yup.boolean().required('Status Required is required'),
    });

    const defaultValues = {
        testseries_name: '',
        category: '',
        exam_type: '',
        stream: '',
        // subject: '',
        // teacherEmail: '',
        starting_date: '',
        is_active:'',
        discount: '0',
        charges: '1',
        description: '',
        language: '',
        remark: 'N/A',
        validity: '',

    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;




    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
        }

        fetchUsers();
    }, []);

    //Teacher List
    const [teacher, setteacher] = useState([]);
    const [allteachers, teacherSet] = useState([]);
    const [val, setVal] = useState(false)
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            teacherSet(responseJson.data);
            setLoading(false)

        }

        fetchUsers();
    }, []);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    //Date
    const [focus, setFocused] = React.useState(false);
    const [showStartingDate, setShowStartingDate] = React.useState(false);

    //Stream
    useEffect(() => {
        const token = localStorage.getItem("token");
        const batch = 'Batch'
        async function fetchUsers() {

            const fullResponseCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Stream`, {
                method: 'GET',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }

            });
            const responseJsonCatergory = await fullResponseCategory.json();
            setStreamCategory(responseJsonCatergory.data);
            setLoading(false)

        }

        fetchUsers();
    }, []);


    //Banner
    const [file, setFile] = useState()
    const [msg, setMsg] = useState('')
    const [focusThree, setFocusedThree] = React.useState(false);
    const [showBanner, setShowBanner] = React.useState(false);
    const changeHandler = async (event) => {
        setFile(event.target.files[0])
    };


    //Adding Details
    const onSubmit = async (data) => {

        if (file?.size && teacher.length !== 0) {

            setLoading(true)

            const formData = new FormData();
            formData.append('file', file);



            // teacher.map()
            teacher.map((item) => formData.append('teacher', item))

            // formData.append('teacher', teacher);

            formData.append('testseries_name', data.testseries_name);
            formData.append('exam_type', data.exam_type);
            formData.append('stream', data.stream);
            // formData.append('no_of_test', data.no_of_test);
            // formData.append('subject', data.subject);
            formData.append('starting_date', data.starting_date);

            formData.append('discount', data.discount);
            formData.append('charges', data.charges);

            formData.append('language', data.language);
            formData.append('remark', data.remark);
            formData.append('validity', data.validity);
            formData.append('description', data.description);
            formData.append('is_active', data.is_active);


            let token = localStorage.getItem("token");

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addTestSeries`, formData, config).then((response) => {
                //console.log(response.data);
                toast.success("Test Series Added")
                setShowBanner(false)

                setShowStartingDate(false)
                if (response.data.msg === 'Added the TestSeries SuccessFully') {

                    setTimeout(() => {
                        setLoading(false)
                        navigate('/dashboard/test')

                    }, 1000)
                    reset()
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        }

    }
    return (<>
        <Page title='Test Series'>
            <Stack spacing={3} ml={5}>
                <Typography className='page-heading'>Add Test series</Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}>
                        <RHFTextField name="testseries_name" label="Test Name " />
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            {/* <RHFTextField
                                // labelId="demo-simple-select-label"


                                label="Category"
                                name='category'
                                select

                                sx={{ width: '100%' }}
                            >
                                {users.map((item, i) => { return <MenuItem key={i} value='{item.title}'>{item.title}</MenuItem> })}

                            </RHFTextField> */}

                            <RHFTextField


                                select
                                name='exam_type'

                                label="Exam Type"

                                sx={{ width: '100%' }}
                            >
                                <MenuItem value='Prelims'>Prelims</MenuItem>
                                <MenuItem value="Mains">Mains</MenuItem>
                                <MenuItem value='Interview'>Interview</MenuItem>
                            </RHFTextField>
                        </Stack>
                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Teachers</InputLabel>
                            <Stack direction='column' sx={{ width: '100%' }}>
                                <Select
                                    // label    ="Name"
                                    // id="demo-multiple-name"
                                    // labelId="test-select-label"
                                    label=""
                                    multiple
                                    error={teacher.length == 0 && val}
                                    name='teacher'
                                    value={teacher}
                                    onChange={handleChange}

                                    sx={{ width: '100%', color: 'black', zIndex: 9 }}
                                    MenuProps={MenuProps}
                                >

                                    {allteachers?.map((name,index) => (
                                        <MenuItem
                                            key={index}
                                            value={name._id}
                                            style={getStyles(name, teacher, theme)}
                                        >
                                            {name.FullName}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {teacher.length == 0 && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                            </Stack>
                        </Stack>
                        <Stack direction='row' spacing={3}>   <RHFTextField name="starting_date"
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}
                            label="Starting Date"
                            onClick={() => setShowStartingDate(true)}
                            type={showStartingDate || focus ? "date" : "text"} />
                            <Stack direction="column" sx={{ width: '100%' }}>
                                <RHFTextField
                                    name="file"
                                    error={!file && val}
                                    onChange={changeHandler}
                                    onFocus={() => setFocusedThree(true)}
                                    onBlur={() => setFocusedThree(false)}

                                    InputProps={{
                                        classes: {
                                            input: "CustomTextField"
                                        }
                                    }}
                                    label="Banner"
                                    onClick={() => setShowBanner(true)}
                                    type={showBanner || focusThree ? "file" : "text"}
                                />
                                {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                                {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                            <RHFTextField name="charges" label="Charges" type="number" id="custom-css-outlined-input" />
                            <RHFTextField name="discount" label="Discount ( In percentage)" type="number" InputProps={{
                                inputProps: {
                                    max: 100, min: 0
                                }
                            }} />
                            <RHFTextField
                                name='language'
                                label="Language"
                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem value='en'>English</MenuItem>
                                <MenuItem value="hi">Hindi</MenuItem>
                                <MenuItem value='enhi'>Bilungual</MenuItem>
                            </RHFTextField>
                            <RHFTextField name="stream" label="Stream" select >
                                {streamCategory?.map((item, index) => {
                                    return <MenuItem key={index} value={item.title} >{item.title}</MenuItem>
                                })}
                            </RHFTextField>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            {/* <RHFTextField name="no_of_test" label="Total no. of tests" type='number' /> */}
                            <RHFTextField name="validity" label="Validity(In Months)" />
                            <RHFTextField name="remark" label="Remark" />
                            <RHFTextField
                                name='is_active'
                                label="Status"
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>

                            </RHFTextField>
                        </Stack>
                        <RHFTextField name="description" label="Description" multiline
                            rows={4} />



                        {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
                    </Stack>

                    {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: '#EA506B' }}>
                    Forgot password?
                </Link>
            </Stack> */}

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: '#EA506B', width: '80%', marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setMsg('Required')
                            setVal(true)
                            // setMsgDemoVideo('Demo Video Required')
                        }}
                    >
                        Add
                    </LoadingButton>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
            </Stack>
        </Page>
    </>
    )
}

export default AddTestSeries