import React, { useState } from 'react';
import { 
  Container, Grid, TextField, Button, 
  RadioGroup, FormControlLabel, Radio, Typography, Box, MenuItem, Select, IconButton, Divider 
} from '@mui/material';
import { 
  FormatBold, FormatItalic, FormatUnderlined, 
  InsertLink, FormatListBulleted, FormatListNumbered, 
  Image 
} from '@mui/icons-material';

const AddQuestions = () => {
  const [questionEn, setQuestionEn] = useState('');
  const [questionImageEn, setQuestionImageEn] = useState(null);
  const [optionsEn, setOptionsEn] = useState(Array(4).fill({ label: '', image: null }));
  const [selectedOptionEn, setSelectedOptionEn] = useState('');
  const [explanationEn, setExplanationEn] = useState('');
  
  const [questionHi, setQuestionHi] = useState('');
  const [questionImageHi, setQuestionImageHi] = useState(null);
  const [optionsHi, setOptionsHi] = useState(Array(4).fill({ label: '', image: null }));
  const [selectedOptionHi, setSelectedOptionHi] = useState('');
  const [explanationHi, setExplanationHi] = useState('');
  
  const [questions, setQuestions] = useState([]);

  const handleOptionChange = (index, value, language) => {
    const setOptions = language === 'en' ? setOptionsEn : setOptionsHi;
    const options = language === 'en' ? optionsEn : optionsHi;
  
    if (index < 0 || index >= options.length) {
      console.error('Invalid index');
      return;
    }
  
    const newOptions = options.map((option, i) => 
      i === index ? { ...option, label: value } : option
    );
  
    setOptions(newOptions);
  };
  
  const handleOptionImageChange = (index, event, language) => {
    const setOptions = language === 'en' ? setOptionsEn : setOptionsHi;
    const options = language === 'en' ? optionsEn : optionsHi;
  
    if (index < 0 || index >= options.length) {
      console.error('Invalid index');
      return;
    }
  
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const newOptions = options.map((option, i) => 
          i === index ? { ...option, image: reader.result } : option
        );
  
        setOptions(newOptions);
      };
  
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
  
      reader.readAsDataURL(file);
    }
  };
  

  const addOption = (language) => {
    const setOptions = language === 'en' ? setOptionsEn : setOptionsHi;
    const options = language === 'en' ? optionsEn : optionsHi;
    setOptions([...options, { label: '', image: null }]);
  };

  const handleQuestionImageChange = (file, language) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (language === 'en') {
          setQuestionImageEn(reader.result);
        } else {
          setQuestionImageHi(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddQuestion = () => {
    setQuestions(prevQuestions => [
      ...prevQuestions,
      {
        questionEn,
        questionImageEn,
        optionsEn: optionsEn.reduce((acc, option, index) => {
          const key = String.fromCharCode(65 + index); 
          acc[key] = option.label;
          return acc;
        }, {}),
        optionImagesEn: optionsEn.reduce((acc, option, index) => {
          const key = String.fromCharCode(65 + index); 
          acc[key] = option.image;
          return acc;
        }, {}),
        answerEn: selectedOptionEn,
        explanationEn,
        questionHi,
        questionImageHi,
        optionsHi: optionsHi.reduce((acc, option, index) => {
          const key = String.fromCharCode(65 + index); 
          acc[key] = option.label;
          return acc;
        }, {}),
        optionImagesHi: optionsHi.reduce((acc, option, index) => {
          const key = String.fromCharCode(65 + index); 
          acc[key] = option.image;
          return acc;
        }, {}),
        answerHi: selectedOptionHi,
        explanationHi
      }
    ]);
    setQuestionEn('');
    setQuestionImageEn(null);
    setOptionsEn(Array(4).fill({ label: '', image: null }));
    setSelectedOptionEn('');
    setExplanationEn('');
    
    setQuestionHi('');
    setQuestionImageHi(null);
    setOptionsHi(Array(4).fill({ label: '', image: null }));
    setSelectedOptionHi('');
    setExplanationHi('');
  };

  const applyFormat = (command) => {
    document.execCommand(command, false, null);
  };

  const insertLink = () => {
    const url = prompt('Enter the URL');
    if (url) {
      document.execCommand('createLink', false, url);
    }
  };

  const handleQuestionChange = (event, language) => {
    if (language === 'en') {
      setQuestionEn(event.target.innerHTML);
    } else {
      setQuestionHi(event.target.innerHTML);
    }
  };

  const handleExplanationChange = (event, language) => {
    if (language === 'en') {
      setExplanationEn(event.target.value);
    } else {
      setExplanationHi(event.target.value);
    }
  };

  const handleOptionSelect = (event, language) => {
    if (language === 'en') {
      setSelectedOptionEn(event.target.value);
    } else {
      setSelectedOptionHi(event.target.value);
    }
  };

  return (
    <Container>
      <Box mt={2} p={2}>
        <Grid container spacing={4}>
          {/* English Section */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom fontWeight="bold" color="#333">
              Question (English):
            </Typography>

            <Box border={1} borderColor="#e0e0e0" borderRadius={2} p={2} mb={3} sx={{ backgroundColor: '#f9f9f9' }}>
              <Box display="flex" mb={2}>
                <IconButton size="small" onClick={() => applyFormat('bold')}>
                  <FormatBold />
                </IconButton>
                <IconButton size="small" onClick={() => applyFormat('italic')}>
                  <FormatItalic />
                </IconButton>
                <IconButton size="small" onClick={() => applyFormat('underline')}>
                  <FormatUnderlined />
                </IconButton>
                <IconButton size="small" onClick={insertLink}>
                  <InsertLink />
                </IconButton>
                <IconButton size="small" onClick={() => applyFormat('insertOrderedList')}>
                  <FormatListNumbered />
                </IconButton>
                <IconButton size="small" onClick={() => applyFormat('insertUnorderedList')}>
                  <FormatListBulleted />
                </IconButton>
              </Box>

              <Box
                contentEditable
                
                onInput={(e) => handleQuestionChange(e, 'en')}
                sx={{ fontSize: '16px', backgroundColor: '#fff', padding: 2, minHeight: '100px' }}
              />
              {questionImageEn && <img src={questionImageEn} alt="Question" style={{ maxWidth: '100%', marginTop: '10px' }} />}
            </Box>

            <Typography color="black" fontWeight="bold" mt={1} mb={1}>
              Image (optional):
            </Typography>

            <Box display="flex" alignItems="center" mb={2}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  borderRadius: 2,
                  backgroundColor: '#F67382',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#f06292' },
                  mr: 2
                }}
              >
                Choose Image
                <input type="file" hidden onChange={(e) => handleQuestionImageChange(e.target.files[0], 'en')} />
              </Button>
              <Typography variant="body2" color="textSecondary">
                Supported Files: .jpg, .png
              </Typography>
            </Box>

            <Divider sx={{ mb: 3 }} />

            <Typography color="black" fontWeight="bold" mt={1} mb={1}>
              Options (English):
            </Typography>

            <Grid container spacing={2}>
              {optionsEn.map((option, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box border={1} borderColor="#e0e0e0" borderRadius={2} p={2} sx={{ backgroundColor: '#f9f9f9' }}>
                    <Box display="flex" alignItems="center">
                      <Radio
                        checked={selectedOptionEn === String.fromCharCode(65 + index)}
                        onChange={(e) => handleOptionSelect(e, 'en')}
                        value={String.fromCharCode(65 + index)}
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        placeholder={`Option ${String.fromCharCode(65 + index)}`}
                        value={option.label}
                        onChange={(e) => handleOptionChange(index, e.target.value, 'en')}
                        InputProps={{ disableUnderline: true }}
                        sx={{ fontSize: '16px', backgroundColor: '#fff', padding: 1.5 }}
                      />
                    </Box>
                    {option.image && (
                      <img
                        src={option.image}
                        alt={`Option ${String.fromCharCode(65 + index)}`}
                        style={{ maxWidth: '100%', marginTop: '5px' }}
                      />
                    )}
                    <Button
                      variant="text"
                      component="label"
                      sx={{ mt: 1, textTransform: 'none', color: '#F67382', '&:hover': { color: '#f06292' } }}
                    >
                      + Add Image
                      <input type="file" hidden onChange={(e) => handleOptionImageChange(index, e, 'en')} />
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Button
              variant="text"
              color="primary"
              sx={{ mt: 1, textTransform: 'none', color: 'black', '&:hover': { color: '#f06292' } }}
              onClick={() => addOption('en')}
            >
              + Add More Options
            </Button>

            <Typography color="black" fontWeight="bold" mt={2}>
              Explanation (English):
            </Typography>

            <TextField
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              margin="normal"
              placeholder="Enter explanation here"
              sx={{ backgroundColor: '#f9f9f9', borderRadius: 2, mt: 1 }}
              value={explanationEn}
              onChange={(e) => handleExplanationChange(e, 'en')}
            />
          </Grid>

          {/* Hindi Section */}
          <Grid item xs={12} md={6}>
  <Typography variant="h6" gutterBottom fontWeight="bold" color="#333">
    Question (Hindi):
  </Typography>

  <Box border={1} borderColor="#e0e0e0" borderRadius={2} p={2} mb={3} sx={{ backgroundColor: '#f9f9f9' }}>
    <Box display="flex" mb={2}>
      <IconButton size="small" onClick={() => applyFormat('bold')}>
        <FormatBold />
      </IconButton>
      <IconButton size="small" onClick={() => applyFormat('italic')}>
        <FormatItalic />
      </IconButton>
      <IconButton size="small" onClick={() => applyFormat('underline')}>
        <FormatUnderlined />
      </IconButton>
      <IconButton size="small" onClick={insertLink}>
        <InsertLink />
      </IconButton>
      <IconButton size="small" onClick={() => applyFormat('insertOrderedList')}>
        <FormatListNumbered />
      </IconButton>
      <IconButton size="small" onClick={() => applyFormat('insertUnorderedList')}>
        <FormatListBulleted />
      </IconButton>
    </Box>

    <Box
      contentEditable
      onInput={(e) => handleQuestionChange(e, 'hi')}
      sx={{ fontSize: '16px', backgroundColor: '#fff', padding: 2, minHeight: '100px', fontFamily: 'Noto Sans Devanagari, sans-serif' }}
    />
    {questionImageHi && <img src={questionImageHi} alt="Question" style={{ maxWidth: '100%', marginTop: '10px' }} />}
  </Box>

  <Typography color="black" fontWeight="bold" mt={1} mb={1}>
    Image (optional):
  </Typography>

  <Box display="flex" alignItems="center" mb={2}>
    <Button
      variant="contained"
      component="label"
      sx={{
        borderRadius: 2,
        backgroundColor: '#F67382',
        color: '#fff',
        '&:hover': { backgroundColor: '#f06292' },
        mr: 2
      }}
    >
      Choose Image
      <input type="file" hidden onChange={(e) => handleQuestionImageChange(e.target.files[0], 'hi')} />
    </Button>
    <Typography variant="body2" color="textSecondary">
      Supported Files: .jpg, .png
    </Typography>
  </Box>

  <Divider sx={{ mb: 3 }} />

  <Typography color="black" fontWeight="bold" mt={1} mb={1}>
    Options (Hindi):
  </Typography>

  <Grid container spacing={2}>
    {optionsHi.map((option, index) => (
      <Grid item xs={12} sm={6} key={index}>
        <Box border={1} borderColor="#e0e0e0" borderRadius={2} p={2} sx={{ backgroundColor: '#f9f9f9' }}>
          <Box display="flex" alignItems="center">
            <Radio
              checked={selectedOptionHi === String.fromCharCode(65 + index)}
              onChange={(e) => handleOptionSelect(e, 'hi')}
              value={String.fromCharCode(65 + index)}
            />
            <TextField
              variant="standard"
              fullWidth
              multiline
              placeholder={`Option ${String.fromCharCode(65 + index)}`}
              value={option.label}
              onChange={(e) => handleOptionChange(index, e.target.value, 'hi')}
              InputProps={{ disableUnderline: true }}
              sx={{ fontSize: '16px', backgroundColor: '#fff', padding: 1.5, fontFamily: 'Noto Sans Devanagari, sans-serif' }}
            />
          </Box>
          {option.image && (
            <img
              src={option.image}
              alt={`Option ${String.fromCharCode(65 + index)}`}
              style={{ maxWidth: '100%', marginTop: '5px' }}
            />
          )}
          <Button
            variant="text"
            component="label"
            sx={{ mt: 1, textTransform: 'none', color: '#F67382', '&:hover': { color: '#f06292' } }}
          >
            + Add Image
            <input type="file" hidden onChange={(e) => handleOptionImageChange(index, e, 'hi')} />
          </Button>
        </Box>
      </Grid>
    ))}
  </Grid>

  <Button
    variant="text"
    color="primary"
    sx={{ mt: 1, textTransform: 'none', color: 'black', '&:hover': { color: '#f06292' } }}
    onClick={() => addOption('hi')}
  >
    + Add More Options
  </Button>

  <Typography color="black" fontWeight="bold" mt={2}>
    Explanation (Hindi):
  </Typography>

  <TextField
    multiline
    rows={4}
    fullWidth
    variant="outlined"
    margin="normal"
    placeholder="Enter explanation here"
    sx={{ backgroundColor: '#f9f9f9', borderRadius: 2, mt: 1, fontFamily: 'Noto Sans Devanagari, sans-serif' }}
    value={explanationHi}
    onChange={(e) => handleExplanationChange(e, 'hi')}
  />
</Grid>

        </Grid>

        <Button 
          variant="contained" 
          color="primary" 
          sx={{ 
            mt: 3, 
            borderRadius: 2, 
            backgroundColor: '#F67382', 
            color: '#fff',
            '&:hover': { backgroundColor: '#f06292' }
          }}
          onClick={handleAddQuestion}
        >
          Add Question
        </Button>

        {/* <Box mt={4}>
          <Typography variant="h6" gutterBottom color="#333">
            Questions List
          </Typography>
          {questions.length > 0 ? (
            <ul>
              {questions.map((q, index) => (
                <li key={index}>
                  <Typography variant="body1" color="#333">
                    <strong>Q{index + 1}: </strong>
                    <div dangerouslySetInnerHTML={{ __html: q.questionEn }} />
                    {q.questionImageEn && (
                      <img
                        src={q.questionImageEn}
                        alt={`Question ${index + 1}`}
                        style={{ maxWidth: '100%', marginTop: '10px' }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Options (English):</strong>
                    <ul>
                      {Object.keys(q.optionsEn).map(option => (
                        <li key={option}>
                          {option}: {q.optionsEn[option]}
                          {q.optionImagesEn[option] && (
                            <img
                              src={q.optionImagesEn[option]}
                              alt={`Option ${option}`}
                              style={{ maxWidth: '100%', marginTop: '5px' }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Answer:</strong> {q.answerEn}
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Explanation:</strong> {q.explanationEn}
                  </Typography>
                  <Typography variant="body1" color="#333">
                    <strong>Question (Hindi): </strong>
                    <div dangerouslySetInnerHTML={{ __html: q.questionHi }} />
                    {q.questionImageHi && (
                      <img
                        src={q.questionImageHi}
                        alt={`Question ${index + 1}`}
                        style={{ maxWidth: '100%', marginTop: '10px' }}
                      />
                    )}
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Options (Hindi):</strong>
                    <ul>
                      {Object.keys(q.optionsHi).map(option => (
                        <li key={option}>
                          {option}: {q.optionsHi[option]}
                          {q.optionImagesHi[option] && (
                            <img
                              src={q.optionImagesHi[option]}
                              alt={`Option ${option}`}
                              style={{ maxWidth: '100%', marginTop: '5px' }}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Answer:</strong> {q.answerHi}
                  </Typography>
                  <Typography variant="body2" color="#666">
                    <strong>Explanation:</strong> {q.explanationHi}
                  </Typography>
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No questions added yet.
            </Typography>
          )}
        </Box> */}
      </Box>
    </Container>
  );
};

export default AddQuestions;
