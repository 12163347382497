import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import PeopleIcon from "@mui/icons-material/People";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F67382",
  "&:hover": {
    backgroundColor: "#e56372",
  },
}));

const CommunityCard = styled(Card)(({ theme }) => ({
  minWidth: 200,
  height: 200,
  margin: theme.spacing(1),
  textAlign: "center",
  backgroundColor: "rgba(236, 145, 145, 0.2)",
  position: "relative",
}));

const Community = ({ title, type,onEdit, onDelete, onFollow, onView }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleClose();
    if (onEdit) onEdit();
  };

  const handleDeleteClick = () => {
    handleClose();
    if (onDelete) onDelete();
  };

  const handleFollowClick = () => {
    handleClose();
    if (onFollow) onFollow();
  };

  const handleViewClick = () => {
    handleClose();
    if (onView) onView();
  };

  return (
    <CommunityCard>
      <CardContent>
        <PeopleIcon style={{ fontSize: 50, color: "#F67382" }} />
        <Typography variant="h5" component="div" style={{ color: "#F67382" }}>
          {title}
        </Typography>
        <IconButton
          aria-controls="community-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          style={{
            position: "absolute",
            bottom: 5,
            right: 5,
            color: "#F67382",
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="community-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleViewClick}>View</MenuItem>
          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
          <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
          {type === "private" && (
            <MenuItem onClick={handleFollowClick}>Follow Requests</MenuItem>
          )}
        </Menu>
      </CardContent>
    </CommunityCard>
  );
};

const CommunitySection = () => {
  const navigate = useNavigate();
  const [communities, setCommunities] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCommunities, setFilteredCommunities] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchCommunities();
  }, []);

  useEffect(() => {
    setFilteredCommunities(
      communities.filter((community) =>
        community.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, communities]);

  const fetchCommunities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/get-communities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        setCommunities(response.data.data);
      } else {
        throw new Error(response.data.msg || "Failed to fetch communities");
      }
    } catch (error) {
      console.error("Error fetching communities:", error.message);
    }
  };

  const handleNewCommunityClick = () => {
    navigate("/dashboard/community/new");
  };

  const handleEditCommunity = (communityId) => {
    navigate(`/dashboard/community/${communityId}/edit`);
  };

  const handleDeleteCommunity = async (communityId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/delete-community/${communityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        fetchCommunities(); // Refresh communities after deletion
        toast.success("Community deleted successfully.");
      } else {
        throw new Error(response.data.msg || "Failed to delete community");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.msg === "User Not authorised"
      ) {
        console.error("User is not authorized to delete this community.");
      } else {
        console.error("Error deleting community:", error.message);
      }
    }
  };

  const handleFollowCommunity = (communityId) => {
    navigate(`/dashboard/community/${communityId}/follow`);
  };

  const handleViewCommunity = (communityId) => {
    navigate(`/dashboard/community/${communityId}/details`);
    // Navigate to CommunityDetails component with communityId
  };

  return (
    <Box p={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">UPSC Hindi Communities</Typography>
        <CustomButton
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleNewCommunityClick}
        >
          New Community
        </CustomButton>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        width="100%"
      >
        <Typography variant="h6">
          {filteredCommunities.length} Communities
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Search Communities"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon style={{ color: "#F67382" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          style={{ marginLeft: "auto" }}
        />
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2}>
        {filteredCommunities.map((community) => (
          <Community
            key={community._id}
            title={community.name}
            type={community.type}
            onEdit={() => handleEditCommunity(community._id)}
            onDelete={() => handleDeleteCommunity(community._id)}
            onFollow={() => handleFollowCommunity(community._id)}
            onView={() => handleViewCommunity(community._id)}
          />
        ))}
      </Box>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
      />
    </Box>
  );
};

export default CommunitySection;
