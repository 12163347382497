import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import Iconify from './Iconify';
import { navConfigForTeacher } from '../layouts/dashboard/NavConfigForTeacher';
// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, subNav } = item;



  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: '#EA506B',
    fontWeight: 'fontWeightMedium',
    bgcolor: 'rgba(255, 137, 158, 0.12)',
  };

  const activeSubStyle = {
    color: '#EA506B',
    bgcolor: 'rgba(255, 137, 158, 0.12)',
    fontWeight: 'fontWeightMedium',

  };


  if (subNav) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),

          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText primary={title} />

          <Iconify
            icon={open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding >
            {subNav.map((item, index) => {
              const { title, path, navicon, } = item;
              // console.log(navicon);
              const isActiveSub = active(path);
              const role = localStorage.getItem('role')
              let it;

              if (role === 'Admin' && title === 'Add Resources') {
                return <></>
              }
              if (role === 'Admin' && title === 'All Resources') {
                return <></>
              }


              return (
                <>{
                  title ? (<ListItemStyle
                    key={index}
                    component={RouterLink}
                    to={path ? path : ''}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      pl: 2,
                      '&:hover': {
                        bgcolor: 'rgba(255, 137, 158, 0.12)'
                        // bgcolor: '#7f11e026'
                      }
                    }}
                  >
                    <ListItemIconStyle>
                      {/* <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                        /> */}
                      {/* // >{navlink && navlink}</Box> */}
                      {navicon?.props && navicon}


                    </ListItemIconStyle>
                    {/* <Iconify icon={navicon} width={22} height={22} /> */}
                    <ListItemText disableTypography primary={title} sx={{ fontSize: '14px', fontWeight: '500' }}></ListItemText>
                  </ListItemStyle>) : null}</>
              )
                ;
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (<>
    <ListItemStyle
      component={RouterLink}
      to={path ? path : 'app'}

      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,

};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  // console.log(navConfig);
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const role = localStorage.getItem('role')
  return (
    <>
      {role === 'Teacher' || role == 'subadmin' || role !== 'Admin' ? (<Box {...other}>
        < List disablePadding sx={{ p: 1 }}>
          {navConfigForTeacher.map((item, i) => {
            
            return <>
              {item.title ? (<NavItem key={i} item={item} active={match} />) : ''}
            </>
          })}
        </List >
      </Box >) :
        (<Box {...other}>
          <List disablePadding sx={{ p: 1 }}>
            {navConfig.map((item, i) => {
              let it;

              const role = localStorage.getItem('role')
              const arr = ['Students', 'Manage Staff', 'Testimonial', 'Resources']

              {/* //console.log(navConfigForTeacher) */ }
              for (it = 0; it < arr.length; it++) {
                if (role === 'Teacher' && item.title === arr[it]) {
                  return <></>
                }
              }


              return <NavItem key={i} item={item} active={match} />
              {/* return <>d.access</> */ }




            })}
          </List>
        </Box >)
      }
    </>
  );
}
