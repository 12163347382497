


import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';

import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
import { ExportCSV } from '../../../utils/ExportCSV';


// for Dialog box
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from "axios"; // for axios request

import { CSVLink } from 'react-csv'; // for CSV file download 
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: '', label: 'S.No', alignRight: false },
    { id: 'FullName', label: 'Full Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'mobileNumber', label: 'Mobile No.', alignRight: false },
    { id: 'Role', label: 'Role', alignRight: false },
    // { id: 'subject', label: 'Subjects', alignRight: false },
    // { id: 'language', label: 'Language', alignRight: false },
    { id: 'created_at', label: 'Date', alignRight: false },
    // { id: 'is_active', label: 'Status', alignRight: false },


];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.FullName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TeacherTable() {

    // Code for handling Dialog box
    const [download, setDownload] = useState(true);
    const [errorOTP, setErrorOtp] = useState();
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const sendOtp = () => {
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const data = {
            action: "Exporting All Staffs Data"
        };

        axios
            .post(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
                data,
                config
            )
            .then((response) => {
            })
            .catch((e) => {
                //console.log(e);
            });

    }
    const verifyOtp = () => {
        //console.log(document.getElementById('name').value);
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const data = {
            otp: document.getElementById('name').value
        };

        axios
            .post(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
                data,
                config
            )
            .then((response) => {
                // //console.log(response);
                if (response.data.status == false) {
                    setErrorOtp("Invalid Otp");
                }
                else {
                    setDownload(false);
                    setErrorOtp("Successfully Verified");
                }
            })
            .catch((e) => {
                //console.log(e);
            });
    }

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate()
    const [users, usersSet] = useState()
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/getteacherListByAdmin`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            setLoading(false)

        }

        fetchUsers();
    }, []);



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.FullName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const filteredUsers = applySortFilter(users ? users : ['no'], getComparator(order, orderBy), filterName);
    let helperArray = [];
    // console.log(filteredUsers)
    filteredUsers?.map((item) => helperArray.push({
        ID: item?._id, photoUrl: item?.profilePhoto, FullName: item?.FullName, email: item?.email, role: item?.Role, qualification: item?.qualification, Created_at: item?.created_at, is_active: item?.is_active, is_special: item?.is_Special
        , accessToContent: item.accessToContent, mobileNumber: item?.mobileNumber, subject: item?.subject
    }))
    const isUserNotFound = filteredUsers.length === 0;

    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '60vh' }}> <SyncLoader color='red' loading={loading} size={20} /></Stack>) :
            (<Page title="Teachers">
                <Container sx={{ position: 'absolute', minWidth: '100%' }}>


                    <Card>
                        <Stack pr={3} direction='row' justifyContent='space-between' alignItems='center' >
                            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                            {/* <ExportCSV csvData={filteredUsers} fileName='Staff Data' /> */}

                            <Button variant="outlined" onClick={() => { handleClickOpen(); sendOtp(); }}>
                                Export
                            </Button>
                            <Dialog open={open} onClose={handleClose}>
                                {download ? (
                                    <>
                                        <DialogTitle>OTP Varification</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                OTP is send successfully to your regisered e-mail.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="OTP Number"
                                                type="number"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <DialogContentText sx={{ color: "red", fontSize: '12px', padding: "3px" }}>
                                                {errorOTP}
                                            </DialogContentText>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={handleClose}>Cancel</Button>
                                            <Button onClick={verifyOtp}>Verify</Button>
                                        </DialogActions>
                                    </>
                                ) : (
                                    <>
                                        <DialogTitle>Download File</DialogTitle>
                                        <DialogContent>
                                            <CSVLink data={helperArray} filename="AllStaffs.csv">Download</CSVLink>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => {
                                                handleClose();
                                                setDownload(true);
                                            }}>Cancel</Button>

                                        </DialogActions>
                                    </>
                                )}
                            </Dialog>


                        </Stack>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={users?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { id, FullName, _id, email, mobileNumber, created_at, is_active, avatarUrl, subject, profilePhoto, Role } = row;
                                            const isItemSelected = selected.indexOf(FullName) !== -1;
                                            const TDate = created_at?.split(" ")[0]
                                            return (
                                                <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, FullName)} />
                                                    </TableCell> */}
                                                    <TableCell  >
                                                        <Typography variant='subtitle2' >{index + 1 + rowsPerPage * page}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" pl={2}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={FullName} src={profilePhoto} sx={{ width: 28, height: 28 }} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {FullName}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{email}</TableCell>
                                                    <TableCell align="left">{mobileNumber}</TableCell>
                                                    <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{Role}</TableCell>
                                                    {/* <TableCell align="left">{subject.title}</TableCell> */}
                                                    <TableCell align="left">{TDate}</TableCell>
                                                    {/* <TableCell align="left">{is_active === 'false' ? 'Active' : 'Inactive'}</TableCell> */}
                                                    {/* <TableCell align="left">{username}</TableCell> */}
                                                    {/* <TableCell align="left">
                                                        <Button variant='outlined' size="small" color='error' onClick={() => navigate(`/dashboard/teacher/teacherProfile/${_id}`)}>view</Button>
                                                    </TableCell> */}


                                                    <TableCell align="right">
                                                        <UserMoreMenu id={_id} title='Staff' req='delete_techer' />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>)}
    </>
    );
}


























