import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, Select, InputLabel, Card, Box, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { useTheme } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { id } from 'date-fns/locale';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import "./form.css"
import MultiLevelSelect from 'react-select-multi-level';


// import optionsForTeacher from "../../../data/tabArray"
import options from "../../../data/tabArray"






// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function AddTeacherForm() {
    // console.log(options)
    const theme = useTheme();
    const navigate = useNavigate();
    const [fetchMessage, setFetchMessage] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const [positive, setPositive] = useState(false)
    const [users, usersSet] = useState()
    const [teacher, setteacher] = useState([]);
    const [batch, setbatch] = useState([]);
    const [lecture, setLecture] = useState([]);
    const [accessToContent, setAaccessToContent] = useState([]);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState('')
    const [accessForTab, setAccesForTab] = useState('read')
    const [deleteAccess, setDeleteAcess] = useState(false)
    const [selected, setSelected] = useState(false)

    const RegisterSchema = Yup.object().shape({
        FullName: Yup.string().required('First name required'),
        // lastName: Yup.string().required('Last name required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        mobile: Yup.string().length(10).required('Mobile No. is required'),
        password: Yup.string().required('Password is required'),
        // Subject: Yup.string().required('Subject is required'),
        // qualification: Yup.string().required('qualification is required'),
        is_Special: Yup.boolean().default(true),
    });

    const defaultValues = {
        FullName: '',
        email: '',
        mobile: '',
        password: '',
        // Subject: '',
        // qualification: 'be',
        is_Special: true
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    const [permissionValidation, setPermissionValidation] = useState({ set: false, text: '' })
    const onSubmit = async (data) => {
        // let check = 

        if (permissionValidation?.set || true) {
            setLoading(true)
            let token = localStorage.getItem("token");
            try {

                const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/addNewTeacher`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                    body: JSON.stringify({ ...data, ejective: true, Subject: teacher ? teacher : '', TeacherAccess: permission, qualification: 'be', access: accessForTab, deleteAccess: deleteAccess, batch: batch })
                })
                const result = await res.json()


                if (result.msg === 'teacher details added successfully') {
                    toast.success('Staff Added')
                    setLoading(false)
                    reset()
                } else if (result.msg === 'teacher Already exists ') {
                    toast.error('Staff Exist with these credentials')
                    setLoading(false)
                    // reset()
                }
                else {
                    toast.error(result.msg);
                    reset();
                    setLoading(false);
                    // window.location.href="/dashboard/staff";
                }
            } catch (error) {
                console.log(error);
            }
        } else setPermissionValidation({ set: false, text: 'Mandatory' })
        // navigate('/dashboard', { replace: true });
    };
    //Getting Subjects
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            // setLoading(false)

        }

        fetchUsers();
    }, []);
    const handleChangeLecture = (event) => {
        const {
            target: { value },
        } = event;
        setLecture(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeBatch = (event) => {
        const {
            target: { value },
        } = event;
        setbatch(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    const teacherTabs = [
        {
            name: "All Batches"
        },
        {
            name: "Add Quiz"
        },
        {
            name: "Add Test Series"
        },
        {
            name: "Assignment"
        },
        {
            name: "Help and Support"
        },
        {

            name: "Recorded Video"
        },
        {

            name: "Pathyakram"
        },
        {

            name: "Notes"
        },
        {

            name: "AIR"
        },
        {

            name: "News Clip"
        },
        {

            name: "Teacher Generated Content Of Batch"
        }
    ]

    const handleChangeAccess = (event) => {
        const {
            target: { value },
        } = event;
        setAaccessToContent(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const moderatorAccess = [{

        label: "Batch", value: ['All Batches', 'All Lecture']


    }]


    // const [showList, setShowList] = useState(false)
    // const [show, setShow] = useState(false)
    // const [showId, setShowId] = useState('')
    const [permission, setPermission] = useState(role == 'teacher' ? ['All Batches', "All Lecture", "All Coupon"] : role == 'moderator' ? moderatorAccess[0]?.value : [])
    // const [firstClick, setFirstClick] = useState({ name: '', click: true })
    // console.log('permission', permission)
   const handleMultiLevelSelect = (e) => {
    if (!e || !e.length) {
        setPermission([]);
        setSelected(false);
        setAccesForTab('read');
        return;
    }

    const pushArray = (a) => {
        if (a?.options) {
            return a.options?.map((ii) => ii.value);
        } else {
            return a.value?.map((ii) => ii);
        }
    };

    const final = [];
    const pushNestedArray = (arr) => {
        return arr.map((item) => final.push(item));
    };

    const helperArray = [];
    e?.map((item) => {
        helperArray.push(pushArray(item));
    });
    helperArray.map((item) => pushNestedArray(item));

    setPermission(final);

    let found = false;
    for (let i = 0; i < e[0]?.options?.length; i++) {
        if (e[0].options[i].label.slice(0, 4) === 'Add ') {
            found = true;
            break;
        }
    }

    setSelected(found);
    setAccesForTab(found ? 'readWrite' : 'read');
};

    useEffect(() => {
        setPermission(role == 'teacher' ? ['All Batches', "All Lecture", "All Coupon"] : role == 'moderator' ? moderatorAccess[0]?.value : [])
    }, [role])
    const [batch_id, setBatchId] = useState()
    //batch and lecture details
    //Lecture list
    const [lectureDetails, setLectureDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails?BatchId=${batch_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setLectureDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])



    //Lecture list


    const [batchDetails, setBatchDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setBatchDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])
    // console.log('batch details', batchDetails)
    // console.log('delete', deleteAccess)
    // console.log('lecture details', lectureDetails)
    return (<>
        <Stack spacing={3} px={5} height={'auto'}>
            <Typography variant='h3'>Add Staff</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }} minHeight={'110vh'} maxHeight={'auto'}>


                    <RHFTextField name="FullName" label="Name" />
                    <RHFTextField name="email" label="Email address" />
                    <RHFTextField name="mobile" label="Mobile No." type='number' />
                    <RHFTextField name="Role" label="Role" select>
                        <MenuItem value='teacher' onClick={() => setRole('teacher')}>Teacher</MenuItem>
                        <MenuItem value="subadmin" onClick={() => setRole('subadmin')}>Sub-Admin</MenuItem>
                        <MenuItem value="moderator" onClick={() => setRole('moderator')}>Moderators</MenuItem>
                    </RHFTextField>
                    <RHFTextField
                        name="password"
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {role !== 'subadmin' && role !== 'moderator' && < Stack direction="row">
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Subjects</InputLabel>
                        <Select
                            // label    ="Name"
                            // id="demo-multiple-name"
                            // labelId="test-select-label"
                            label=""
                            multiple
                            name='Subject'
                            value={teacher}
                            onChange={handleChange}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {users?.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name._id}
                                    style={getStyles(name, teacher, theme)}
                                >
                                    {name.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>}
                    {role == 'moderator' && < Stack direction="row">
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Batch</InputLabel>
                        <Select
                            // label    ="Name"
                            // id="demo-multiple-name"
                            // labelId="test-select-label"
                            label=""
                            multiple
                            name='batch'
                            value={batch}
                            onChange={handleChangeBatch}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {batchDetails?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item._id}
                                    onClick={() => setBatchId(item?._id)}
                                    style={getStyles(item, batch, theme)}
                                >
                                    {item?.batch_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>}

                    <Stack direction="column" sx={{ width: '100%' }}>


                        {role != 'moderator' && <Stack direction="row"> <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Tabs</InputLabel>
                            <MultiLevelSelect
                                options={role == 'teacher' ? options?.optionsForTeacher : options?.options}
                                className='selectClass'
                                placeholder=' '
                                onChange={(e) => handleMultiLevelSelect(e)}
                            /></Stack>}



                        {!permissionValidation?.set && role == 'subadmin' && < Typography color='red' sx={{ marginLeft: '150px' }}> {permissionValidation?.text}</Typography>}

                        {/* <Select

                            label=""
                            multiple
                            name='TeacherAccess'
                            value={accessToContent}
                            onChange={handleChangeAccess}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {role !== 'teacher' && tabsArray?.map((item, i) => (

                                <MenuItem
                                    key={i}
                                    value={item.name}
                                    style={getStyles(item, accessToContent, theme)}
                                >
                                    {item.name}
                                    {item?.subNav && item?.subNav?.map((i, index) => i.name)}
                                </MenuItem>

                            ))}
                            {role == 'teacher' && teacherTabs?.map((item, i) => (<MenuItem
                                key={i}
                                value={item.name}
                                style={getStyles(item, accessToContent, theme)}
                            >
                                {item.name}
                            </MenuItem>
                            ))}
                        </Select> */}
                        {/* <ul style={{ border: '1px solid lightgray', position: 'relative', borderRadius: '4px', paddingLeft: '15px', paddingTop: '10px', paddingBottom: '10px', cursor: 'pointer', maxHeight: '50px', width: '100%' }}>
                            <li onClick={() => {
                                setShowList(!showList)
                            }} style={{ listStyle: "none", height: '40px' }}>{permission.length !== 0 ? permission?.map((per, key) => `${per},`) : ''}</li>

                            <Box style={{ display: showList ? 'block' : 'none', background: 'white', position: 'absolute', width: '95%', borderRadius: "10px", zIndex: '100', boxShadow: '0px 5px 5px -3px rgba(145, 158, 171, 0.2), 0px 8px 10px 1px rgba(145, 158, 171, 0.14), 0px 3px 14px 2px rgba(145, 158, 171, 0.12)', maxHeight: '200px', overflowX: 'scroll' }}>
                                {sampleTabArray.map((item, index) => {
                                    return <><li style={{ display: showList ? 'block' : 'none', marginLeft: '10px', marginTop: '10px  ', marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} onClick={() => {
                                        setShowId(item?.id)
                                        setShow(!show)
                                        if (!item?.subList) {
                                            setPermission((prev) => [...prev, item?.name])
                                        }
                                    }}>{item?.name} {item?.subList && <ArrowDropDownIcon />}</li>
                                        {item?.id === showId && item?.subList?.map((nav, i) => <li style={{ display: show ? 'block' : 'none', marginLeft: '20px', marginBottom: '10px ' }} onClick={() => {

                                            // setFirstClick((prev) => [{ name: [nav?.name], click: [!firstClick.click] }])
                                            // console.log(firstClick)
                                            // handlePermissionChange(nav)

                                        }}>{nav?.name}</li >)}
                                    </>
                                })}

                            </Box>
                        </ul> */}

                    </Stack>

                    {permission.length > 0 && <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '90px', alignItems: 'center' }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Access Level</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="female" onClick={() => setAccesForTab('read')} control={<Radio checked={selected === false} />} label="Read Only" />
                            <FormControlLabel value="male" onClick={() => setAccesForTab('readWrite')} control={<Radio checked={selected === true} />} label="Read & Write " />
                            <FormControlLabel value="other" control={<Checkbox {...label} checked={deleteAccess} onChange={(e) => setDeleteAcess(e.target.checked)} />} label="Delete Access" />
                            {/* <FormControlLabel
                                value="disabled"
                                disabled
                                control={<Radio />}
                                label="other"
                            /> */}
                        </RadioGroup>
                    </FormControl>
                    }
                    {/* <Stack direction="row" spacing={3}>


                        <RHFTextField name='qualification' label='Qualification' helperText='Add Only Valid Qualification' />



                    </Stack> */}

                    {/* <Stack >
                        <RHFTextField name='is_Special' label='Special' select>
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                        </RHFTextField>
                    </Stack> */}




                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Register
                    </LoadingButton>
                </Stack>
            </FormProvider >
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack >
    </>
    );
}
