import { Box, Card, CardMedia, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import './batchDetailsCard.css'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { useState } from 'react';
import { useEffect } from 'react';

import "../style.css";

const convertYouTubeURLToEmbedURL = (url) =>{
    const urlPattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|youtu.be\/([a-zA-Z0-9_-]+)/;
    const match = url.match(urlPattern);
    if (match) {
      const videoID = match[1] || match[2];
      if (videoID) {
        const embedURL = `https://www.youtube.com/embed/${videoID}`;
        return embedURL;
      }
    }
  
    return null;
  }
  

const BatchDetailsCard = ({ batchId, setBatchName }) => {
    const iconStyle = {
        background: 'rgba(255, 122, 130, 0.2)',
        padding: '5px',
        color: 'rgba(249, 83, 104, 1)',
        borderRadius: '50%',
        cursor: 'pointer'
    }
    const [sub, setSub] = useState(0)
    const [tea, setTea] = useState(0)
    const [demo, setDemo] = useState(0)
    const scrollSubject = {
        transition: 'all 0.3s'
        , translate: `${sub}px`

    }
    const scrollTeacher = {
        transition: 'all 0.3s'
        , translate: `${tea}px`

    }
    const scrollDemo = {
        transition: 'all 0.3s'
        // translate:`translateX(${demo}px)`
        , translate: `${demo}px`

    }


    //Getting Batch Details
    const [batch, setBatch] = useState([])
    const [link, setLink] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchBatchDetails() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails?id=${batchId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJson = await fullResponse.json();

            setBatch(responseJson.data[0]);
            setBatchName(responseJson.data[0]?.batch_name)
            setLink(responseJson.data[0]?.banner[0]?.fileLoc)
        }
        fetchBatchDetails()
    }, [])

    return (
        <Card sx={{ minHeight: '100%', margin:'0px 10px 100px 10px'}}>
            <Stack direction="column" sx={{ minHeight: '80vh' }}>
                <CardMedia
                    component="img"
                    height="140"
                    width="87%"
                    // style={{ objectFit: 'contain' , borderRadius: '5px' , margin:0}}
                    image={link}
                    alt="Banner"
                />
                {/* <Box sx={{ width: '100%', minHeight: '160px', background: '#FFC8C8' }}>
                    <img src={link} sx={{ objectFit: 'contain' }} />
                </Box> */}
                <Typography color="#F95858" m={1} sx={{ fontWeight: '600', textAlign: 'center' }}>{batch?.batch_name} </Typography>
                <Divider />
                <div className='batch_desc'
                    dangerouslySetInnerHTML={{
                        __html:
                            batch?.description
                    }}
                ></div>
                {/* <Typography color="#727070" m={1} ml={2} mr={2} sx={{ fontWeight: '400', fontSize: '15px' }}>{batch?.description}</Typography> */}
                {batch?.remark != "" && <Typography color="#727070" m={1} mt={0} ml={2} mr={2} sx={{ fontWeight: '400', fontSize: '15px' }}><span style={{ color: '#4A4A4A', fontWeight: '600' }}>Remark-</span>{batch?.remark}</Typography>}
                <Divider />
                <Stack direction="row" justifyContent="space-between" m={1} ml={2} mr={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {/* <Typography p={1} sx={{ borderRadius: '44px', background: '#FFC8C8', fontSize: '10px' }}>L</Typography> */}
                        <TranslateIcon fontSize="medium" sx={{ borderRadius: '50%', background: '#FFC8C8', padding: '4px' }} />
                        <Typography fontSize='small' fontWeight="700">{batch?.language == 'en' ? 'English' : batch?.language == 'hi' ? 'हिंदी' : 'हिंदी/English'}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <div style={{ borderRadius: '50%', background: '#FFC8C8', fontSize: '10px', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>B</div>
                        <Typography fontSize='small' fontWeight="700">{batch?.category?.title}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <div style={{ borderRadius: '50%', background: '#FFC8C8', fontSize: '10px', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>ET</div>
                        <Typography fontSize='small' fontWeight="700">{batch?.exam_type}</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <div style={{ borderRadius: '50%', background: '#FFC8C8', fontSize: '10px', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>V</div>
                        <Typography fontSize='small' fontWeight="700"> {batch?.validity}m Validity</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Typography color="#F95858" mt={1} sx={{ fontWeight: '500', textAlign: 'center' }}>Teachers</Typography>
                <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
                    <ArrowBackIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setTea(tea + 50)} />
                    <Stack direction="row" spacing={2} ml={1} mr={1} className="TeacherScroll" sx={{ flex: 3 }}>
                        {batch?.teacher?.map((item, index) => <Typography key={index} mb={1} sx={{ fontSize: '14px', ...scrollTeacher }}>{item?.FullName}</Typography>)}
                    </Stack>
                    <ArrowForwardIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setTea(tea - 50)} />
                </Stack>
                <Divider />
                <Typography color="#F95858" mt={1} sx={{ fontWeight: '500', textAlign: 'center' }}>Subject</Typography>
                <Stack direction="row" ml={2} mr={2} justifyContent="space-between">
                    <ArrowBackIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setSub(sub - 50)} />
                    <Stack direction="row" spacing={2} ml={1} mr={1} className="SubjectScroll" sx={{ flex: 3 }} >
                        {batch?.subject?.map((item, index) => <Typography key={index} mb={1} sx={{ fontSize: '14px', ...scrollSubject }} >{item?.title}</Typography>)}
                    </Stack>
                    <ArrowForwardIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setSub(sub + 50)} />
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" alignItems="center" m={1} ml={2} mr={2}>
                    <Typography color="#4A4A4A" sx={{ fontWeight: '600', fontSize: '20px' }}>Batch Amount</Typography>
                    <Stack alignItems="flex-end">
                        <Typography color="#F95858" sx={{ fontWeight: '700', fontSize: '16px' }}>₹{batch?.charges}</Typography>
                        <Typography color="#4A4A4A" sx={{ fontWeight: '600', fontSize: '8px', margidemogonTop: '-5px' }}>GST+{batch?.discount}% Discount</Typography>
                    </Stack>
                </Stack>
                <Divider />
                <Typography color="#F95858" sx={{ textAlign: 'center' }} mt={1}>Demo Videos</Typography>
                <Stack direction="row" ml={2} mr={2} justifyContent="space-between" alignItems="center">
                    <ArrowBackIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setDemo(demo + 50)} />
                    <Stack direction="row" justifyContent="space-around" m={1} mb={2} ml={2} mr={2} spacing={4} className="TeacherScroll">
                        {batch?.demoVideo?.map((item, index) => {
                            // {/* const link = item?.fileLoc.split(' ')[0]
                            //console.log(link) */}
                            {/* //console.log(item?.fileLoc)
                        //console.log(typeof (item?.fileLoc)) */}
                        // debugger;
                            // console.log(batch?.demoVideo);
                            return (
                                <div key={index}>
                                    {item?.DemoVideofileType == 'link' ? (
                                        typeof (item?.fileLoc) !== 'string' ? item?.fileLoc?.map((link, i) => {
                                            return <div style={{ ...scrollDemo }}><iframe
                                                key={i}
                                                width="130"
                                                height="100"
                                                src={link}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allowFullScreen="allowfullscreen"

                                            ></iframe>
                                            </div>
                                        }) : <div style={{ ...scrollDemo }}><iframe

                                            width="300"
                                            height="250"
                                            src={convertYouTubeURLToEmbedURL(item?.fileLoc)}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allowFullScreen="allowfullscreen"

                                        ></iframe></div>
                                    ) : (<video key={item?._id} id="capsule" style={{ ...scrollDemo }} src={item?.fileLoc} width="300" height="250" controls />)
                                    }
                                </div>)
                        }
                        )}
                    </Stack>
                    <ArrowForwardIosOutlinedIcon fontSize='small' sx={{ ...iconStyle }} onClick={() => setDemo(demo - 50)} />
                </Stack>
            </Stack>
        </Card >
    )
}

export default BatchDetailsCard