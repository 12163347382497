import {
  Box,
  Stack,
  Popover,
  Typography,
  FormControlLabel,
  Checkbox,
  Modal,
  FormGroup,
  Button,
  Tooltip
} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const BoxDesign = {
  background: "rgba(236, 145, 145, 0.2)",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  borderRadius: "15px",
  height: "200px",
};

const BoxInsideTop = {
  height: "35px",
  background: "#ffffff",
  padding: "5px 13px",
  borderRadius: "12px 12px 0px 0px",
};
const BoxInsideCenter = {
  background: "transparent",
  padding: "5px 13px",
  fontWeight: "500",
};
const BoxInsideCenterInside = {
  background: "rgba(255, 255, 255, 0.4)",
  border: "1px solid #FB5259",
  color: "#F95858",
  fontWeight: "500",
  fontSize: "13px",
  padding: "8px 10px",
};
const InfoCard = ({ title, data, path, use, setLoading }) => {
  // //console.log(data?.batch_name)
  //Date
  let createdDate = data?.created_at.split(" ")[0];

  const navigate = useNavigate();

  //Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const [batchIdForPopover, setBatchIdForPopover] = useState();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (id) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Delete batch
  const deleteDetails = async (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    await fetch(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteBatchesDetails/${batchIdForPopover}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setLoading(false);

    setTimeout(() => {
      toast.success("Batch Deleted");
      window.location.reload();
    }, 1000);
  };

  //handleChange
  const handleChange = async (id) => {
    // //console.log(id)
    const formData = new FormData();
    if (data?.is_active) {
      formData.append("is_active", false);
    } else {
      formData.append("is_active", true);
    }

    data.demoVideo.map((item, i) => {
      formData.append("demoVideoResentLinks", item?.fileLoc);
    });

    formData.append("demoVideoResentLinks", "");

    formData.append("category", data?.category?.title);
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedBatchesDetails/${batchIdForPopover}`,
        formData,
        config
      )
      .then((response) => {
        setLoading(false);
        if (response.data.msg === "Updated the Batches details") {
          if (data.is_active) {
            toast.success("Batch Deactivated");
          } else {
            toast.success("Batch Activated");
          }
          setTimeout(() => {
            window.location.reload();
          }, 800);
        } else {
          toast.error(response.data.msg);
        }
      });
  };

  //Make Active/InActive
  const makeActive = async () => {
    const formData = new FormData();
    if (data?.is_active) {
      formData.append("is_active", false);
    } else {
      formData.append("is_active", true);
    }

    data.demoVideo.map((item, i) => {
      formData.append("demoVideoResentLinks", item?.fileLoc);
    });

    formData.append("demoVideoResentLinks", "");

    formData.append("category", data?.category?.title);
    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedBatchesDetails/${batchIdForPopover}`,
        formData,
        config
      )
      .then((response) => {
        setLoading(false);

        if (response.data.msg === "Updated the Batches details") {
          if (data.is_active) {
            toast.success("Batch Deactivated");
          } else {
            toast.success("Batch Activated");
          }
          setTimeout(() => {
            window.location.reload();
          }, 800);
        } else {
          toast.error(response.data.msg);
        }
      });
  };

  // Copy batch
const [openCopyBatch, setOpenCopyBatch] = React.useState(false);
const handleOpenCopyBatch = () => setOpenCopyBatch(true);
const handleCloseCopyBatch = () => setOpenCopyBatch(false);
const [copyBatchDetails, setCopybatchDetails] = React.useState({
  isCopyLecture: false,
  isCopyLectResources: false,
});
const [batchId, setBatchId] = React.useState(null);

const handleCopybatchChange = (e, name) => {
  setCopybatchDetails((prev) => ({ ...prev, [name]: e.target.checked }));
};

const handleCopybatch = async () => {
  setLoading(true);
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    id: batchId,
    lecture: copyBatchDetails.isCopyLecture ? 1 : 0,
   // letureRecources: copyBatchDetails.isCopyLectResources ? 1 : 0,
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_LIVE_URL}/batch/copy-batch`,
      data,
      config
    );
    setLoading(false);
    if (response.data.message === "Batch Copied Successfully") {
      toast.success("Batch Copied");

      // Redirect to the updateBatchDetails page with the new batch ID
      const newBatchId = response.data.data.batch._id;
      setTimeout(() => {
        navigate(`/dashboard/batches/updateBatchDetails/${newBatchId}`);
      }, 800);
    } else {
      toast.error(response.data.msg);
    }
  } catch (error) {
    setLoading(false);
    toast.error("Error copying batch");
  }
};


  
 const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };


  // //console.log(use == 'Student Batches')
  return (
    <Box sx={{ ...BoxDesign }}>
      {title == "Info" ? (
        <Stack
          direction="column"
          justifyContent="space-between"
          pb={1}
          sx={{ height: "100%" }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...BoxInsideTop }}
          >
            <Typography
              color="#F95858"
              sx={{ fontSize: "16px", fontWeight: "600" }}
            >
              {use == "Student Batches"
                ? data?.batch_name
                : use == "Batches"
                ? data?.batch_name
                : data?.testseries_name}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ ...BoxInsideCenter }}
          >
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  ...BoxInsideCenterInside,
                  padding: "8px 14px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {data?.student?.length}
              </Box>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Students
              </Typography>
            </Stack>
            <Stack direction="column" alignItems="center" spacing={1}>
              <Box sx={{ borderRadius: "8px", ...BoxInsideCenterInside }}>
                {data?.starting_date}
              </Box>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Batch Start
              </Typography>
            </Stack>
            {use == "Student Batches" && (
              <Stack direction="column" alignItems="center" spacing={1}>
                <Box sx={{ borderRadius: "8px", ...BoxInsideCenterInside }}>
                  {data?.ending_date}
                </Box>
                <Typography color="#474747" sx={{ fontSize: "14px" }}>
                  Batch End
                </Typography>
              </Stack>
            )}
            {use == "Batches" && (
              <Stack direction="column" alignItems="center" spacing={1}>
                <Box sx={{ borderRadius: "8px", ...BoxInsideCenterInside }}>
                  {data?.ending_date}
                </Box>
                <Typography color="#474747" sx={{ fontSize: "14px" }}>
                  Batch End
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ ...BoxInsideCenter }}
          >
            <Stack direction="column" alignItems="center">
              <Typography color="#FB5259" sx={{ fontSize: "12px" }}>
                {createdDate}
              </Typography>
              <Typography color="#474747" sx={{ fontSize: "14px" }}>
                Date Created
              </Typography>
            </Stack>

            <Typography sx={{ padding: "2px 5px", borderRadius: "8px" }}>
              Status:{" "}
              <span style={{ color: data?.is_active ? "green" : "red" }}>
                {data?.is_active ? "Active" : "In Active"}
              </span>
            </Typography>
            {use !== "Batches" ||
              (use !== "Student Test Series" && (
                <Box
                  onClick={() => {
                    setBatchIdForPopover(data?._id);
                  }}
                >
                  <KeyboardArrowDownIcon
                    sx={{
                      color: "#FB5259",
                      background: "rgba(255, 252, 252, 0.72)",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                    aria-describedby={id}
                  />
                </Box>
              ))}

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <>
                <Typography
                  pl={4}
                  pr={4}
                  pt={1}
                  pb={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() =>
                    navigate(`/dashboard/batchDetails/${batchIdForPopover}`)
                  }
                >
                  View
                </Typography>

                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboard/batches/updateBatchDetails/${batchIdForPopover}`
                    )
                  }
                >
                  Edit
                </Typography>

                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() => {
                    setBatchId(data?._id);
                    handleOpenCopyBatch();
                  }}
                >
                  Copy Batch
                </Typography>

                <Modal
                  open={openCopyBatch}
                  onClose={handleCloseCopyBatch}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "background.paper",
                      border: "2px solid #000",
                      boxShadow: 24,
                      p: 4,
                    }}
                  >
                    <FormGroup>
                      <Typography variant="h4">Copy Details</Typography>
                      <FormControlLabel
                        onClick={(e) =>
                          handleCopybatchChange(e, "isCopyLecture")
                        }
                        control={<Checkbox />}
                        label="Lectures"
                      />
                      {/*
                        <FormControlLabel
                          onClick={(e) =>
                            handleCopybatchChange(e, "isCopyLectResources")
                          }
                          control={<Checkbox />}
                          label="Lecture Resource"
                        />
                     */ }
                      <Button variant="outlined" onClick={handleCopybatch}>
                        Done
                      </Button>
                    </FormGroup>
                  </Box>
                </Modal>

                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboard/batches/addTeacherToBatch/${batchIdForPopover}`
                    )
                  }
                >
                  Assign Teacher
                </Typography>
                {data?.student?.length == 0 ? (
                  <Typography
                    pl={4}
                    pr={4}
                    pb={1}
                    pt={0.5}
                    sx={{
                      color: "gray",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#d3d3d36b",
                      },
                    }}
                    onClick={() => deleteDetails()}
                  >
                    Delete
                  </Typography>
                ) : null}
                <Typography
                  pl={4}
                  pr={4}
                  pb={1}
                  pt={0.5}
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    "&:hover": {
                      background: "#d3d3d36b",
                    },
                  }}
                  onClick={() => makeActive()}
                >
                  {data?.is_active ? "Make InActive" : "Make Active"}
                </Typography>
              </>
            </Popover>
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ minWidth: "100%", minHeight: "100%" }}
        >
          <Box onClick={() => navigate(path)} sx={{ cursor: "pointer" }}>
            {" "}
            <svg
              width="58"
              height="58"
              viewBox="0 0 78 78"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M39 0C17.4603 0 0 17.4603 0 39C0 60.5397 17.4603 78 39 78C60.5397 78 78 60.5397 78 39C78 17.4603 60.5397 0 39 0ZM58.5 42.9H42.9V58.5H35.1V42.9H19.5V35.1H35.1V19.5H42.9V35.1H58.5V42.9Z"
                fill="url(#paint0_linear_1_9)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_9"
                  x1="78"
                  y1="69"
                  x2="0"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#D95673" />
                  <stop offset="1" stopColor="#FFB0C1" />
                </linearGradient>
              </defs>
            </svg>
          </Box>
          <Typography
            color="#FB5259"
            sx={{ fontSize: "22px", fontWeight: "600" }}
          >
            Add Batch
          </Typography>
        </Stack>
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export default InfoCard;
