import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography, MenuItem, IconButton, Button, Grid, Select } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddQuestions from './addquestion'; 
import * as XLSX from 'xlsx';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const Library = () => {
  const [tabValue, setTabValue] = useState(null);  
  const [file, setFile] = useState(null);  
  const [excelData, setExcelData] = useState([]); 

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    if (uploadedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const binaryString = e.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        const sheet = workbook.Sheets[sheetNames[0]];
        const data = XLSX.utils.sheet_to_json(sheet);
        setExcelData(data); 
      };
      reader.readAsBinaryString(uploadedFile);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        padding: '20px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',  
      }}
    >
      <Box mb={1} mt={1}>
        <Typography variant="h4" gutterBottom fontWeight="bold" color="#333">
          Add Questions
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        <Box
          sx={{
            width: '70%',
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="none"
            textColor="inherit"
            sx={{
              borderBottom: '1px solid #E0E0E0',
              '& .MuiTab-root': {
                fontSize: '16px',
                fontWeight: 'bold',
                textTransform: 'none',
                color: '#000',
                padding: '10px 20px',
                flex: 1,
                transition: 'color 0.3s ease',
              },
              '& .Mui-selected': {
                color: '#FFF',
                backgroundColor: '#F67382',
                borderRadius: '10px 10px 0 0',
              },
            }}
          >
            <Tab label="Add Manual Questions" value={0} />
            <Tab label="Import from Excel" value={1} />
          </Tabs>

          {tabValue === null && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px', 
              }}
            >
             <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
          }}
        >
          <AssignmentTurnedInIcon sx={{ fontSize: 100, color: '#F77B7B', marginBottom: '20px' }} />
          <Typography variant="h6" color="#999">
            Please Select Exam Category, Type, Subject, and Chapter to Continue
          </Typography>
        </Box>
            </Box>
          )}
          
          {tabValue === 0 && <AddQuestions />}
          {tabValue === 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                mt: 6,
              }}
            >
              <IconButton
                component="label"
                sx={{
                  backgroundColor: '#FCE3E3',
                  borderRadius: '50%',
                  width: '120px',
                  height: '120px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <UploadFileIcon sx={{ fontSize: 40, color: '#F77B7B' }} />
                <input type="file" hidden onChange={handleFileUpload} />
              </IconButton>
              <Button
                variant="outlined"
                sx={{
                  color: '#F77B7B',
                  borderColor: '#F77B7B',
                  borderRadius: '10px',
                  padding: '10px 20px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Select Excel File
              </Button>
              {file && <Typography variant="body2" sx={{ mt: 1 }}>{file.name}</Typography>}

              {excelData.length > 0 && (
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom color="#333">
                    Imported Data:
                  </Typography>
                  <Grid container spacing={2}>
                    {excelData.map((row, index) => (
                      <Grid item xs={12} key={index}>
                        <Box border={1} borderColor="#e0e0e0" borderRadius={2} p={2} mb={2} sx={{ backgroundColor: '#f9f9f9' }}>
                          {Object.entries(row).map(([key, value]) => (
                            <Typography key={key} variant="body2" color="#666">
                              <strong>{key}:</strong> {value}
                            </Typography>
                          ))}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: '30%',
          }}
        >
        <Box
  mt={4}
  p={3}
  borderRadius={3}
  bgcolor="#FBD6DD"
>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom color="#333">
        Exam Category
      </Typography>
      <Select
        fullWidth
        variant="outlined"
        defaultValue=""
        sx={{
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          },
        }}
        displayEmpty
      >
        <MenuItem value="" disabled>Select Exam Category</MenuItem>
        <MenuItem value="Central Exam">Central Exam</MenuItem>
        <MenuItem value="State Exam">State Exam</MenuItem>
      </Select>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom color="#333">
        Exam Type
      </Typography>
      <Select
        fullWidth
        variant="outlined"
        defaultValue=""
        sx={{
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          },
        }}
        displayEmpty
      >
        <MenuItem value="" disabled>Select Exam Type</MenuItem>
        <MenuItem value="IIT JEE">IIT JEE</MenuItem>
        <MenuItem value="NEET">NEET</MenuItem>
      </Select>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom color="#333">
        Subject
      </Typography>
      <Select
        fullWidth
        variant="outlined"
        defaultValue=""
        sx={{
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          },
        }}
        displayEmpty
      >
        <MenuItem value="" disabled>Select Subject</MenuItem>
        <MenuItem value="Physics">Physics</MenuItem>
        <MenuItem value="Chemistry">Chemistry</MenuItem>
      </Select>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom color="#333">
        Chapter
      </Typography>
      <Select
        fullWidth
        variant="outlined"
        defaultValue=""
        sx={{
          backgroundColor: '#fff',
          '& .MuiOutlinedInput-root': {
            borderRadius: 2,
          },
        }}
        displayEmpty
      >
        <MenuItem value="" disabled>Select Chapter</MenuItem>
        <MenuItem value="Chapter 1: Units and Vectors">Chapter 1: Units and Vectors</MenuItem>
        <MenuItem value="Chapter 2: Kinematics">Chapter 2: Kinematics</MenuItem>
      </Select>
    </Grid>

    <Grid item xs={12}>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          mt: 3,
          borderRadius: 2,
          backgroundColor: '#F67382',
          color: '#fff',
          '&:hover': { backgroundColor: '#f06292' },
        }}
      >
       Add Question
      </Button>
    </Grid>
  </Grid>
</Box>

        </Box>
      </Box>
    </Box>
  );
};

export default Library;
