import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
import React from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
  Divider,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
import { Link, useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import axios from "axios";
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToastContainer, toast } from 'react-toastify';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: "checkbox",
    label: "Checkbox",
    alignRight: false,
    editable: true,
  },
  {
    id: "sno",
    label: "S.No",
    alignRight: false,
    editable: true,
  },
  { id: "FullName", label: "Name", alignRight: false, editable: true },
  { id: "email", label: "Email", alignRight: false, editable: true },
  { id: "mobileNumber", label: "Mobile No.", alignRight: false },
  { id: "requestedAt", label: "RequestedAt", alignRight: false },
  { id: "message", label: "Message", alignRight: false },
  // { id: "action", label: "Action", alignRight: false },
  // { id: 'upload_file', label: 'File ', alignRight: false },
  // { id: 'language', label: 'Language', alignRight: false },
  // // { id: 'is_Verfied', label: 'Verified', alignRight: false },
  // { id: 'is_active', label: 'Status', alignRight: false, },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {

  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      const studentNameMatch = _user?.user?.name?.toLowerCase()?.includes(query.toLowerCase());
      const emailMatch = _user?.user?.email?.toLowerCase()?.includes(query.toLowerCase());
      const phoneMatch = _user?.user?.mobileNumber?.toLowerCase()?.includes(query.toLowerCase());
      return studentNameMatch || phoneMatch || emailMatch;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function LogoutUsers() {
  const [page, setPage] = useState(0);
  // const [users, usersSet] = React.useState([]);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [userarray, setUserarray] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllLogoutRequests`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setUsers(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.user.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const handleUserArray = (id) => {
    // toast.success("shcvjhc");
    //console.log(id);
    setUserarray((prev) => [...prev, id]);
    //console.log(userarray);
  }

  const logoutSellected = async () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // //console.log(userarray);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/logoutTheUserFromOtherDevices`,
        { userIdArr: userarray },
        config
      );

      if (res.status >= 200 && res.status < 300) {
        toast.success("User Logout Successfully");
        window.location.reload();
      } else {
        // Handle non-successful response
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
    }





  }

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  // //console.log(updatedValues)
  //console.log("USEE", filteredUsers);
  let i = 0;
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Logout Users">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack justifyContent="space-between" mb={3}>
              <Typography variant="h3" gutterBottom>
                Logout Requests
              </Typography>
              <Divider />
              {/* <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: '#EA506B', '&:hover': { background: 'red' } }} >Add Student</Button> */}
            </Stack>

            <Card>
              <Stack direction="row" spacing={2} paddingRight="25px" justifyContent="space-between" alignItems="center">

                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                <Button
                  variant="contained"
                  onClick={logoutSellected}
                  sx={{ bgcolor: "#EA506B" }}
                >
                  Logout Selected Users
                </Button>

              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {filteredUsers && filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          let { id, user, message, requestAt } = row;
                          const isItemSelected =
                            selected.indexOf(user?.name) !== -1;
                          // debugger;
                          // console.log(user);

                          return (
                            < React.Fragment key={index}>
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell>
                                  <Typography variant="subtitle2">
                                    <Checkbox
                                      checked={userarray.includes(user?.userId)}
                                      onChange={() => handleUserArray(user?.userId)}
                                    />

                                  </Typography>
                                </TableCell>

                                <TableCell>
                                  <Typography variant="subtitle2">
                                    {index + 1 + rowsPerPage * page}
                                  </Typography>
                                </TableCell>

                                <TableCell component="th" scope="row" pl={2}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                  >
                                    {/* <Avatar alt={name} src={avatarUrl} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      <span>{user?.name}</span>
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  <span>{user?.email}</span>
                                </TableCell>
                                <TableCell align="left">
                                  <span>{user?.mobileNumber}</span>
                                </TableCell>
                                <TableCell align="left">
                                  <span>{requestAt}</span>
                                </TableCell>
                                <TableCell align="left">
                                  <span>{message}</span>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
