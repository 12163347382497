import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { useHistory } from 'react-router-dom';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "", label: "S.No", alignRight: false },
  { id: "userName", label: "Name", alignRight: false },
  //
  { id: "quizuserId", label: "Quiz", alignRight: false },
  // { id: 'user', label: 'Student', alignRight: false },
  // { id: 'created_at', label: 'Date', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => {
      const studentNameMatch = _user?.userName && _user.userName.toLowerCase().includes(query.toLowerCase());
      // const emailMatch = _user?.email && _user.email.toLowerCase().includes(query.toLowerCase());
      // const phoneMatch = _user?.mobileNumber && _user.mobileNumber.toLowerCase().includes(query.toLowerCase());
      return studentNameMatch;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array?.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,(_user) => {
//         const studentNameMatch = _user?.userName.toLowerCase().indexOf(query.toLowerCase()) ;
//         return studentNameMatch;
//       });
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function QuizStudents() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);

  const { quizId, quizName } = useParams();

  //Getting notes
  const [isActiveValue, setIsActiveValue] = useState(false);

  const [students, setStudents] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getStudentDetails/${quizId}`,
        {
          // const fullResponse = await fetch(`http://localhost:3001/api/v1/adminPanel/getStudentDetails/${quizId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const publishResult = async (id) => {

    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    // toast.success("Student Result Published");
    // //console.log("Before Axios Call");
    axios.post(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/publishResultByStudentId/${id}/${quizId}`,
      {},
      config
    )
      .then((response) => {
        //console.log(response);
        // if(response.data.status){
        toast.success(response.data.msg);
        window.location.reload();
        // }

      })
      .catch((error) => {
        //console.log(error);
      });
    window.location.reload();
    // toast.success("Quiz Result ");
  };

  const publishQuizResult = async () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/publishResultByQuizId/${quizId}`,
        {},
        config
      )
      .then((response) => {
        toast.success("Quiz Result Published");
      })
      .catch((e) => {
        //console.log(e);
      });
    window.location.reload();
    // toast.success("Quiz Result Published");
  };

  // const handleLeader = () => {
  //   // window.location.href = `./dashbaord/quiz/quizStudents/${quizId}`;
  //   const history = useHistory();
  //   history.push(`./dashbaord/quiz/quizStudents/${quizId}`);
  // }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : [],
    getComparator(order, orderBy),
    filterName
  );

  // let helperArray = [];
  // filteredUsers?.map((item) =>
  //   helperArray.push({
  //     ID: item?.userId,
  //     File_url: item?.file_url?.fileLoc,
  //     Title: item?.title,
  //     Is_active: item?.is_active,
  //     Language: item?.language,
  //     Notes_type: item?.notes_type,
  //   })
  // );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                {quizName} Students
              </Typography>

              {
                users?.length != 0 ? (
                  <Button
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: "#EA506B",
                      "&:hover": { background: "red" },
                    }}
                    onClick={() => navigate(`/dashboard/quiz/leaderboard/${quizId}/${quizName}`)}

                  >
                    Leader Board
                  </Button>
                ) : (
                  <>
                  </>
                )
              }
              {users?.length != 0 ? (
                <Button
                  variant="contained"
                  sx={{
                    width: "20%",
                    alignSelf: "center",
                    background: "#EA506B",
                    "&:hover": { background: "red" },
                  }}
                  onClick={() => publishQuizResult()}
                >
                  Publish Quiz Result
                </Button>
              ) : (
                ""
              )}

            </Stack>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV
                  csvData={helperArray}
                  fileName="Quiz Students Data"
                /> */}
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const { id, userName, userId, is_published } = row;
                          const isItemSelected =
                            selected.indexOf(userName) !== -1;
                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle2" noWrap>
                                    <span>{userName}</span>
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography>
                                    <span>{quizName}</span>
                                  </Typography>
                                </Stack>
                              </TableCell>

                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/quiz/${quizName}/${quizId}/${userId}`
                                    )
                                  }
                                >
                                  View Result
                                </Button>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  disabled={is_published}
                                  onClick={() => publishResult(userId)}
                                  sx={{
                                    "&.Mui-disabled": {
                                      background: "white",
                                      color: "#57D163",
                                      border: "1px solid #57D163",
                                    },
                                  }}
                                >
                                  {is_published
                                    ? "Result Published"
                                    : "Publish Result"}
                                </Button>
                              </TableCell>

                              {/* <TableCell align="right" onClick={() => setSelectedId(userId)}>
                                                        <UserMoreMenu id={userId} req='DeleteNotesDetails' setLoading={setLoading} />
                                                    </TableCell> */}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
