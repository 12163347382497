import React, { useEffect } from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
    Link,
    Stack,
    IconButton,
    InputAdornment,
    Typography,
    TextField,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Grid,
    Box,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from '../../../components/Iconify';

import {
    FormProvider,
    RHFTextField,
    RHFCheckbox,
} from "../../../components/hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

import Page from '../../../components/Page';
import keyValueTab from "../../../data/tabArray"



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const UpdateStaff = () => {
    const navigate = useNavigate();
    const [focus, setFocused] = React.useState(false);
    const [resources, setResources] = React.useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [users, usersSet] = React.useState([]);
    const { lectId, batchId, teacherId } = useParams();
    const [loading, setLoading] = useState(false);
    const [fetchMessage, setFetchMessage] = useState();
    const [starting, setStarting] = React.useState(dayjs("2022-01-01T21:11:54"));
    const [ending, setEnding] = React.useState(dayjs("2022-01-01T21:11:54"));



    const [selectedOption, setSelectedOption] = useState('no');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };



    const [teacher, setteacher] = React.useState([]);
    const theme = useTheme();

    const handleChange = (newValue) => {
        setStarting(newValue);
    };
    const handleChangeTwo = (newValue) => {
        setEnding(newValue);
    };

    const LoginSchema = Yup.object().shape({});

    const defaultValues = {};

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [file, setFile] = useState();

    const changeHandler = async (event) => {
        setFile(event.target.files[0]);
    };

    //Getting Existing details
    const [lectureDetails, setLectureDetails] = useState();
    const [value, setValue] = useState();
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     async function fetchUsers() {
    //         const fullResponse = await fetch(
    //             `${process.env.REACT_APP_LIVE_URL}/adminPanel/getPerticularLectureDetails/${lectId}`,
    //             {
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         );
    //         const responseJson = await fullResponse.json();
    //         setLectureDetails(responseJson.data);
    //         //console.log(responseJson.data.starting_date);
    //         setStarting(dayjs(responseJson.data.starting_date));
    //         setEnding(dayjs(responseJson.data.ending_date));
    //         setValue((prev) => ({
    //             ...prev,
    //             lecture_type: responseJson.data?.lecture_type,
    //             lecture_title: responseJson.data?.lecture_title,

    //             description: responseJson.data?.description,
    //         }));
    //     }
    //     fetchUsers();
    // }, []);
    // //console.log(lectureDetails?.starting_date);
    // //console.log(starting);



    //console.log(teacherId)

    //Old Details
    const [oldValue, setOldValue] = useState();
    const [teacherDetails, setTeacherDetails] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/getTeacherDetails/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setTeacherDetails(responseJson.data);
            //console.log(teacherDetails)
            settingValues(responseJson.data)
            check(responseJson.data)
        }

        fetchUsers();
    }, []);



    // const { teacherId } = useParams()

    //Fetching Data

    const [users1, usersSet1] = useState()
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/getTeacherDetails/${teacherId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet1(responseJson.data);

            check1(responseJson.data)
        }

        fetchUsers();
    }, []);
    let helperArray1 = []
    const check1 = (tabsDetails) => {
        setteacher(tabsDetails?.TeacherDetails?.accessToContent)

        helperArray1 = keyValueTab?.keyValueTab.map((item, index) => {
            if (teacher?.includes(item?.value)) {
                return item?.label;
            }

        })
        console.log('line218', helperArray1)
    }

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const settingValues = (data) => {
        //console.log(data?.TeacherDetails)
        setOldValue((prev) => ({
            ...prev,
            FullName: data?.TeacherDetails?.FullName,
            email: data?.TeacherDetails?.email,

            Role: data?.TeacherDetails?.Role,
            password: data?.TeacherDetails?.password,
            qualification: data?.TeacherDetails?.qualification,
            is_Special: data?.TeacherDetails?.is_Special,
            mobile: data?.TeacherDetails?.mobileNumber,
            deleteAccess: data?.TeacherDetails?.deleteAccess,
            access: data?.TeacherDetails?.access
        }));
    }
    //console.log(teacherDetails?.TeacherDetails)
    // console.log(oldValue)

    //Submit Function


    const onSubmit = async (data) => {
        //console.log(data)
        setLoading(true);

        if (selectedOption === 'no') {
            delete oldValue.password;
        }

        let obj = { ...oldValue, ej_ective: true, batch: assignedBatches }
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": 'application/json',
                Authorization: `Bearer ${authToken}`,
            },
        };
        axios
            .put(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/updateteacher/${teacherId}`, obj, config)
            .then((response) => {

                if (response.data.msg === "Teacher details updated successfully") {
                    reset();
                    setLoading(false);
                    toast.success("Staff Details Updated");

                    setTimeout(() => {
                        navigate(`/dashboard/staff`);
                        // window.location.reload();
                    }, 1500);
                } else {
                    toast.error(response.data.msg);
                }
            })
            .catch((e) => {
                //console.log(e);
            });

        let obj1 = {
            tabs: teacher,


        }

        axios.put(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/updateTheTeacherPermission/${teacherId}`, obj1, config).then((response) => {
            if (response.data.msg == 'Teacher permissions is succefully updated') {
                reset()
                setLoading(false)
                toast.success("Teacher permissions is succefully updated")
                setTimeout(() => {
                    navigate('/dashboard/staff')
                }, 1000)
            }
        })
            .catch(e => {
                //console.log(e);
            })
    };

    //Getting Batch
    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);

            // setLoading(false)
        }
        fetchUsers();
    }, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setOldValue((prev) => ({ ...prev, [name]: value }));
    };

    const [batchDetails, setBatchDetails] = useState()
    const [batch, setbatch] = useState([]);
    const [batch_id, setBatchId] = useState()
    const [role, setRole] = useState('')
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            setBatchDetails(responseJson.data);
        }
        fetchUsers()
    }, [batch_id])


    let helperArray = []
    const [assignedBatches, setAssignedBatches] = useState()
    const check = (tabsDetails) => {
        // console.log(tabsDetails?.BatchesTeacherIn)
        helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => item?._id)
        // console.log('helperArray', helperArray)
        setAssignedBatches(helperArray)

        // helperArray = tabsDetails?.BatchesTeacherIn?.map((item, index) => {
        //     if (assignedBatches?.includes(item?.value)) {
        //         console.log(item?.batch_name)
        //         return item?.batch_name;
        //     }

        // })
        // console.log('line218', helperArray)
    }
    const handleChangeAssignBatch = (event) => {
        const {
            target: { value },
        } = event;
        setAssignedBatches(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <Grid direction="row" pl={5}>
            <Typography variant="h2" ml={2}>
                Update Staff Details
            </Typography>
            {/* <Typography variant="h6" ml={2} mb={4}>
            Enter the below required details to update a lecture
        </Typography> */}

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {/* <Stack direction="row" spacing={5} > */}
                <Stack spacing={3} sx={{ width: "70%" }}>
                    <RHFTextField name="FullName"
                        value={oldValue?.FullName}
                        onChange={onChange2}
                    />
                    <RHFTextField name="email"
                        value={oldValue?.email}
                        onChange={onChange2}
                    />
                    <RHFTextField name="mobile" type='number'
                        value={oldValue?.mobile}
                        onChange={onChange2}
                    />
                    <RHFTextField name="Role" label={oldValue?.Role} select
                        value={oldValue?.Role}
                        onChange={onChange2}>
                        <MenuItem value='teacher' onClick={() => setRole('teacher')}>Teacher</MenuItem>
                        <MenuItem value="subadmin" onClick={() => setRole('subadmin')}>Sub-Admin</MenuItem>
                        <MenuItem value="moderator" onClick={() => setRole('moderator')}>Moderators</MenuItem>
                    </RHFTextField>


                    <label>edit password</label>
                    <label>
                        <input
                            type="radio"
                            value="yes"
                            checked={selectedOption === 'yes'}
                            onChange={handleOptionChange}
                        />
                        Yes
                    </label>

                    <label>
                        <input
                            type="radio"
                            value="no"
                            checked={selectedOption === 'no'}
                            onChange={handleOptionChange}
                        />
                        No
                    </label>



                    {
                        selectedOption === 'yes' ? (
                            <RHFTextField
                                name="password"
                                // label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={oldValue?.password}
                                onChange={onChange2}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />

                        ) : (
                            <></>
                        )
                    }


                    {/* <Stack direction="row">
                    <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Subjects</InputLabel>
                    <Select
                        // label    ="Name"
                        // id="demo-multiple-name"
                        // labelId="test-select-label"
                        label=""
                        multiple
                        name='Subject'
                        value={teacher}
                        onChange={handleChange}

                        sx={{ width: '100%', color: 'black', zIndex: 9 }}
                        MenuProps={MenuProps}
                    >

                        {users?.map((name) => (
                            <MenuItem
                                key={name}
                                value={name._id}
                                style={getStyles(name, teacher, theme)}
                            >
                                {name.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack> */}
                    {/* <Stack direction="row">
                    <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Tabs</InputLabel>
                    <Select

                        label=""
                        multiple
                        name='TeacherAccess'
                        value={accessToContent}
                        onChange={handleChangeAccess}

                        sx={{ width: '100%', color: 'black', zIndex: 9 }}
                        MenuProps={MenuProps}
                    >

                        {tabsArray?.map((item, i) => (
                            <MenuItem
                                key={i}
                                value={item.name}
                                style={getStyles(item, accessToContent, theme)}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Stack> */}
                    {/* <Stack direction="row" spacing={3}>


                    <RHFTextField name='qualification' helperText='Add Only Valid Qualification'
                        value={oldValue?.qualification}
                        onChange={onChange2}
                    />



                </Stack> */}
                    {(role == 'moderator' || oldValue?.Role == 'moderator') && < Stack direction="row">
                        <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Batch</InputLabel>
                        <Select
                            // label    ="Name"
                            // id="demo-multiple-name"
                            // labelId="test-select-label"
                            label=""
                            multiple
                            name='assignedBatches'
                            // value={oldValue?.batches?.batch_name}
                            value={assignedBatches}
                            onChange={handleChangeAssignBatch}

                            sx={{ width: '100%', color: 'black', zIndex: 9 }}
                            MenuProps={MenuProps}
                        >

                            {batchDetails?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item._id}
                                    onClick={() => setBatchId(item?._id)}
                                // style={getStyles(item, batch, theme)}
                                >
                                    {item?.batch_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>}

                    <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '90px', alignItems: 'center' }}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Access Level</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="female" onClick={() => setOldValue((prev) => ({ ...prev, access: 'read' }))} checked={oldValue?.access == 'read'} control={<Radio />} label="Read Only" />
                            <FormControlLabel value="male" onClick={() => setOldValue((prev) => ({ ...prev, access: 'readWrite' }))} checked={oldValue?.access == 'readWrite'} control={<Radio />} label="Read & Write " />
                            <FormControlLabel value="other" onClick={() => setOldValue((prev) => ({ ...prev, deleteAccess: !oldValue?.deleteAccess }))} checked={oldValue?.deleteAccess} control={<Checkbox {...label} />} label="Delete Access" />
                            {/* <FormControlLabel
                            value="disabled"
                            disabled
                            control={<Radio />}
                            label="other"
                        /> */}
                        </RadioGroup>
                    </FormControl>
                    <RHFTextField name='is_Special' label={oldValue?.is_Special ? 'Active' : 'InActive'} select
                        value={oldValue?.is_Special}
                        onChange={onChange2}>
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                    </RHFTextField>

                    <InputLabel sx={{ width: '40%', marginRight: '20px', alignSelf: 'center' }}>Add Permissions </InputLabel>
                    <Select

                        label=""
                        multiple
                        name='teacher'
                        value={teacher}
                        onChange={handleChange1}

                        sx={{ width: '100%', color: 'black', zIndex: 9 }}
                        MenuProps={MenuProps}
                    >

                        {keyValueTab?.keyValueTab?.map((name) => (
                            <MenuItem
                                key={name}
                                value={name?.value}
                            // style={getStyles(name, teacher, theme)}
                            >
                                {name.label}
                            </MenuItem>
                        ))}
                    </Select>

                </Stack>

                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                    sx={{
                        bgcolor: "#EA506B",
                        width: "70%",
                        marginTop: "5%",
                        "&:hover": { background: "red" },
                    }}
                >
                    Update
                </LoadingButton>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Grid>
    );
};

export default UpdateStaff;

