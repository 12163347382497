export const optionsForTeacher = [
    { label: 'General Permission', value: ['All Batches', "All Lecture", "All Coupon"] },
    {
        label: "Users", value: '1', options: [
            {
                label: 'All Users', value: 'All Users'
            },
            {
                label: 'Course Purchases', value: 'Course Purchases'
            },
            {
                label: 'Test Series Purchases', value: 'Test Series Purchases'
            },
            {
                label: 'Purchase Report', value: 'Purchase Report'
            },
            {
                label: 'User Report', value: 'User Report'
            },
        ]
    },
    {
        label: "Batch", value: '2', options: [
            {
                label: 'All Batches', value: 'All Batches'
            },
            {
                label: 'Add Batch', value: 'Add Batch'
            },
            {
                label: 'All Lecture', value: 'All Lecture'
            },
            {
                label: 'Add Lecture', value: 'Add Center Lecture'
            },
            {
                label: 'Add Resource', value: 'Add Center Resource'
            }
        ]
    }
    , {
        label: 'Test Series', value: '3'
        , options: [
            {
                label: 'All Test Series', value: 'All Test Series'
            },
            {
                label: 'Add Test Series', value: 'Add Test Series'
            },
            {
                label: 'All Test', value: 'All Test'
            },
            {
                label: 'Add Test', value: 'Add Test'
            },
        ]
    }
    , {
        label: 'Quiz', value: '4'
        , options: [
            {
                label: 'All Quiz', value: 'All Quiz'
            },
            {
                label: 'Add Quiz', value: 'Add Quiz'
            },
            {
                label: 'All Question', value: 'All Question'
            },
            {
                label: 'Add Question', value: 'Add Question'
            },
        ]
    }
    , {
        label: 'Coupon', value: '5'
        , options: [
            {
                label: 'All Coupon', value: 'All Coupon'
            },
            {
                label: 'Add Coupon', value: 'Add Coupon'
            },

        ]
    }
    // , {
    //     label: 'Resources', value: '6'
    //     , options: [
    //         {
    //             label: 'Recorded Video', value: 'Recorded Video'
    //         },
    //         {
    //             label: 'Add Recorded Video', value: 'Add Recorded Video'
    //         },
    //         {
    //             label: 'Pathyakram', value: 'Pathyakram'
    //         },

    //         {
    //             label: 'Add Pathyakram', value: 'Add Pathyakram'
    //         },
    //         {
    //             label: 'Notes', value: 'Notes'
    //         },
    //         {
    //             label: 'Add Notes', value: 'Add Notes'
    //         },
    //         {
    //             label: 'Previous Year Question Papers', value: 'Previous Year Question Papers'
    //         },
    //         {
    //             label: 'Add Previous Year Question Papers', value: 'Add Previous Year Question Papers'
    //         },
    //         {
    //             label: 'AIR', value: 'AIR'
    //         },
    //         {
    //             label: 'Add AIR', value: 'Add AIR'
    //         },
    //         {
    //             label: 'News Clip', value: 'News Clip'
    //         },
    //         {
    //             label: 'Add News Clip', value: 'Add News Clip'
    //         },
    //         {
    //             label: 'Teacher Generated Content Of Batch', value: 'Teacher Generated Content Of Batch'
    //         },
    //         {
    //             label: 'Alerts', value: 'Alerts'
    //         },
    //         {
    //             label: 'Add Alerts', value: 'Add Alerts'
    //         },

    //     ]
    // }
    , {
        label: 'Assignment', value: '7'
        , options: [
            {
                label: 'All Assignment', value: 'All Assignment'
            },
            {
                label: 'Add Assignment', value: 'Add Assignment'
            },

        ]
    }
    // , {
    //     label: 'Manage Staff', value: '8'
    //     , options: [
    //         {
    //             label: 'All Staff', value: 'All Staff'
    //         },
    //         {
    //             label: 'Add Staff', value: 'Add Staff'
    //         },

    //     ]
    // }
    // , {
    //     label: 'Testimonial', value: '9'
    //     , options: [
    //         {
    //             label: 'All Testimonial', value: 'All Testimonial'
    //         },
    //         {
    //             label: 'Add Testimonial', value: 'Add Testimonial'
    //         },

    //     ]
    // }
    // , {
    //     label: 'General Settings', value: '10'
    //     , options: [
    //         {
    //             label: 'Bannner', value: 'Bannner'
    //         },
    //         {
    //             label: 'Add Bannner', value: 'Add Bannner'
    //         },
    //         {
    //             label: 'Logout Users', value: 'Logout Users'
    //         },
    //         {
    //             label: 'Subject', value: 'Subject'
    //         },
    //         {
    //             label: 'Add Subject', value: 'Add Subject'
    //         },
    //         {
    //             label: 'Youtube', value: 'Youtube'
    //         },
    //         {
    //             label: 'Add Youtube', value: 'Add Youtube'
    //         },
    //         {
    //             label: 'Telegram', value: 'Telegram'
    //         },
    //         {
    //             label: 'Add Telegram', value: 'Add Telegram'
    //         },
    //         {
    //             label: 'Category', value: 'Category'
    //         },
    //         {
    //             label: 'Add Category', value: 'Add Category'
    //         },
    //         {
    //             label: 'Sub Category', value: 'Sub Category'
    //         }
    //         ,
    //         {
    //             label: 'Add Sub Category', value: 'Add Sub Category'
    //         }
    //         ,
    //         {
    //             label: 'Contact Us', value: 'Contact Us'
    //         },
    //         {
    //             label: 'Add Contact Us', value: 'Add Contact Us'
    //         }
    //         ,
    //         {
    //             label: 'Help And Support', value: 'Help And Support'
    //         },

    //     ]
    // }
]
export const options = [

    {
        label: "Users", value: '1', options: [
            {
                label: 'All Users', value: 'All Users'
            },
            {
                label: 'Course Purchases', value: 'Course Purchases'
            },
            {
                label: 'Test Series Purchases', value: 'Test Series Purchases'
            },
            {
                label: 'Purchase Report', value: 'Purchase Report'
            },
            {
                label: 'User Report', value: 'User Report'
            },
        ]
    },
    {
        label: "Batch", value: '2', options: [
            {
                label: 'All Batches', value: 'All Batches'
            },
            {
                label: 'Add Batch', value: 'Add Batch'
            },
            {
                label: 'All Lecture', value: 'All Lecture'
            },
            {
                label: 'Add Lecture', value: 'Add Center Lecture'
            },
            {
                label: 'Add Resource', value: 'Add Center Resource'
            }
        ]
    }
    , {
        label: 'Test Series', value: '3'
        , options: [
            {
                label: 'All Test Series', value: 'All Test Series'
            },
            {
                label: 'Add Test Series', value: 'Add Test Series'
            },
            {
                label: 'All Test', value: 'All Test'
            },
            {
                label: 'Add Test', value: 'Add Test'
            },
        ]
    }
    , {
        label: 'Quiz', value: '4'
        , options: [
            {
                label: 'All Quiz', value: 'All Quiz'
            },
            {
                label: 'Add Quiz', value: 'Add Quiz'
            },
            {
                label: 'All Question', value: 'All Question'
            },
            {
                label: 'Add Question', value: 'Add Question'
            },
        ]
    }
    , {
        label: 'Coupon', value: '5'
        , options: [
            {
                label: 'All Coupon', value: 'All Coupon'
            },
            {
                label: 'Add Coupon', value: 'Add Coupon'
            },

        ]
    }
    , {
        label: 'Resources', value: '6'
        , options: [
            {
                label: 'Recorded Video', value: 'Recorded Video'
            },
            {
                label: 'Add Recorded Video', value: 'Add Recorded Video'
            },
            {
                label: 'Pathyakram', value: 'Pathyakram'
            },

            {
                label: 'Add Pathyakram', value: 'Add Pathyakram'
            },
            {
                label: 'Notes', value: 'Notes'
            },
            {
                label: 'Add Notes', value: 'Add Notes'
            },
            {
                label: 'Previous Year Question Papers', value: 'Previous Year Question Papers'
            },
            {
                label: 'Add Previous Year Question Papers', value: 'Add Previous Year Question Papers'
            },
            {
                label: 'AIR', value: 'AIR'
            },
            {
                label: 'Add AIR', value: 'Add AIR'
            },
            {
                label: 'News Clip', value: 'News Clip'
            },
            {
                label: 'Add News Clip', value: 'Add News Clip'
            },
            {
                label: 'Teacher Generated Content Of Batch', value: 'Teacher Generated Content Of Batch'
            },
            {
                label: 'Alerts', value: 'Alerts'
            },
            {
                label: 'Add Alerts', value: 'Add Alerts'
            },

        ]
    }
    , {
        label: 'Assignment', value: '7'
        , options: [
            {
                label: 'All Assignment', value: 'All Assignment'
            },
            {
                label: 'Add Assignment', value: 'Add Assignment'
            },

        ]
    }
    // , {
    //     label: 'Manage Staff', value: '8'
    //     , options: [
    //         {
    //             label: 'All Staff', value: 'All Staff'
    //         },
    //         {
    //             label: 'Add Staff', value: 'Add Staff'
    //         },

    //     ]
    // }
    , {
        label: 'Testimonial', value: '9'
        , options: [
            {
                label: 'All Testimonial', value: 'All Testimonial'
            },
            {
                label: 'Add Testimonial', value: 'Add Testimonial'
            },

        ]
    }
    , {
        label: 'General Settings', value: '10'
        , options: [
            {
                label: 'Bannner', value: 'Bannner'
            },
            {
                label: 'Add Bannner', value: 'Add Bannner'
            },
            {
                label: 'Logout Users', value: 'Logout Users'
            },
            {
                label: 'Subject', value: 'Subject'
            },
            {
                label: 'Add Subject', value: 'Add Subject'
            },
            {
                label: 'Youtube', value: 'Youtube'
            },
            {
                label: 'Add Youtube', value: 'Add Youtube'
            },
            {
                label: 'Telegram', value: 'Telegram'
            },
            {
                label: 'Add Telegram', value: 'Add Telegram'
            },
            {
                label: 'Category', value: 'Category'
            },
            {
                label: 'Add Category', value: 'Add Category'
            },
            {
                label: 'Sub Category', value: 'Sub Category'
            }
            ,
            {
                label: 'Add Sub Category', value: 'Add Sub Category'
            }
            ,
            {
                label: 'Contact Us', value: 'Contact Us'
            },
            {
                label: 'Add Contact Us', value: 'Add Contact Us'
            }
            ,
            {
                label: 'Help And Support', value: 'Help And Support'
            },

        ]
    }
]


export const keyValueTab =
    [
        {
            label: 'All Users', value: 'All Users'
        },
        {
            label: 'Course Purchases', value: 'Course Purchases'
        },
        {
            label: 'Test Series Purchases', value: 'Test Series Purchases'
        },
        {
            label: 'Purchase Report', value: 'Purchase Report'
        },
        {
            label: 'User Report', value: 'User Report'
        },
        {
            label: 'All Batches', value: 'All Batches'
        },
        {
            label: 'Add Batch', value: 'Add Batch'
        },
        {
            label: 'All Lecture', value: 'All Lecture'
        },
        {
            label: 'Add Lecture', value: 'Add Lecture'
        },
        {
            label: 'All Test Series', value: 'All Test Series'
        },
        {
            label: 'Add Test Series', value: 'Add Test Series'
        },
        {
            label: 'All Test', value: 'All Test'
        },
        {
            label: 'Add Test', value: 'Add Test'
        },
        {
            label: 'All Quiz', value: 'All Quiz'
        },
        {
            label: 'Add Quiz', value: 'Add Quiz'
        },
        {
            label: 'All Question', value: 'All Question'
        },
        {
            label: 'Add Question', value: 'Add Question'
        },
        {
            label: 'All Coupon', value: 'All Coupon'
        },
        {
            label: 'Add Coupon', value: 'Add Coupon'
        },
        {
            label: 'Recorded Video', value: 'Recorded Video'
        },
        {
            label: 'Add Recorded Video', value: 'Add Recorded Video'
        },
        {
            label: 'Pathyakram', value: 'Pathyakram'
        },

        {
            label: 'Add Pathyakram', value: 'Add Pathyakram'
        },
        {
            label: 'Notes', value: 'Notes'
        },
        {
            label: 'Add Notes', value: 'Add Notes'
        },
        {
            label: 'Previous Year Question Papers', value: 'Previous Year Question Papers'
        },
        {
            label: 'Add Previous Year Question Papers', value: 'Add Previous Year Question Papers'
        },
        {
            label: 'AIR', value: 'AIR'
        },
        {
            label: 'Add AIR', value: 'Add AIR'
        },
        {
            label: 'News Clip', value: 'News Clip'
        },
        {
            label: 'Add News Clip', value: 'Add News Clip'
        },
        {
            label: 'Teacher Generated Content Of Batch', value: 'Teacher Generated Content Of Batch'
        },
        {
            label: 'Alerts', value: 'Alerts'
        },
        {
            label: 'Add Alerts', value: 'Add Alerts'
        },

        {
            label: 'All Assignment', value: 'All Assignment'
        },
        {
            label: 'Add Assignment', value: 'Add Assignment'
        },
        {
            label: 'All Staff', value: 'All Staff'
        },
        {
            label: 'Add Staff', value: 'Add Staff'
        },
        {
            label: 'All Mentors', value: 'All Mentor'
        },
        {
            label: 'Add Mentor', value: 'Add Mentor'
        },
        {
            label: 'All Testimonial', value: 'All Testimonial'
        },
        {
            label: 'Add Testimonial', value: 'Add Testimonial'
        },
        {
            label: 'Bannner', value: 'Bannner'
        },
        {
            label: 'Add Bannner', value: 'Add Bannner'
        },
        {
            label: 'Logout Users', value: 'Logout Users'
        },
        {
            label: 'Subject', value: 'Subject'
        },
        {
            label: 'Add Subject', value: 'Add Subject'
        },
        {
            label: 'Youtube', value: 'Youtube'
        },
        {
            label: 'Add Youtube', value: 'Add Youtube'
        },
        {
            label: 'Telegram', value: 'Telegram'
        },
        {
            label: 'Add Telegram', value: 'Add Telegram'
        },
        {
            label: 'Category', value: 'Category'
        },
        {
            label: 'Add Category', value: 'Add Category'
        },
        {
            label: 'Sub Category', value: 'Sub Category'
        }
        ,
        {
            label: 'Add Sub Category', value: 'Add Sub Category'
        }
        ,
        {
            label: 'Contact Us', value: 'Contact Us'
        },
        {
            label: 'Add Contact Us', value: 'Add Contact Us'
        }
        ,
        {
            label: 'Help And Support', value: 'Help And Support'
        },

    ]



export default { options, keyValueTab, optionsForTeacher };