import React, { useState } from 'react';
import { Box, Typography, Checkbox, Button, Paper, Grid, Divider, Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const questions = [
  {
    id: 1,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    options: ['Option A', 'Option B', 'Option C', 'Option D'],
    correctOption: 'A',
    explanation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  {
    id: 2,
    question: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
    options: ['Option A', 'Option B', 'Option C', 'Option D'],
    correctOption: 'B',
    explanation: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
  },
];

const QuestionCard = ({ questionNumber, questionData, handleSelect }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={1}>
          <Checkbox onChange={(e) => handleSelect(e.target.checked)} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            Q{questionNumber}. {questionData.question}
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Options:</Typography>
            <Typography variant="body2">
              A) {questionData.options[0]} &nbsp;&nbsp; B) {questionData.options[1]}
            </Typography>
            <Typography variant="body2">
              C) {questionData.options[2]} &nbsp;&nbsp; D) {questionData.options[3]}
            </Typography>
          </Box>
          {expanded && (
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Correct Option:</Typography>
              <Typography variant="body2">A) {questionData.correctOption}</Typography>
              <Typography variant="body2" sx={{ marginTop: 1, fontWeight: 'bold' }}>Explanation:</Typography>
              <Typography variant="body2">{questionData.explanation}</Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 30,
              height: 30,
              borderRadius: '50%',
              border: '2px solid #F67382',
              cursor: 'pointer',
              color: '#F67382'
            }}
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ExamAndQuestionManagement = () => {
  const [selectedCount, setSelectedCount] = useState(0);

  const handleSelect = (checked) => {
    setSelectedCount((prev) => (checked ? prev + 1 : prev - 1));
  };

  return (
    <>
      <Box sx={{ padding: '20px', display: 'flex', gap: 3, backgroundColor: 'white' }}>
        <Box sx={{ flex: 2 }}>
          <Typography variant="h5" sx={{ marginBottom: 2 }}>Questions</Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container spacing={2} sx={{ marginBottom: 1 }}>
            <Grid item xs={1}>
              <Typography variant="body" sx={{ marginTop: 1, marginBottom: 1 }}>Select</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="body" sx={{ marginTop: 1, marginBottom: 1 }}>Question</Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

          {questions.map((q, index) => (
            <QuestionCard
              key={q.id}
              questionNumber={index + 1}
              questionData={q}
              handleSelect={handleSelect}
            />
          ))}

          <Box sx={{
            position: 'sticky', bottom: 0, backgroundColor: '#F67382', color: 'white',
            padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'
          }}>
            <Typography>Selected Questions: {selectedCount}</Typography>
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'white',
                color: '#F44336',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#F44336',
                  boxShadow: 'none'
                }
              }}
            >
              Add to Test
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: '30%',
            mt: 4,
            p: 3,
            borderRadius: 3,
            bgcolor: "#FBD6DD",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="#333">
                Exam Category
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                defaultValue=""
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Exam Category</MenuItem>
                <MenuItem value="Central Exam">Central Exam</MenuItem>
                <MenuItem value="State Exam">State Exam</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="#333">
                Exam Type
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                defaultValue=""
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Exam Type</MenuItem>
                <MenuItem value="IIT JEE">IIT JEE</MenuItem>
                <MenuItem value="NEET">NEET</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="#333">
                Subject
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                defaultValue=""
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Subject</MenuItem>
                <MenuItem value="Physics">Physics</MenuItem>
                <MenuItem value="Chemistry">Chemistry</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="#333">
                Chapter
              </Typography>
              <Select
                fullWidth
                variant="outlined"
                defaultValue=""
                sx={{
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                  },
                }}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Chapter</MenuItem>
                <MenuItem value="Chapter 1: Units and Vectors">Chapter 1: Units and Vectors</MenuItem>
                <MenuItem value="Chapter 2: Kinematics">Chapter 2: Kinematics</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  mt: 3,
                  borderRadius: 2,
                  backgroundColor: '#F67382',
                  color: '#fff',
                  '&:hover': { backgroundColor: '#f06292' },
                }}
              >
                Add Question
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ExamAndQuestionManagement;
