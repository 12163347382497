import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
// import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import LoginTeacherForm from '../sections/auth/login/LoginTeacherForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginTeacher() {
    const smUp = useResponsive('up', 'sm');

    const mdUp = useResponsive('up', 'md');

    return (
        <Page title="Login" sx={{}}>
            <RootStyle>
                <HeaderStyle >
                    <Logo sx={{ width: '150px', height: '80px', marginLeft: '65%' }} />

                    {smUp && (
                        <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            Not a Teacher? {''}
                            <Link variant="subtitle2" component={RouterLink} to="/login">
                                Sign in as Admin / SubAdmin
                            </Link>
                        </Typography>
                    )}
                </HeaderStyle>

                {mdUp && (
                    <SectionStyle>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <img src="/static/illustrations/illustration_login.png" alt="login" />
                    </SectionStyle>
                )}

                <Container maxWidth="sm">
                    <ContentStyle>
                        <Typography variant="h4" gutterBottom>
                            Sign in as <span style={{ color: '#EA506B' }}> Teacher  / Moderator</span>
                        </Typography>

                        <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>

                        {/* <AuthSocial /> */}

                        <LoginTeacherForm />

                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                Not a Teacher?{' '}
                                <Link variant="subtitle2" component={RouterLink} to="/login">
                                    Sign in as Admin/SubAdmin
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page>
    );
}
