import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
    Input,
    MenuItem,
    TextField,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';

import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../user';
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import AudioFileIcon from '@mui/icons-material/AudioFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { ExportCSV } from '../../../utils/ExportCSV';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// for Dialog box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { CSVLink } from 'react-csv'; // for CSV file download 
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'sno', label: 'S.No', alignRight: false },
    { id: 'data', label: 'Data', alignRight: false },
    { id: 'audio_file', label: 'File', alignRight: false },
    { id: 'language', label: 'Language', alignRight: false },
    { id: 'created_at', label: 'Date', alignRight: false },
    { id: 'is_active', label: 'Status', alignRight: false },


];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.data.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function AIRTable() {

    // Code for handling Dialog box
    const [download, setDownload] = useState(true);
    const [errorOTP, setErrorOtp] = useState();
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const sendOtp = () => {
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const data = {
            action: "Exporting All AIRs Data"
        };

        axios
            .post(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
                data,
                config
            )
            .then((response) => {
            })
            .catch((e) => {
                //console.log(e);
            });

    }
    const verifyOtp = () => {
        //console.log(document.getElementById('name').value);
        const token = localStorage.getItem("token");
        const authToken = token;
        const config = {
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const data = {
            otp: document.getElementById('name').value
        };

        axios
            .post(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
                data,
                config
            )
            .then((response) => {
                // //console.log(response);
                if (response.data.status == false) {
                    setErrorOtp("Invalid Otp");
                }
                else {
                    setDownload(false);
                    setErrorOtp("Successfully Verified");
                }
            })
            .catch((e) => {
                //console.log(e);
            });
    }



    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');
    const [selectedId, setSelectedId] = useState()

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate()
    const [users, usersSet] = useState()
    const [loading, setLoading] = useState(true)
    const [file, setFile] = useState()



    const [isActiveValue, setIsActiveValue] = useState(false)
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getallindiaradio`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            // setIsActiveValue(responseJson.data.is_active)
            setLoading(false)

        }

        fetchUsers();
    }, []);
    // const deleteBanner = async (id) => {
    //     const token = localStorage.getItem("token");

    //     await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/DeleteAllIndiaRadioDetails/${id}`, {
    //         method: 'DELETE',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`
    //         },

    //     });
    // }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.title);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const filteredUsers = applySortFilter(users ? users : ['no'], getComparator(order, orderBy), filterName);
    //console.log(filteredUsers)

    const [isEdit, setIsEdit] = useState(false)
    const [updatedValues, setUpdatedValues] = useState({})
    let helperArray = [];
    filteredUsers?.map((item) => helperArray.push({ _id: item?._id, audio_file: item?.audio_file?.fileLoc, data: item?.data, created_at: item?.created_at, is_active: item?.is_active, language: item?.language }))

    const isUserNotFound = filteredUsers.length === 0 || filteredUsers == 'no';
    //for update
    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '60vh' }}> <SyncLoader color='red' loading={loading} size={20} /></Stack>) :
            (<Page title="AIR">
                <Container sx={{ position: 'absolute', minWidth: '100%' }}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                        {/* <Typography variant="h3" gutterBottom>
                            All AIR
                        </Typography> */}
                        {/* <Button variant='contained' sx={{ width: '20%', alignSelf: 'center', background: '#EA506B', '&:hover': { background: 'red' } }} >Add Student</Button> */}
                    </Stack>

                    <Card>

                        <Stack pr={3} direction='row' justifyContent='space-between' alignItems='center' >
                            <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                            {/* <ExportCSV csvData={helperArray} fileName='AIR Data' /> */}
                            <Button variant="outlined" onClick={() => { handleClickOpen(); sendOtp(); }}>
                                Export
                            </Button>
                            <Dialog open={open} onClose={handleClose}>
                                {download ? (
                                    <>
                                        <DialogTitle>OTP Varification</DialogTitle>
                                        <DialogContent>
                                            <DialogContentText>
                                                OTP is send successfully to your regisered e-mail.
                                            </DialogContentText>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="OTP Number"
                                                type="number"
                                                fullWidth
                                                variant="standard"
                                            />
                                            <DialogContentText sx={{ color: "red", fontSize: '12px', padding: "3px" }}>
                                                {errorOTP}
                                            </DialogContentText>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={handleClose}>Cancel</Button>
                                            <Button onClick={verifyOtp}>Verify</Button>
                                        </DialogActions>
                                    </>
                                ) : (
                                    <>
                                        <DialogTitle>Download File</DialogTitle>
                                        <DialogContent>
                                            <CSVLink data={helperArray} filename="AllAIRs.csv">Download</CSVLink>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={() => {
                                                handleClose();
                                                setDownload(true);
                                            }}>Cancel</Button>

                                        </DialogActions>
                                    </>
                                )}
                            </Dialog>
                        </Stack>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={users?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                    />
                                    <TableBody>
                                        {filteredUsers.length > 0 && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { id, data, _id, audio_file, language, created_at, is_active, } = row;
                                            const isItemSelected = selected.indexOf(data) !== -1;
                                            //console.log(filteredUsers)
                                            {/* row.sno = index + 1 + rowsPerPage * page */ }
                                            // const uniqueKey = id ? `row_${id}` : `row_${index}`;
                                            const TDate = created_at?.split(" ")[0]



                                            //Handling File 
                                            const changeHandler = async (event) => {
                                                setFile(event.target.files[0])


                                            };
                                            //handling Updated values 
                                            const onChange = (e, row, _id) => {
                                                const { name, value } = e.target
                                                if (name == 'is_active') {
                                                    //console.log('came')
                                                    setIsActiveValue(true)
                                                }
                                                setUpdatedValues((prev) => {
                                                    return { ...prev, [name]: value }
                                                })





                                            }
                                            const handleUpdate = async () => {
                                                setLoading(true)
                                                let data = { ...updatedValues }

                                                makeReq(data)
                                            }
                                            const makeReq = async (data) => {
                                                const token = localStorage.getItem("token");
                                                const authToken = token;
                                                const formData = new FormData();
                                                if (file) {
                                                    formData.append('file', file)

                                                }

                                                if (data.data) { formData.append('data', data.data) }


                                                if (data.language) { formData.append('language', data.language) }

                                                if (isActiveValue) { formData.append('is_active', data.is_active) }





                                                const config = {
                                                    headers: {
                                                        'content-type': 'multipart/form-data',
                                                        Authorization: `Bearer ${authToken}`,
                                                    },
                                                };
                                                const res = await axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedAllIndiaRadioDetails/${_id}`, formData, config);
                                                toast.success('Updated')
                                                setLoading(false)
                                                setTimeout(() => window.location.reload()
                                                    , 800)
                                            }
                                            return (<>
                                                {row !== 'no' && <TableRow
                                                    hover
                                                    key={index}
                                                    tabIndex={-1}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >
                                                    {/* <TableCell padding="checkbox">
                                                <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, data)} />
                                            </TableCell> */}
                                                    <TableCell  >
                                                        <Typography variant='subtitle2' >{index + 1 + rowsPerPage * page}</Typography>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" pl={2}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {/* <Avatar alt={name} src={avatarUrl} /> */}
                                                            <Typography variant="subtitle2" noWrap>
                                                                {isEdit && selectedId === _id ? (<Input
                                                                    onChange={(e) => onChange(e, row, _id)}
                                                                    // value={value}
                                                                    name='data'
                                                                    placeholder={data}



                                                                />) : <span>{data}</span>}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {isEdit && selectedId === _id ? <>
                                                            <input
                                                                style={{
                                                                    display: "none"
                                                                }}

                                                                id="choose-file"
                                                                type="file"
                                                                onChange={changeHandler}

                                                            />
                                                            <label htmlFor="choose-file">
                                                                <FileUploadIcon sx={{
                                                                    cursor: 'pointer', '&:hover': {
                                                                        color: 'red'
                                                                    }
                                                                }} />
                                                            </label>
                                                        </> : (<a href={audio_file?.fileLoc} style={{ color: 'black' }}>
                                                            <AudioFileIcon

                                                                sx={{
                                                                    cursor: 'pointer', '&:hover': {
                                                                        color: 'red'
                                                                    }
                                                                }} />
                                                        </a>)}
                                                    </TableCell>
                                                    <TableCell align="left">{isEdit && selectedId === _id ? (<TextField
                                                        onChange={(e) => onChange(e, row)}

                                                        label={language === 'hi' ? 'Hindi' : 'English'}
                                                        name='language'
                                                        placeholder={language === 'hi' ? 'Hindi' : language == 'en' ? 'English' : 'Hindi/English'}
                                                        size="small"
                                                        select
                                                        sx={{ width: '100px' }}>
                                                        <MenuItem value='hi'>Hindi</MenuItem>
                                                        <MenuItem value='en'>English</MenuItem>
                                                        <MenuItem value='enhi'>Bilingual</MenuItem>
                                                    </TextField>) : <span> {language === 'hi' ? 'Hindi' : language === 'en' ? 'English' : 'Hindi/English'}</span>}</TableCell>
                                                    <TableCell align="left">{TDate}</TableCell>
                                                    <TableCell align="left">{isEdit && selectedId === _id ? (<TextField
                                                        onChange={(e) => onChange(e, row)}
                                                        size="small"
                                                        label={is_active ? 'Active' : 'Inactive'}
                                                        name='is_active'
                                                        placeholder={is_active ? 'Active' : 'Inactive'}
                                                        select
                                                        sx={{ width: '100px' }}>
                                                        <MenuItem value={true}>Active</MenuItem>
                                                        <MenuItem value={false}>Inactive</MenuItem>
                                                    </TextField>) : <span style={{ background: is_active === true ? 'green' : is_active === false ? 'red' : '', padding: '5px', borderRadius: '10px', color: 'white', fontWeight: '500' }}>{is_active === true ? 'Active' : is_active === false ? 'Inactive' : ''}</span>}</TableCell>
                                                    {isEdit && selectedId === _id ? (< TableCell align="left">
                                                        <Button variant='outlined' onClick={handleUpdate}>Update</Button>
                                                    </TableCell>) : null}


                                                    <TableCell align="right" onClick={() => setSelectedId(_id)}>
                                                        <UserMoreMenu id={_id} req='DeleteAllIndiaRadioDetails' isEdit={isEdit} setIsEdit={setIsEdit} setLoading={setLoading} />
                                                    </TableCell>
                                                </TableRow>}
                                            </>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {(isUserNotFound || filteredUsers?.length == 0) && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={1000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>
            </Page>)}
    </>
    );
}


















