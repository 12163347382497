import { Card, Grid, Stack, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fShortenNumber } from "../../../utils/formatNumber";
import Iconify from "../../../components/Iconify";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import InfoCard from "../../../components/batch/InfoCard";
import SyncLoader from "react-spinners/SyncLoader";

const StudentBatches = () => {
  const navigate = useNavigate();
  const [batchInfo, setBatchInfo] = useState([]);
  const [filteredBatchInfo, setFilteredBatchInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  // BatchesDetails
  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setBatchInfo(responseJson.data);
      setLoading(false);
      setFilteredBatchInfo(responseJson.data); // Initialize filtered data
    }

    fetchUsers();
  }, []);

  // Handle search input changes
  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    // If the search input is empty, show all batch cards
    if (searchValue === "") {
      setFilteredBatchInfo(batchInfo);
    } else {
      // Filter the batch information based on the search input
      const filteredData = batchInfo.filter(
        (item) =>
          item.batch_name &&
          item.batch_name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilteredBatchInfo(filteredData);
    }
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Grid
            container
            pl={5}
            pr={3}
            justifyContent="space-between"
            alignItems="flex-start" // Align items at the cross-start
          >
            <Grid item>
              <Typography variant="h3">User Batches</Typography>
            </Grid>
            {/* Search Bar */}
            <Grid item>
              <TextField
                label="Search Batch"
                variant="outlined"
                margin="normal"
                value={searchInput}
                onChange={handleSearchInputChange}
                sx={{
                  width: "300px", 
                  height: "20px",
                  marginBottom: "70px",
                  "& label": {
                    color: "black", 
                  },
                  "& fieldset": {
                    borderColor: "black",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={4} pl={5} pr={3}>
            {filteredBatchInfo.map((batch, index) => {
              const { _id, is_active } = batch;

              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{
                    cursor: "pointer",
                    margin: 0,
                    padding: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() =>
                    navigate(
                      `/dashboard/studentBatches/studentList/${
                        is_active ? "1" : "0"
                      }/${_id}`
                    )
                  }
                >
                  <InfoCard
                    title="Info"
                    data={batch}
                    path=""
                    use="Student Batches"
                    setLoading={setLoading}
                    sx={{ flexGrow: 1 }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
    </>
  );
  
};

export default StudentBatches;
