// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { useEffect } from 'react';
import axios from 'axios';
// import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_LIVE_URL;
  }, [])
  return (
    <ThemeProvider>
      <ScrollToTop />

      {/* <BaseOptionChartStyle /> */}
      <Router />
    </ThemeProvider>
  );
}
