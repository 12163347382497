import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  AppBar,
  Toolbar,
  Stack,
  IconButton,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableContainer,
  TablePagination,
  Card,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import axios from "axios";
import Scrollbar from "../../../components/Scrollbar";
import { UserListToolbar } from "../user";

const AssignStudentsDialog = ({ open, onClose, communityId }) => {
  const [allStudents, setAllStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filterStudent, setFilterStudent] = useState("");
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      const context = this;
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        timeoutId = null;
        func.apply(context, args);
      }, delay);
    };
  };

  const debouncedFetchUsers = useCallback(
    debounce((filterStudent) => {
      const token = localStorage.getItem("token");
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`,
            {
              params: {
                page: page1 + 1, // Backend expects page starting from 1
                limit: rowsPerPage1,
                search: filterStudent,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setAllStudents(response.data?.data || []);
          setCount(response.data?.count || 0);
          setLoading(false);

          if (response.data.msg === "not an admin") {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("details");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
          console.error("Error details:", error.response);
          setLoading(false);
        }
      };

      fetchData();
    }, 1500),
    [page1, rowsPerPage1]
  );

  useEffect(() => {
    debouncedFetchUsers(filterStudent);
  }, [filterStudent, debouncedFetchUsers]);

  const handleCheck = (event, id) => {
    const selectedIndex1 = selectedStudent.indexOf(id);
    let newSelected = [];
    if (selectedIndex1 === -1) {
      newSelected = newSelected.concat(selectedStudent, id);
    } else if (selectedIndex1 === 0) {
      newSelected = newSelected.concat(selectedStudent.slice(1));
    } else if (selectedIndex1 === selectedStudent.length - 1) {
      newSelected = newSelected.concat(selectedStudent.slice(0, -1));
    } else if (selectedIndex1 > 0) {
      newSelected = newSelected.concat(
        selectedStudent.slice(0, selectedIndex1),
        selectedStudent.slice(selectedIndex1 + 1)
      );
    }
    setSelectedStudent(newSelected);
  };

  const handleAssign = () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const data = {
      memberIds: selectedStudent,
    };

    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/add-member/${communityId}`,
        data,
        config
      )
      .then((response) => {
        toast.success(response.data.msg, { autoClose: 3000 });
        onClose();
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        onClose();
      });
    setSelectedStudent([]);
     setTimeout(() => window.location.reload(), 3000);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleFilterStudentByName = (event) => {
    setFilterStudent(event.target.value);
    setPage1(0);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppBar sx={{ position: "relative", background: "#ea506b" }}>
        <Toolbar sx={{ width: "100%" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <DialogTitle>Assign Members</DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <UserListToolbar
                numSelected={selectedStudent.length}
                filterName={filterStudent}
                onFilterName={handleFilterStudentByName}
                from="AssignStudent"
              />
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1000 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Selected</TableCell>
                    <TableCell align="left">Student Name</TableCell>
                    <TableCell align="left">Mobile No.</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Username</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allStudents.map((row, index) => {
                    const {
                      id,
                      StudentName,
                      email,
                      username,
                      mobileNumber,
                      profilePhoto,
                    } = row;
                    const isItemSelected1 = selectedStudent.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected1}
                        aria-checked={isItemSelected1}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected1}
                            onChange={(event) => {
                              handleCheck(event, id);
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" pl={2}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar
                              alt={StudentName}
                              src={profilePhoto}
                              sx={{ width: 28, height: 28 }}
                            />
                            <Typography variant="subtitle2" noWrap>
                              {StudentName}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {mobileNumber ? mobileNumber : "NA"}
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{username}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            count={count}
            component="div"
            rowsPerPage={rowsPerPage1}
            page={page1}
            onPageChange={handleChangePage1}
            onRowsPerPageChange={handleChangeRowsPerPage1}
          />
        </Card>
      </DialogContent>
      <Stack direction="row" alignItems="center" justifyContent="center" m={3}>
        <Button
          onClick={handleAssign}
          variant="contained"
          sx={{
            width: "20%",
            alignSelf: "center",
            background: "#EA506B",
            "&:hover": { background: "red" },
          }}
        >
          Assign
        </Button>
      </Stack>
    </Dialog>
  );
};

export default AssignStudentsDialog;
