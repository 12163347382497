import { filter } from 'lodash';
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    // Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Button,
} from '@mui/material';
// components
import Page from '../../../components/Page';

import Scrollbar from '../../../components/Scrollbar';

import SearchNotFound from '../../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../sections/@dashboard/user';
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from 'react-router-dom';
import SyncLoader from "react-spinners/SyncLoader";
// ----------------------------------------------------------------------

const TABLE_HEAD = [

    { id: 'sno', label: 'S NO', alignRight: false },
    { id: 'lectureName', label: 'Lecture Name', alignRight: false },
    { id: 'batch', label: 'Batch Name', alignRight: false },
    { id: 'platformtype', label: 'Platform Type', alignRight: false },
    { id: 'lectureType', label: 'Lecture Type', alignRight: false },
    { id: 'assignedTeacher', label: 'Assigned Teacher', alignRight: false },
    { id: 'language', label: 'Language', alignRight: false },
    { id: 'startDateTime', label: 'Starting Date & Time', alignRight: false },




];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.lecture_title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TodaysSchedule() {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const role = localStorage.getItem("role")
    const [users, setUsers] = useState([])
    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getMySchedularTeacherSide`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const responseJson = await fullResponse.json();
            setUsers(responseJson.data);

        }

        fetchUsers();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users?.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

    const filteredUsers = applySortFilter(users ? users : ['no'], getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (<>
        {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color='red' loading={loading} size={20} /></Stack>) :
            (<Page title="Dashboard">
                <Container sx={{
                    minWidth: '100%',

                }}>


                    <Card >
                        {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                        <Typography variant='h6' pl={2} pt={2} sx={{ textAlign: 'center' }}>{role == 'Teacher' ? 'My Schedule' : "Today's Schedule"}</Typography>
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: '100%' }}>

                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={users?.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        title='Todays Scchedule'
                                    />
                                    <TableBody>
                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const { id, lecture_title, lecture_type, starting_date, language, LiveOrRecorded, teacher, batch } = row;
                                            const isItemSelected = selected.indexOf(lecture_title) !== -1;
                                            const uniqueKey = id ? `row_${id}` : `row_${index}`;


                                            {/* let fname = row?.teacher[0]?.FullName */ }
                                            {/* //console.log(fname) */ }
                                            {/* //console.log(row?.teacher[0]) */ }
                                            return (
                                                <TableRow
                                                    hover
                                                    key={uniqueKey}
                                                    tabIndex={-1}

                                                    selected={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                >

                                                    <TableCell >
                                                        <Typography variant="subtitle2" noWrap>
                                                            {index + 1}
                                                        </Typography>

                                                    </TableCell>

                                                    <TableCell component="th" scope="row" pl={2}>
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {/* <Avatar alt={lecture_title} src={profilePhoto} sx={{ width: 24, height: 24 }} /> */}
                                                            <Typography variant="subtitle2" noWrap>
                                                                {lecture_title}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{batch}</TableCell>
                                                    <TableCell align="left">{lecture_type == 'YT' ? 'Youtube' : 'UPSC Hindi Application'}</TableCell>
                                                    <TableCell align="left" >{LiveOrRecorded}</TableCell>

                                                    {row?.teacher?.map((item, index) => <TableCell key={index} align="left">{item?.FullName}</TableCell>)}
                                                    {/* <TableCell align="left">{teacher[0]?.FullName}</TableCell> */}
                                                    <TableCell align="left">{language === 'hi' ? 'Hindi' : language == 'en' ? 'English' : 'Hindi/English'}</TableCell>
                                                    <TableCell align="left">{starting_date}</TableCell>
                                                    {/* <TableCell align="left">{username}</TableCell> */}


                                                    {/* <TableCell align="right">
                                                        <UserMoreMenu id={userId} />
                                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isUserNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                                    <SearchNotFound searchQuery={filterName} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={users?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </ Card>
                </Container>
            </Page >)
        }
    </>
    );
}



