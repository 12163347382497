import { Grid, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import Page from "../../../components/Page";
import TestSeriesCard from "./components/TestSeriesCard";
import SyncLoader from "react-spinners/SyncLoader";
import "./test.css";
import { useTheme } from "@emotion/react";

const Test = () => {
  const access = JSON.parse(localStorage.getItem("detailObject"));
  const theme = useTheme();

  // State for search input and filtered test series data
  const [searchInput, setSearchInput] = useState("");
  const [filteredTestSeries, setFilteredTestSeries] = useState([]);

  const sampleArray = [
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
    {
      title: "UPSC",
      students: 1245,
      icon: "carbon:rule-test",
    },
  ];

  const navigate = useNavigate();

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    margin: "auto",
    display: "flex",
    borderRadius: "50%",
    alignItems: "center",
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  }));
  // Test Series Details
  const [loading, setLoading] = React.useState(true);
  const [users, usersSet] = React.useState([]);

  useEffect(() => {
    const token = localStorage.getItem("token");

    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getallTestSeriesdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);

      // Set filtered test series initially with all data
      setFilteredTestSeries(responseJson.data);
    }

    fetchUsers();
  }, []);

  // Handle search input changes
  const handleSearchInputChange = (event) => {
    const searchValue = event.target.value;
    setSearchInput(searchValue);

    // If the search input is empty, show all test series cards
    if (searchValue === "") {
      setFilteredTestSeries(users);
    } else {
      // Filter the test series based on the search input
      const filteredData = users.filter((item) =>
        item.testseries_name.toLowerCase().includes(searchValue.toLowerCase())
      );

      setFilteredTestSeries(filteredData);
    }
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader
            color={theme.palette.primary.mainTheme}
            loading={loading}
            size={20}
          />
        </Stack>
      ) : (
        <Page title="Test Series">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            pl={5}
            pr={3}
          >
            <Typography className="page-heading">All Test Series</Typography>

            {/* Search Bar */}
            <TextField
              label="Search Test Series"
              variant="outlined"
              margin="normal"
              value={searchInput}
              onChange={handleSearchInputChange}
              sx={{
                width: "300px", 
                height: "20px",
                marginBottom: "70px",
                "& label": {
                  color: "black", 
                },
                "& fieldset": {
                  borderColor: "black", 
                },
              }}
            />
          </Grid>

          {/* Display Test Series Cards */}
          <Grid container pl={5} pr={3} spacing={3}>
            {(access?.access.includes("Add Test Series") ||
              access?.access.includes("all")) && (
              <Grid item xs={12} sm={6} md={4}>
                <TestSeriesCard title="notInfo" />
              </Grid>
            )}
            {filteredTestSeries.map((item, i) => (
              <Grid item key={i} xs={12} sm={6} md={4}>
                <TestSeriesCard title="Info" data={item} />
              </Grid>
            ))}
          </Grid>
        </Page>
      )}
    </>
  );
};

export default Test;