import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
  Tabs,
  Tab,
  List,
  ListItemButton,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';
import { Link } from 'react-router-dom';

const fetchQuestionsData = async () => {
  return {
    totalQuestions: 4,
    duration: 180,
    marksPerQuestion: 2,
    subjects: ['Physics', 'Chemistry', 'Mathematics'],
    questions: [
      {
        id: 1,
        subject: 'Physics',
        text: 'What is the speed of light?',
        options: {
          A: '3 x 10^8 m/s',
          B: '3 x 10^5 m/s',
          C: '3 x 10^2 m/s',
          D: '3 x 10^6 m/s',
        },
        explanation: 'The speed of light in a vacuum is 3 x 10^8 meters per second.',
        correctOption: 'A',
      },
      {
        id: 2,
        subject: 'Chemistry',
        text: 'What is the molecular formula of water?',
        options: {
          A: 'H2O',
          B: 'CO2',
          C: 'O2',
          D: 'H2SO4',
        },
        explanation: 'Water is composed of two hydrogen atoms and one oxygen atom, hence H2O.',
        correctOption: 'A',
      },
      {
        id: 3,
        subject: 'Mathematics',
        text: 'What is the derivative of x^2?',
        options: {
          A: 'x',
          B: '2x',
          C: 'x^2',
          D: '2x^2',
        },
        explanation: 'The derivative of x^2 with respect to x is 2x.',
        correctOption: 'B',
      },
      {
        id: 4,
        subject: 'Physics',
        text: 'What is the unit of force?',
        options: {
          A: 'Newton',
          B: 'Joule',
          C: 'Pascal',
          D: 'Watt',
        },
        explanation: 'The SI unit of force is Newton, named after Sir Isaac Newton.',
        correctOption: 'A',
      },
    ],
  };
};

function TestSeriesPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await fetchQuestionsData();
      setData(response);
      setLoading(false);
    };
    loadData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedQuestion(0);
  };

  const handleQuestionClick = (index) => {
    setSelectedQuestion(index);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const { totalQuestions, duration, marksPerQuestion, subjects, questions } = data;

  const filteredQuestions = questions.filter(
    (question) => question.subject === subjects[selectedTab]
  );

  return (
    <Box p={3} bgcolor="#F8F8F8">
      <Typography variant="h4" gutterBottom>
        Test Series Name Goes Here
      </Typography>

      <Paper elevation={3} sx={{ display: 'flex', bgcolor: '#FBD6DD' }}>
        <Box flex={1} bgcolor="#FBD6DD">
          <Box
            p={2}
            bgcolor="#F67382"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" color="#FFF">
              {totalQuestions} Questions
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/dashboard/library"
              sx={{
                borderRadius: 2,
                backgroundColor: '#FFF',
                color: 'black',
                border: '1px solid #CCC',
                '&:hover': {
                  backgroundColor: '#F0F0F0',
                },
              }}
            >
              + Add Question
            </Button>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            centered
          >
            {subjects.map((subject, index) => (
              <Tab key={index} label={subject} />
            ))}
          </Tabs>
          <List>
            {filteredQuestions.map((question, index) => (
              <ListItemButton
                key={question.id}
                selected={selectedQuestion === index}
                onClick={() => handleQuestionClick(index)}
                sx={{
                  backgroundColor: selectedQuestion === index ? 'white !important' : 'transparent',
                  color: selectedQuestion === index ? 'black !important' : 'black',
                  '&:hover': {
                    backgroundColor: selectedQuestion === index ? 'white' : '#F0F0F0',
                  },
                  p: 2,
                }}
              >
                <Typography
                  variant="body2"
                  color={selectedQuestion === index ? '#F67382' : 'inherit'}
                >
                  Q{index + 1}. {question.text}
                </Typography>
              </ListItemButton>
            ))}
          </List>
        </Box>

        <Divider orientation="vertical" flexItem sx={{ bgcolor: '#F67382', width: '3px' }} />

        <Box flex={2} p={2} bgcolor="#FBD6DD">
          <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box
              sx={{
                px: 2,
                py: 1,
                backgroundColor: '#F67382',
                color: 'white',
                borderRadius: '16px',
              }}
            >
              <Typography variant="body1">Marks per Question: {marksPerQuestion}</Typography>
            </Box>
            <Box
              sx={{
                px: 2,
                py: 1,
                backgroundColor: '#F67382',
                color: 'white',
                borderRadius: '16px',
              }}
            >
              <Typography variant="body1">Duration: {duration} Min</Typography>
            </Box>
          </Box>
          <Divider
  sx={{
    borderColor: "black", 
    borderWidth: 0.5,      
  }}
/>

          <Box p={3}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="h6" gutterBottom>
                Question:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {filteredQuestions[selectedQuestion]?.text ||
                  'No questions available for this subject.'}
              </Typography>
            </Box>

            
            <Box mt={2} mb={1}>
              <Grid container>
                
                <Grid item xs={1.5}>
                  <Typography variant="h6" gutterBottom>
                    Options:
                  </Typography>
                </Grid>

                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    {filteredQuestions[selectedQuestion]?.options &&
                      Object.entries(filteredQuestions[selectedQuestion].options).map(
                        ([key, value], index) => {
                          const isCorrect = key === filteredQuestions[selectedQuestion].correctOption;
                          return (
                            <Grid item xs={6} key={key}>
                              <Paper
                                variant="outlined"
                                sx={{
                                  p: 2,
                                  backgroundColor: isCorrect ? '#C8E6C9' : '#FFF',
                                  border: isCorrect ? '2px solid #4CAF50' : '1px solid #CCC',
                                }}
                              >
                                <Typography variant="body1">
                                  {key}) {value}
                                </Typography>
                              </Paper>
                            </Grid>
                          );
                        }
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {filteredQuestions[selectedQuestion] && (
              <Box mt={3} display="flex" alignItems="center">
                <Typography variant="h6" gutterBottom>
                  Explanation:
                </Typography>
                <Typography variant="body1" gutterBottom ml={1}>
                  {filteredQuestions[selectedQuestion].explanation}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default TestSeriesPage;
