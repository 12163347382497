// @mui
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
// components
import Page from "../components/Page";
import Iconify from "../components/Iconify";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  // AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from "../sections/@dashboard/app";
import UserListHead from "../sections/@dashboard/user/UserListHead";
import Label from "../components/Label";
import { UserMoreMenu } from "../sections/@dashboard/user";
import { useEffect, useState } from "react";
import Student from "./User";
import NewlyResitered from "../sections/@dashboard/app/NewlyResitered";
import TodaysSchedule from "../sections/@dashboard/app/TodaysSchedule";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const [users, usersSet] = useState([]);
  const [batches, setBatches] = useState([]);
  const [testSeries, setTestSeries] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teacherData, setTeacherData] = useState([]);
  const role = localStorage.getItem("role");
  const teacherId = localStorage.getItem("id");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      //Admin
      usersSet(responseJson.data);
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const Batch = await res.json();
      setBatches(Batch.data);
      //TeacherBatches
      if (role === 'Teacher') {
        const teacherResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails?teacher_id=${teacherId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await teacherResponse.json();
        setTeacherData(response.data);
      }

      const resTeacher = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const Teacher = await resTeacher.json();
      setTeachers(Teacher.data);
      const testSeriesResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getallTestSeriesdetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const TestSeries = await testSeriesResponse.json();
      setTestSeries(TestSeries.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h3" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Batches"
                total={batches?.length}
                icon={"eva:shopping-bag-fill"}
              />
            ) : (
              <AppWidgetSummary
                title="All Assigned Batches"
                total={teacherData?.length}
                icon={"eva:shopping-bag-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Users"
                total={users?.length}
                color="info"
                icon={"eva:people-fill"}
              />
            ) : (
              <AppWidgetSummary
                title="All Resources"
                total={0}
                color="info"
                icon={"eva:people-fill"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            {role === "Admin" ? (
              <AppWidgetSummary
                title="All Teachers"
                total={teachers?.length}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
            ) : (
              <AppWidgetSummary
                title="All Teachers"
                total={teachers?.length}
                color="warning"
                icon={"fa-solid:chalkboard-teacher"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="All Test Series"
              color="error"
              total={testSeries?.length}
              icon={"eva:file-text-fill"}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TodaysSchedule />
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{ display: role === "Teacher" ? "none" : "" }}
          >
            <NewlyResitered />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
