import { Avatar, Box, Card, Stack, Typography } from '@mui/material'
import React from 'react'

const BoxStyle = {
    background: 'linear-gradient(180deg, rgba(249, 83, 104, 0.15) 0%, rgba(249, 83, 104, 0.195) 100%)',
    height: '45%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px'

}
const SpecialClasses = ({ value }) => {
    //console.log(value)
    const CardStyle = {
        minHeight: '160px',
        minWidth: '31%',
        maxWidth: '240px',
        transition: 'all 0.3s',
        translate: `${value}px`,
    }
    return (
        <Card sx={{ ...CardStyle }}>

            <Box sx={{ ...BoxStyle }}>
                <Avatar alt='Alternate' sx={{ width: 54, height: 54, }} />
            </Box>
            <Stack justifyContent="space-between" pt={1} pb={1} pr={1.5} pl={1.5} sx={{ height: '50%' }}>
                <Typography fontSize="small" color="#F95368" sx={{ fontWeight: '400' }}>English</Typography>
                <Typography fontSize="15px" color="#1F1F39" sx={{ fontWeight: '700' }}>A Quick Discussion on Tense</Typography>
                <Stack direction="row" justifyContent="space-between">
                    <Typography fontSize="small" color="rgba(114, 112, 112, 1)" sx={{ fontSize: '10px', fontWeight: '400' }}>Ends on Dec 16,2022</Typography>
                    <Typography fontSize="small" color="rgba(114, 112, 112, 1)" sx={{ fontSize: '10px', fontWeight: '400' }}>Teacher Name</Typography>
                </Stack>
            </Stack>
        </Card>
    )
}

export default SpecialClasses