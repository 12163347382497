import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Button,
  Chip,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FollowRequests = () => {
  const { id } = useParams();

  const [followRequests, setFollowRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFollowRequests = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("User not authorized");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL}/admin-community/follow-request?id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.status) {
          setFollowRequests(response.data.data);
        } else {
          throw new Error(
            response.data.error || "Failed to fetch follow requests"
          );
        }
      } catch (error) {
        console.error("Error fetching follow requests:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowRequests();
  }, [id]);

  const handleAccept = async (requestId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("User not authorized");
      }

      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/follow-request/${requestId}`,
        { isApproved: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        const updatedRequests = followRequests.map((request) =>
          request._id === requestId ? { ...request, isApproved: true } : request
        );
        setFollowRequests(updatedRequests);
        toast.success("Follow request accepted successfully!");
      } else {
        throw new Error(
          response.data.error || "Failed to accept follow request"
        );
      }
    } catch (error) {
      console.error("Error accepting follow request:", error.message);
      toast.error("Failed to accept follow request");
    }
  };

  const handleRemove = async (requestId) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("User not authorized");
      }

      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/follow-request/${requestId}`,
        { isApproved: false },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.status) {
        const updatedRequests = followRequests.filter(
          (request) => request._id !== requestId
        );
        setFollowRequests(updatedRequests);
        toast.success("Follow request removed successfully!");
      } else {
        throw new Error(
          response.data.error || "Failed to remove follow request"
        );
      }
    } catch (error) {
      console.error("Error removing follow request:", error.message);
      toast.error("Failed to remove follow request");
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" mt={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>
        Follow Requests
      </Typography>
      {followRequests?.length === 0 ? (
        <Typography variant="body1">No follow requests found.</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No.</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Community Name</TableCell>
                <TableCell>Message</TableCell>
                <TableCell>Requested At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {followRequests.map((request, index) => (
                <TableRow key={request?._id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{request?.userId?.FullName}</TableCell>
                  <TableCell>{request?.communityId?.name}</TableCell>
                  <TableCell>{request?.message}</TableCell>
                  <TableCell>
                    {request?.created_at?.slice(0,10)}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={request?.isApproved ? "Accepted" : "Pending"}
                      color={request?.isApproved ? "success" : "warning"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {request?.isApproved === false && (
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleAccept(request?._id)}
                          sx={{ minWidth: "100px" }}
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => handleRemove(request?._id)}
                          sx={{ minWidth: "100px" }}
                        >
                          Remove
                        </Button>
                      </Stack>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default FollowRequests;
