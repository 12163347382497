import React from "react";
import * as Yup from "yup";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";

// form
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../components/hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

const LectureForm = () => {
  const navigate = useNavigate();
  // const { setRole } = useContext(User)
  const [focus, setFocused] = React.useState(false);
  const [resources, setResources] = React.useState(false);
  const [loading, setLoading] = useState(false);
  // const [focusThree, setFocusedThree] = React.useState(false);
  // const [focusTwo, setFocusedTwo] = React.useState(false);

  // const [showStartingDate, setShowStartingDate] = React.useState(false);
  // const [showEndingDate, setShowEndingDate] = React.useState(false);

  const [users, usersSet] = React.useState([]);
  const { Id, batchName } = useParams();
  const [positive, setPositive] = useState(false);
  const [fetchMessage, setFetchMessage] = useState();
  const [starting, setStarting] = React.useState(dayjs());
  const [ending, setEnding] = React.useState(dayjs());

  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const handleChangeTwo = (newValue) => {
    setEnding(newValue);
  };

  const LoginSchema = Yup.object().shape({
    lecture_title: Yup.string().required("Title is required"),
    // batch_name: Yup.string().required('Batch Name is required'),
    description: Yup.string().required("Description is required"),
    // LiveOrRecorded: Yup.string().required("Type is required"),
    // starting_date: Yup.string().required('Staring date is required'),
    // ending_date: Yup.string().required('Ending Date is required'),
    subject_id: Yup.string().required("Subject  required"),
    language: Yup.string().required("language  required"),
    lecture_type: Yup.string().required("Lecture Type  required"),
    // link: Yup.string().required('Link  required'),
    // materials: Yup.string().required('Resources  required'),
  });

  const defaultValues = {
    lecture_type: "",
    lecture_title: "",
    // batch_name: '',
    description: "",
    // starting_date: '',
    // ending_date: '',
    subject_id: "",
    // LiveOrRecorded: "",
    language: "",
    remember: true,
    link: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const [file, setFile] = useState();

  function matchYoutubeUrl(url) {
    //console.log("Cameeeeeeee In Here");
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]{11})/;
    // var p = /^https:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]/;
    var matches = url.match(p);
    if (matches) {
      //console.log("heeee");
      setValidateUrl(true);
      return matches[1];
    }
    return false;
  }
  const [utubeurl, setUtubeurl] = useState("");
  const [validateYoutubeUrl, setValidateUrl] = useState(false);

  function check(sender) {
    //console.log("huu");
    // var url = $('#txt').val();
    var id = matchYoutubeUrl(utubeurl);
    if (id != false) {
      setValidateUrl(true);
      //console.log("Cameeeeeeee");
      // alert(id);
    } else {
      //console.log("else of validate");
      setValidateUrl(false);
      // alert('Incorrect URL');
    }
  }

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };
  let formatDate = (date) => {
    let datehelper = "" + date;
    datehelper = datehelper.split(" ");
    let monthsList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let time = datehelper[4];
    let year = datehelper[3];
    let month = `0${monthsList.indexOf(datehelper[1]) + 1}`.slice(-2);
    let day = datehelper[2];

    return `${day}-${month}-${year} ${time}`;
  };

  const onSubmit = async (data) => {
    // //console.log(data);
    let StartingDate = formatDate(starting.$d);
    let EndingDate = formatDate(ending.$d);

    if (!youtubeVideo || validateYoutubeUrl) {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("lecture_type", data.lecture_type);
      formData.append("lecture_title", data.lecture_title);
      formData.append("description", data.description);
      formData.append("language", data.language);
      if (youtubeVideo) {
        formData.append("link", utubeurl);
      } else {
        formData.append("link", "");
      }

      if (youtubeVideo) {
        formData.append("LiveOrRecorded", data.LiveOrRecorded);
      } else {
        formData.append("LiveOrRecorded", "Live");
      }

      formData.append("subject_id", data.subject_id);
      formData.append("batch_id", Id);
      formData.append("starting_date", StartingDate);
      formData.append("ending_date", EndingDate);
      const YtLiveToken = localStorage.getItem("YtLiveToken");

      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${authToken}`,
        },
      };


      axios
        .post(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/addLecture`,
          formData,
          config
        )
        .then((response) => {
          //console.log(response.data);
          setFetchMessage();

          if (response.data.msg === "added the Lecture details successfully") {
            // setPositive(true)
            setLoading(false);
            toast.success("Added The Lecture Details");
            reset();
            setTimeout(() => {
              navigate(`/dashboard/batchDetails/${Id}`);
            }, 1000);
          } else {
            toast.error(response.data.msg);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };
  // //console.log(starting.$d)
  // //console.log(ending.$d)
  const [liveStatus, setLiveStatus] = useState("");
  const [youtubeStatus, setYoutubeStatus] = useState("");
  //Getting Batch
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);

      // setLoading(false)
    }
    fetchUsers();
  }, []);
  // //console.log(users);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(false);

  // //console.log(utubeurl)

  return (
    <Grid pl={5}>
      <Typography variant="h2" ml={2}>
        Add Lecture {"  "}({batchName})
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}>
        <Typography variant="h6" ml={2} mb={4}>
          Enter the below required details to create a lecture
        </Typography>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {/* <Stack direction="row" spacing={5} > */}
        <Stack spacing={3} sx={{ width: "70%" }}>
          {/* <Controller
        control={control}
        defaultValue=""
        render={({ field }) => ( */}
          <RHFTextField
            name="lecture_type"
            label="Lecture Type"

            select
          >
            <MenuItem value="YT" onClick={() => { setYoutubeStatus("YT"); setYoutubeVideo(true); }}>YouTube</MenuItem>
            <MenuItem value="APP" onClick={() => { setYoutubeStatus("APP"); setYoutubeVideo(true); }}>App</MenuItem>
          </RHFTextField>
          {/* //   )}
      // /> */}

          <RHFTextField name="lecture_title" label="Title " />

          {/* <RHFTextField
            name="description"
            label="Description "
            multiline
            rows={3}
          /> */}
          <RHFTextEditor name="description" label="Description" />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack
              direction="row"
              spacing={3}
              sx={{ width: "100%", justifyContent: "space-between" }}
            >
              <DateTimePicker
                label="Starting time"
                value={starting.$d}
                onChange={handleChange}
                // name="starting_date"
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
              <DateTimePicker
                label="Ending time"
                value={ending.$d}
                onChange={handleChangeTwo}
                sx={{ color: "black" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%", color: "black" }}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>

          <Stack direction="row" spacing={3}>
            <RHFTextField name="language" label="Language" select>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
              <MenuItem value="enhi">Bilingual</MenuItem>
            </RHFTextField>
            <RHFTextField name="subject_id" label="Subject" select>
              {users?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item._id}>
                    {item.title}
                  </MenuItem>
                );
              })}
            </RHFTextField>
            {(youtubeVideo && youtubeStatus == "APP") ? (
              <RHFTextField name="LiveOrRecorded" label="Type"  select>
                <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
                <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
              </RHFTextField>
            ) : (
              <RHFTextField name="LiveOrRecorded" label="Type" select >
                <MenuItem value="Recorded" onClick={() => { setLiveStatus("Recorded"); }}>Recorded</MenuItem>
                <MenuItem value="Live" onClick={() => { setLiveStatus("Live"); }}>Live</MenuItem>
              </RHFTextField>
            )}
          </Stack>
          <Stack
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <RHFTextField
              id="assi"
              name="materials"
              label="Resources"
              onChange={changeHandler}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              inputProps={{ accept: "application/pdf" }}
              helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              onClick={() => setResources(true)}
              type={resources || focus ? "file" : "text"}
            />
            {youtubeVideo ? (
              <>
                {" "}
                <RHFTextField
                  name="link"
                  label="Link"
                  type="url"
                  helperText={
                    !validateYoutubeUrl ? <span style={{ color: 'red' }}>Add Valid Youtube Link</span>: ''
                  }
                  value={utubeurl}
                  // error={!validateYoutubeUrl}
                  onChange={(e) => setUtubeurl(e.target.value)}
                />

              </>
            ) :
              (<>
              </>)
            }

          </Stack>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          onClick={check}
          sx={{
            bgcolor: "#EA506B",
            width: "70%",
            marginTop: "5%",
            "&:hover": { background: "red" },
          }}
        >
          Add
        </LoadingButton>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? 'green' : "red"} mt={3} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
    </Grid>
  );
};

export default LectureForm;
