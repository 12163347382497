import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, TextField, MenuItem, Select, InputLabel, Box, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';


import { useTheme } from '@mui/material/styles';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Page from '../../../components/Page';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TagsInput } from "react-tag-input-component";
import './style.css';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}



const UpdateBatchDetails = () => {
    const [users, usersSet] = useState([]);
    const [subject, setSubject] = useState([]);
    const [allteachers, teacherSet] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subjectList, setsubjectList] = useState([]);
    const [streamCategory, setStreamCategory] = useState([])
    const [type, setType] = useState(true)
    const [teacher, setteacher] = useState([]);
    const [selected, setSelected] = useState([]);



    const navigate = useNavigate()
    const { batchId } = useParams()
    const theme = useTheme();
    const LoginSchema = Yup.object().shape({

    });
    // //console.log(subjectList)
    const defaultValues = {
        batch_name: '',
        category: '',
        exam_type: '',
        subject: '',
        // teacherEmail: '',
        starting_date: '',
        ending_date: '',
        mode: '',
        materials: '',
        discount: '',
        charges: '',
        description: '',
        // banner: '',
        language: '',
        remark: '',
        validity: '',
        // course_review: '',
        remember: true,
        is_active: ''
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    const [file, setFile] = useState()
    const [demoVideo, setDemoVideo] = useState([]);


    const changeHandler = async (event) => {
        setFile(event.target.files[0])


    };

    const changeHandlerTwo = async (event) => {
        setDemoVideo(event.target.files)


    };

    //Getting Batch Details
    // let arr = [];
    const [batchDetails, setBatchDetails] = useState()

    useEffect(() => {

        let token = localStorage.getItem("token");


        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails?id=${batchId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            });
            const responseJson = await fullResponse.json();
            
            setBatchDetails(responseJson.data[0]);
            checkDetails(responseJson.data[0])


        }

        fetchUsers()

    }, [])
    // //console.log(batchDetails)
    // //console.log(batchDetails?.demoVideo)
    const [fileSelected, setFileSelected] = useState([])
    
    const[isActive, setIsActive] = useState(batchDetails?.is_active || false);
    //multipple Links Update
    // //console.log(subjectList)
    const [value, setValue] = useState()
    const checkDetails = (batchDetails) => {

        if (batchDetails?.demoVideo[0]?.DemoVideofileType == 'link') {
            setType(true)
        } else {
            setType(false)
        }
        let arr = []
        let arr2 = []
        batchDetails?.subject?.map((i, index) => arr.push(i?._id))
        batchDetails?.teacher?.map((i, index) => arr2.push(i?._id))
        setsubjectList(arr)
        setteacher(arr2)
        let arr3 = []
        let arr4 = []
        batchDetails?.demoVideo?.map((i, indx) => arr3.push(i?.fileLoc))
        batchDetails?.demoVideo?.map((i, indx) => arr4.push(i))
        setSelected(arr3)
        setFileSelected(arr4)
        setIsActive(batchDetails?.is_active);
        
        setValue(prev => ({
            ...prev,
            batch_name: batchDetails?.batch_name,
            discount: batchDetails?.discount,
            charges: batchDetails?.charges,
            description: batchDetails?.description,
            remark: batchDetails?.remark,
            validity: batchDetails?.validity,

        }))
        // batchDetails?.demoVideo?.map((i, indx) => helperArray.push(i))

    }

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            teacherSet(responseJson.data);
            setLoading(false)

        }

        fetchUsers();
    }, []);


    //Getting category
    React.useEffect(() => {
        const token = localStorage.getItem("token");

        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Batch`, {
                method: 'GET',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            setLoading(false)
            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resSubject = await res.json();
            setSubject(resSubject.data);
            setLoading(false)
            const fullResponseCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Stream`, {
                method: 'GET',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }

            });
            const responseJsonCatergory = await fullResponseCategory.json();
            setStreamCategory(responseJsonCatergory.data);

        }

        fetchUsers();
    }, []);


    const [focus, setFocused] = React.useState(false);
    const [focusTwo, setFocusedTwo] = React.useState(false);
    const [focusThree, setFocusedThree] = React.useState(false);
    const [focusFour, setFocusedFour] = React.useState(false);

    const [showStartingDate, setShowStartingDate] = React.useState(false);
    const [showEndingDate, setShowEndingDate] = React.useState(false);
    const [showBanner, setShowBanner] = React.useState(false);
    const [showDemoVideo, setShowDemoVideo] = React.useState(false);

    



    // let children = teacher.concat(damta);
    // //console.log(damta)




    // //console.log(subjects);
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setteacher(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };




    //delete
    const handleDelete = (id) => {
        setFileSelected([])

        fileSelected?.map((item, index) => {
            if (item.fileLoc !== id) {
                setFileSelected((prev) => [...prev, item])
            }
        })
    }



    const onSubmit = async (data) => {

        let arr = []
        batchDetails?.demoVideo?.map((item, index) => arr.push(item?.fileLoc))
        let arr2 = []
        batchDetails?.demoVideo?.map((item, index) => arr2.push(item?.fileLoc))
        let helperText = []
        let helperTextForSelected = []
        setLoading(true)
        const formData = new FormData();
        if (file) {
            formData.append('file', file);
        }
        if (batchDetails?.demoVideo[0]?.DemoVideofileType == 'link') {
            if (selected.length !== 0) {
                arr2.map((item) => {
                    if (selected.includes(item)) {
                        helperText.push(item)
                    }
                })
                //console.log(helperText)
                if (helperText.length == 1) {
                    formData.append('demoVideoResentLinks', '')
                }
                helperText.map((item, index) => {
                    formData.append('demoVideoResentLinks', item)

                })
                selected.map((itemOfSelected) => {
                    if (!arr.includes(itemOfSelected)) {
                        helperTextForSelected.push(itemOfSelected)
                        formData.append('demoVideoLink', itemOfSelected)
                    }

                })
                if (helperTextForSelected.length == 1) {
                    formData.append('demoVideoLink', '')
                }

            }
            else {
                batchDetails?.demoVideo?.map((item, index) => formData.append('demoVideoResentLinks', item?.fileLoc))
                formData.append('demoVideoResentLinks', '')
            }
        }
        if (batchDetails?.demoVideo[0]?.DemoVideofileType == 'Video') {
            if (fileSelected.length !== 0) {
                fileSelected.map((item, index) => formData.append('demoVideoResentLinks', item?.fileLoc))

                if (fileSelected.length == 1) {
                    formData.append('demoVideoResentLinks', '')
                }
            }
            if (demoVideo?.length) {
                Object.values(demoVideo).forEach(demoVideo => {
                    formData.append("demoVideo", demoVideo);
                })
                if (demoVideo.length == 1) {
                    formData.append("demoVideo", '');
                }
            }
        }


        if (data.bannerfileType) {
            formData.append('bannerfileType', data.bannerfileType);
        } else {
            formData.append('bannerfileType', batchDetails?.banner[0]?.bannerfileType);
        }
        if (data.demoVideoType) {
            formData.append('demoVideoType', data.demoVideoType);
        } else {
            formData.append('demoVideoType', batchDetails?.demoVideo[0]?.DemoVideofileType);
        }
        // if (data.batch_name) {
        //     formData.append('batch_name', data.batch_name);
        // } else {
        //     formData.append('batch_name', batchDetails?.batch_name);
        // }
        if (data.materials) { formData.append('materials', data.materials); }
        else {
            formData.append('materials', batchDetails?.materials)
        }
        if (teacher) {
            teacher.map((item) => formData.append('teacher', item))
            // batchDetails?.teacher.map((item) => formData.append('teacher', item._id))
        }
        else { batchDetails?.teacher.map((item) => formData.append('teacher', item._id)) }
        if (subjectList) {
            subjectList.map((item) => formData.append('subject', item))
            // batchDetails?.subject.map((item) => formData.append('subject', item?._id))
        } else { batchDetails?.subject.map((item) => formData.append('subject', item)) }
        // formData.append('teacher', teacher);

        // if (data.category) { formData.append('category', data.category); } else {
        //     formData.append('category', batchDetails?.category.title);
        // }
        if (data.stream) {
            formData.append('stream', data.stream);
        } else {
            formData.append('stream', batchDetails?.stream);
        }
        if (data.exam_type) {
            formData.append('exam_type', data.exam_type);
        } else {
            formData.append('exam_type', batchDetails?.exam_type);
        }
        formData.append('batch_name', value?.batch_name);
        formData.append('charges', value?.charges);
        formData.append('validity', value?.validity);
        formData.append('remark', value?.remark);
        formData.append('discount', value?.discount);
        formData.append('description', value?.description);
        if (data.starting_date) {
            formData.append('starting_date', data.starting_date);
        } else {
            formData.append('starting_date', batchDetails?.starting_date);
        }
        if (data.ending_date) {
            formData.append('ending_date', data.ending_date);
        } else {
            formData.append('ending_date', batchDetails?.ending_date);
        }
        if (data.mode) {
            formData.append('mode', data.mode);
        } else {
            formData.append('mode', batchDetails?.mode);
        }
        // if (data.discount) {
        //     formData.append('discount', data.discount);
        // } else {
        //     formData.append('discount', batchDetails?.discount);
        // }
        // if (data.charges) {
        //     formData.append('charges', data.charges);
        // } else {
        //     formData.append('charges', batchDetails?.charges);
        // }
        // if (data.description) {
        //     formData.append('description', data.description);
        // } else {
        //     formData.append('description', batchDetails?.description);
        // }
        if (data.language) {
            formData.append('language', data.language);
        } else {
            formData.append('language', batchDetails?.language);
        }
        // if (data.remark) {
        //     formData.append('remark', data.remark);
        // } else {
        //     formData.append('remark', batchDetails?.remark);
        // }
        // if (data.validity) {
        //     formData.append('validity', data.validity);
        // } else {
        //     formData.append('validity', batchDetails?.validity);
        // }
        if (data.course_review) {
            formData.append('course_review', data.course_review);
        } else {
            formData.append('course_review', batchDetails?.course_review);
        }
        // debugger;
        if( isActive === undefined){
            formData.append('is_active', batchDetails?.is_active)
        }else{
            formData.append('is_active',isActive);
        }
        
        // debugger;
        // console.log(data.isActive + " "+ batchDetails?.is_active );
        // if (data.is_active ) {
        //     formData.append('is_active', data.is_active);
        // } else {
        //     formData.append('is_active', batchDetails?.is_active);
        // }

        // for( let Pair of formData){
        //     console.log( Pair[0] + " "+ Pair[1]);
        // }
        let token = localStorage.getItem("token");

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        };
        axios.put(`/adminPanel/updatedBatchesDetails/${batchId}`, formData, config).then((response) => {
            //console.log(response.data);
            if (response.data.msg === 'Updated the Batches details') {
                reset()
                setLoading(false)
                toast.success("Batch Details Updated")
                setTimeout(() => {
                    navigate('/dashboard/batches')
                    // window.location.reload()
                }, 1000)
            }
        })
            .catch(e => {
                //console.log(e);
            })
    }


    const handleChangeSubject = (event) => {
        const {
            target: { value },
        } = event;
        setsubjectList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const onChange2 = (e) => {
        // console.log(e);
        const { name, value } = e.target;
        setValue(prev => ({ ...prev, [name]: value }))

    }

    const onChange3 = (content) => {
        console.log(content);
        // const { name, value } = e;
        setValue(prev => ({ ...prev, description: content}))

    }

    // //console.log(streamCategory)
    return (<>
        <Page title='Batch'>
            <Stack spacing={3} ml={5}>
                <Typography className='page-heading'>Update Batch </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}>
                        <RHFTextField name="batch_name" value={value?.batch_name} onChange={onChange2} />
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>

                            {/* <RHFTextField name="category" label={batchDetails?.category?.title ? batchDetails?.category?.title : 'Category'} select>
                                {users?.map((item, index) => {
                                    return <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                                })}
                            </RHFTextField> */}
                            <RHFTextField name="stream" label={batchDetails?.stream} select>
                                {streamCategory?.map((item, index) => {
                                    return <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                                })}
                            </RHFTextField>
                            <RHFTextField
                                name='exam_type'
                                label={batchDetails?.exam_type}
                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem value='Prelims'>Prelims</MenuItem>
                                <MenuItem value="Mains">Mains</MenuItem>
                                <MenuItem value='Interview'>Interview</MenuItem>
                            </RHFTextField>
                        </Stack>
                        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>

                            <RHFTextField
                                label={batchDetails?.subject}
                                name='subject'


                                sx={{ width: '100%' }}
                                select
                            >
                                {subject?.map((sub, i) => {
                                    return <MenuItem key={i} value={sub.title}>{sub.title}</MenuItem>
                                })}


                            </RHFTextField>


                        </Stack> */}

                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Subjects</InputLabel>
                            <Select
                                // label    ="Name"
                                // id="demo-multiple-name"
                                // labelId="test-select-label"
                                label=""

                                multiple
                                name='subject'
                                value={subjectList}
                                onChange={handleChangeSubject}
                                sx={{ width: '100%', color: 'black', zIndex: 9 }}
                                MenuProps={MenuProps}
                            >

                                {subject?.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name._id}
                                        style={getStyles(name, subject, theme)}
                                    >
                                        {name.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>

                        <Stack direction="row">
                            <InputLabel sx={{ width: '20%', marginRight: '20px', alignSelf: 'center' }}>Assign Teachers</InputLabel>
                            <Select
                                // label    ="Name"
                                // id="demo-multiple-name"
                                // labelId="test-select-label"
                                label=""
                                multiple
                                name='teacher'

                                value={teacher}
                                onChange={handleChange}

                                sx={{ width: '100%', color: 'black', zIndex: 9 }}
                                MenuProps={MenuProps}
                            >

                                {allteachers?.map((name, index) => {


                                    return <MenuItem
                                        key={name}
                                        value={name._id}
                                        style={getStyles(name, teacher, theme)}
                                    >
                                        {name.FullName}
                                    </MenuItem>
                                })}
                            </Select>
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField name="starting_date"
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label={batchDetails?.starting_date}
                                onClick={() => setShowStartingDate(true)}
                                type={showStartingDate || focus ? "date" : "text"} />
                            <RHFTextField name="ending_date"
                                onFocus={() => setFocusedTwo(true)}
                                onBlur={() => setFocusedTwo(false)}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label={batchDetails?.ending_date}
                                onClick={() => setShowEndingDate(true)}
                                type={showEndingDate || focusTwo ? "date" : "text"}
                            />
                            <RHFTextField
                                name='mode'
                                label="Mode"
                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem value='online'>Online</MenuItem>
                                <MenuItem value="offline">Offline</MenuItem>
                            </RHFTextField>
                            <RHFTextField
                                name='materials'
                                label="Materials"
                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem value='live'>Live</MenuItem>
                                <MenuItem value="recorded">Recorded</MenuItem>
                                <MenuItem value='both'>Both</MenuItem>
                            </RHFTextField>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField name="charges" value={value?.charges} onChange={onChange2} type="number" />
                            <RHFTextField name="discount" value={value?.discount} onChange={onChange2} type="number" />
                            <RHFTextField name="bannerfileType" label={batchDetails?.banner[0]?.bannerfileType} select>
                                <MenuItem value='image'>Image</MenuItem>
                                <MenuItem value="video">Video</MenuItem>
                            </RHFTextField>
                            <RHFTextField
                                name="banner"
                                onChange={changeHandler}
                                onFocus={() => setFocusedThree(true)}
                                onBlur={() => setFocusedThree(false)}

                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label="Banner"
                                onClick={() => setShowBanner(true)}
                                type={showBanner || focusThree ? "file" : "text"}
                            />
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField
                                name='demoVideoType'
                                label={batchDetails?.demoVideo[0]?.DemoVideofileType}

                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem onClick={() => setType(true)} value='link'>Youtube Link</MenuItem>
                                <MenuItem onClick={() => setType(false)} value="Video">Video File</MenuItem>

                            </RHFTextField>
                            {type && <TagsInput
                                value={selected}
                                onChange={setSelected}
                                name="link"
                                placeHolder="Enter Youtube Links"
                                className='rti--input'
                            />}
                            {/* <a href={batchDetails?.demoVideo[0]?.fileLoc}>File 1</a> */}
                            {!type && <><RHFTextField
                                name="demoVideo"
                                onChange={changeHandlerTwo}

                                onFocus={() => setFocusedFour(true)}
                                onBlur={() => setFocusedFour(false)}
                                inputProps={{ multiple: true }}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}
                                label="Demo Videos"
                                onClick={() => setShowDemoVideo(true)}
                                type={showDemoVideo || focusFour ? type ? "url" : "file" : "text"}
                            />
                                {fileSelected?.map((item, index) => <Stack key={index} direction='column' sx={{ width: '100%', position: 'relative' }} alignItems="center" spacing={1} >

                                    <IconButton sx={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => handleDelete(item?.fileLoc)}>
                                        <CloseIcon fontSize='small' />
                                    </IconButton>

                                    <a href={item?.fileLoc} style={{ textDecoration: 'none' }}><FileCopyIcon fontSize='small' /></a>
                                    <Typography sx={{ fontSize: '12px' }}>{item?.fileName}</Typography>
                                </Stack>)}
                            </>}
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <RHFTextField
                                name='language'
                                label={batchDetails?.language == 'en' ? 'English' : batchDetails?.language == 'enhi' ? 'Hindi/English' : 'Hindi'}
                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem value='en'>English</MenuItem>
                                <MenuItem value="hi">Hindi</MenuItem>
                                <MenuItem value='enhi'>Bilingual</MenuItem>
                            </RHFTextField>

                            <RHFTextField name="validity" value={value?.validity} onChange={onChange2} type="number" />
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField name="remark" value={value?.remark} onChange={onChange2} />
                            <RHFTextField
                                name='isActive'
                                label={batchDetails?.is_active ? 'Active' : 'Inactive'}
                                sx={{ width: '100%' }}
                                select
                                type="checkbox"
                            >
                                <MenuItem value={true} onClick={()=> setIsActive(true)}>Active</MenuItem>
                                <MenuItem value={false} onClick={()=>setIsActive(false)}>Inactive</MenuItem>

                            </RHFTextField>
                        </Stack>


                        {/* <RHFTextField name="description" value={value?.description} onChange={onChange2} multiline
                            rows={4} /> */}
                        <RHFTextEditor name="description" value={value?.description} label={'Description'} onChange={onChange3} />
                         


                    </Stack>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: '#EA506B', width: '80%', marginTop: '5%', '&:hover': { background: 'red' } }}
                    >
                        Update
                    </LoadingButton>
                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}

            </Stack>
        </Page >
    </>
    )
}

export default UpdateBatchDetails