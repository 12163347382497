import React, { useEffect } from "react";
import * as Yup from "yup";
import { useState } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  Typography,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormProvider, RHFTextField } from "../../../components/hook-form";

import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import Page from "../../../components/Page";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TagsInput } from "react-tag-input-component";
import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AddBatch = () => {
  // const [users, usersSet] = useState([]);
  const [subject, setSubject] = useState([]);
  const [allteachers, teacherSet] = useState([]);
  const [teacher, setteacher] = useState([]);
  const [subjectList, setsubjectList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [streamCategory, setStreamCategory] = useState([]);
  const navigate = useNavigate();
  const [type, setType] = useState(true);
  const [selected, setSelected] = useState([]);
  const [uploadSize, setUploadSize] = useState({
    completed: 0,
    totalSize: 0,
  });

  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [submitted, setSubmitted] = React.useState(false);

  const theme = useTheme();
  const LoginSchema = Yup.object().shape({
    // file: Yup.mixed().required('File is required'),
    batch_name: Yup.string().required("Name is required"),
    // category: Yup.string().required('category is required'),
    exam_type: Yup.string().required("Exam type is required"),
    // subject: Yup.string().required('Subject is required'),
    // teacherEmail: Yup.string().required('teacherEmail is required'),
    starting_date: Yup.string().required("Starting Date is required"),
    ending_date: Yup.string().required("Ending Date is required"),
    mode: Yup.string().required("mode is required"),
    materials: Yup.string().required("materials is required"),
    // discount: Yup.string().required('discount is required'),
    charges: Yup.string().required("charges is required"),
    description: Yup.string().required("descriptoin is required"),
    // file: Yup.string().required('banner is required'),
    language: Yup.string().required("language is required"),
    // remark: Yup.string().required('remark is required'),
    stream: Yup.string().required("Stream is required"),
    bannerfileType: Yup.string().required("Banner File Type is required"),
    demoVideoType: Yup.string().required("Demo video Type is required"),
    // link: Yup.string().required('Links required'),

    validity: Yup.string().required("validity is required"),
    // // course_review: Yup.string().required('course_review is required'),
    is_active: Yup.boolean().required("Status Required is required"),
  });


  const defaultValues = {
    batch_name: "",
    // category: '',
    exam_type: "",
    stream: "",
    // subject: '',
    // teacherEmail: '',
    starting_date: "",
    ending_date: "",
    mode: "",
    materials: "",
    discount: "0",
    charges: "",
    description: "",
    // file: null,
    language: "",
    remark: "",
    validity: "",
    // course_review: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();
  const [demoVideo, setDemoVideo] = useState();
  const [msg, setMsg] = useState("");

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };
  const changeHandlerTwo = async (event) => {
    if (!type) {
      setDemoVideo(event.target.files);
    } else {
      setDemoVideo(event.target.value);
    }
    // setDemoVideo(event.target.files)
  };


  //Add Details'

  const onSubmit = async (data) => {

    let cond = false;

    if (data.demoVideoType == "link") {
      if (selected.length !== 0) {
        cond = true;
      }
    } else {
      if (demoVideo?.length) {
        cond = true;
      }
    }
    if (
      file?.size &&
      teacher.length !== 0 &&
      subjectList.length !== 0 &&
      cond
    ) {
      setShowProgress(true);
      setLoading(true);

      const formData = new FormData();
      formData.append("file", file);
      if (type) {
        formData.append("demoVideoType", data.demoVideoType);
        selected.map((item, index) => formData.append("demoVideoLink", item));
        
      } else if (!type) {
        formData.append("demoVideoType", data.demoVideoType);

        Object.values(demoVideo).forEach((demoVideo) => {
          formData.append("demoVideo", demoVideo);
        });
      }

      formData.append("materials", data.materials);
      formData.append("bannerfileType", data.bannerfileType);
      // teacher.map()
      teacher.map((item) => formData.append("teacher", item));
      subjectList.map((item) => formData.append("subject", item));
      // formData.append('teacher', teacher);
      formData.append("batch_name", data.batch_name);
       formData.append('stream', data.stream);
      // formData.append('category', 'Batch');
      formData.append("exam_type", data.exam_type);
     

      // formData.append('subject', data.subject);
      formData.append("starting_date", data.starting_date);
      formData.append("ending_date", data.ending_date);
      formData.append("mode", data.mode);
      formData.append("discount", data.discount);
      formData.append("charges", data.charges);
      formData.append("description", data.description);
      formData.append("language", data.language);
      formData.append("remark", data.remark);
      formData.append("validity", data.validity);
      formData.append("course_review", "Review");
      formData.append("is_active", data.is_active);

      let token = localStorage.getItem("token");

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
         
          setUploadSize((prev) => ({
            completed: Math.floor(loaded / 1000000),
            totalSize: Math.floor(total / 1000000),
          }));
          // setInterval(() => {
          //     if (percent < 90) {
          //         setProgress(prev => prev + 10)
          //     }
          // }, 1200)
          if (percent < 90) {
            setProgress(percent);
          }
        },
        maxBodyLength: Infinity,
        maxContentLength: Infinity,
      };
  
      axios
        .post(`/adminPanel/addBatchesDetails`, formData, config)
        .then((response) => {
          //console.log(response.data);
          toast.success("Batch Details Added");
          setShowBanner(false);
          setShowEndingDate(false);
          setShowStartingDate(false);
          if (response.data.msg === "added Batches details successfully") {
            setProgress(100);
            setSubmitted(true);
            setTimeout(() => {
                setLoading(false)
                navigate('/dashboard/batches')

             }, 1000)
            reset();
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      teacherSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  //Getting category
  useEffect(() => {
    const token = localStorage.getItem("token");
    const batch = "Batch";
    async function fetchUsers() {
      // const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Batch`, {
      //     method: 'GET',

      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`
      //     }

      // });
      // const responseJson = await fullResponse.json();
      // usersSet(responseJson.data);
      setLoading(false);
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resSubject = await res.json();
      setSubject(resSubject.data);
      setLoading(false);
      const fullResponseCategory = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Stream`,
        {
          method: "GET",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJsonCatergory = await fullResponseCategory.json();
      setStreamCategory(responseJsonCatergory.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  // //console.log(streamCategory)

  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [focusFour, setFocusedFour] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [showBanner, setShowBanner] = React.useState(false);
  const [showDemoVideo, setShowDemoVideo] = React.useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeSubject = (event) => {
    const {
      target: { value },
    } = event;
    setsubjectList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [val, setVal] = useState(false);
  const [bannerType, setBannerType] = useState(false);

  return (
    <>
      <Page title="Batch">
        <Stack spacing={3} ml={5}>
          <Typography className="page-heading">Add Batch </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <RHFTextField name="batch_name" label="Batch Name" />

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                {/* <RHFTextField name="category" label="Category" select>
                                {users?.map((item, index) => {
                                    return <MenuItem key={index} value={item.title}>{item.title}</MenuItem>
                                })}
                            </RHFTextField> */}

                <RHFTextField
                  name="exam_type"
                  label="Exam Type"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="Prelims">Prelims</MenuItem>
                  <MenuItem value="Mains">Mains</MenuItem>
                  <MenuItem value="Interview">Interview</MenuItem>
                </RHFTextField>
              </Stack>

              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Streams
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  {/* <Select
                    label=""
                    error={streamList.length === 0 && val}
                    multiple
                    name="stream"
                    value={streamList}
                    onChange={(event) => setStreamList(event.target.value)}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {streamCategory?.map((name, index) => (
                      <MenuItem
                        key={index}
                        value={name.title}
                        style={getStyles(name, streamList, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                  */}
                     <RHFTextField name="stream" label="Stream" select >
                                {streamCategory?.map((item, index) => {
                                    return <MenuItem key={index} value={item.title} >{item.title}</MenuItem>
                                })}
                            </RHFTextField>

                 
                </Stack>
              </Stack>
              {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>

                            <RHFTextField
                                label="Subjects"
                                name='subject'


                                sx={{ width: '100%' }}
                                select
                            >
                                {subject?.map((sub, i) => {
                                    return <MenuItem key={i} value={sub.title}>{sub.title}</MenuItem>
                                })}


                            </RHFTextField>


                        </Stack> */}

              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Subjects
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    // label    ="Name"
                    // id="demo-multiple-name"
                    // labelId="test-select-label"
                    label=""
                    error={subjectList.length == 0 && val}
                    multiple
                    name="subject"
                    value={subjectList}
                    onChange={handleChangeSubject}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {subject?.map((name, index) => (
                      <MenuItem
                        key={index}
                        value={name._id}
                        style={getStyles(name, subject, theme)}
                      >
                        {name.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {subjectList.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Teachers
                </InputLabel>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <Select
                    // label    ="Name"
                    // id="demo-multiple-name"
                    // labelId="test-select-label"
                    label=""
                    multiple
                    error={teacher.length == 0 && val}
                    name="teacher"
                    value={teacher}
                    onChange={handleChange}
                    sx={{ width: "100%", color: "black", zIndex: 9 }}
                    MenuProps={MenuProps}
                  >
                    {allteachers?.map((name, index) => (
                      <MenuItem
                        key={index}
                        value={name._id}
                        style={getStyles(name, teacher, theme)}
                      >
                        {name.FullName}
                      </MenuItem>
                    ))}
                  </Select>
                  {teacher.length == 0 && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="starting_date"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Starting Date"
                  onClick={() => setShowStartingDate(true)}
                  type={showStartingDate || focus ? "date" : "text"}
                />
                <RHFTextField
                  name="ending_date"
                  onFocus={() => setFocusedTwo(true)}
                  onBlur={() => setFocusedTwo(false)}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  label="Ending Date"
                  onClick={() => setShowEndingDate(true)}
                  type={showEndingDate || focusTwo ? "date" : "text"}
                />
                <RHFTextField
                  name="mode"
                  label="Mode"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </RHFTextField>
                <RHFTextField
                  name="materials"
                  label="Materials"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="live">Live</MenuItem>
                  <MenuItem value="recorded">Recorded</MenuItem>
                  <MenuItem value="both">Both</MenuItem>
                </RHFTextField>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="charges"
                  label="Regular Price"
                  type="number"
                  id="custom-css-outlined-input"
                />
                <RHFTextField
                  name="discount"
                  label="Discount Price"
                  type="number"
                />
                <RHFTextField name="bannerfileType" label="Banner type" select>
                  <MenuItem value="image" onClick={() => setBannerType(false)}>
                    Image
                  </MenuItem>
                  <MenuItem value="video" onClick={() => setBannerType(true)}>
                    Video
                  </MenuItem>
                </RHFTextField>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <RHFTextField
                    name="file"
                    error={!file && val}
                    onChange={changeHandler}
                    onFocus={() => setFocusedThree(true)}
                    onBlur={() => setFocusedThree(false)}
                    inputProps={{ accept: bannerType ? "video/*" : "image/*" }}
                    // helperText={bannerType ? "Video Files" : "Image"}
                    InputProps={{
                      classes: {
                        input: "CustomTextField",
                      },
                    }}
                    label="Banner"
                    onClick={() => setShowBanner(true)}
                    type={showBanner || focusThree ? "file" : "text"}
                  />
                  {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                  {!file && (
                    <Typography
                      sx={{
                        fontSize: "12px",
                        marginTop: "5px",
                        marginLeft: "10px",
                        color: "red",
                      }}
                    >
                      {msg}
                    </Typography>
                  )}
                </Stack>
                {/* <Stack direction='row' alignItems="center" sx={{
                                border: '1px solid lightgray', padding: '6px', borderRadius: '10px', color: 'gray', '&:hover': {
                                    border: '1px solid black'
                                }
                            }}>Banner  <IconButton color="primary" aria-label="upload picture" component="label">
                                    <input hidden accept="image/*" type="file" name="" />
                                    <PhotoCamera />
                                </IconButton></Stack> */}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="demoVideoType"
                  label="Demo Video Type"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem onClick={() => setType(true)} value="link">
                    Youtube Link
                  </MenuItem>
                  <MenuItem onClick={() => setType(false)} value="Video">
                    Video File
                  </MenuItem>
                </RHFTextField>
                {type && (
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <TagsInput
                      value={selected}
                      onChange={setSelected}
                      name="link"
                      placeHolder="Enter Youtube Links (Embeded Links)"
                      className="rti--input"
                    />
                    {selected.length == 0 && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "5px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                      >
                        {msg}
                      </Typography>
                    )}
                  </Stack>
                )}
                {!type && (
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <RHFTextField
                      name="demoVideo"
                      // helperText={type ? "Youtube Link" : "Video File"}
                      error={!demoVideo && val}
                      onChange={changeHandlerTwo}
                      onFocus={() => setFocusedFour(true)}
                      onBlur={() => setFocusedFour(false)}
                      inputProps={{ multiple: true }}
                      InputProps={{
                        classes: {
                          input: "CustomTextField",
                        },
                      }}
                      label={type ? "Youtube Link" : "Video File"}
                      onClick={() => setShowDemoVideo(true)}
                      type={showDemoVideo || focusFour ? "file" : "text"}
                    />
                    {!demoVideo && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          marginTop: "5px",
                          marginLeft: "10px",
                          color: "red",
                        }}
                      >
                        {msg}
                      </Typography>
                    )}
                  </Stack>
                )}
                <RHFTextField
                  name="language"
                  label="Language"
                  sx={{ width: "100%" }}
                  select
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="hi">Hindi</MenuItem>
                  <MenuItem value="enhi">Bilungual</MenuItem>
                </RHFTextField>

                <RHFTextField
                  name="validity"
                  label="Validity(In months)"
                  type="number"
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField name="remark" label="Remark" />
                <RHFTextField
                  name="is_active"
                  label="Status"
                  sx={{ width: "100%" }}
                  select
                  type="checkbox"
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>

              {/* <RHFTextField name="course_review" label="Course Review" /> */}
              {/* <RHFTextField name="description" label="Description" multiline
                            rows={4} /> */}
              <RHFTextEditor name="description" label="Description" />
            </Stack>

            {/* <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: '#EA506B', width: '80%', marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setMsg('Required')
                            setVal(true)
                            // setMsgDemoVideo('Demo Video Required')
                        }}
                    >
                        Add
                    </LoadingButton> */}
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#EA506B",
                width: "80%",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setMsg("Required");
                setVal(true);
                // setMsgDemoVideo('Demo Video Required')
              }}
              // onClick={navigate('/dashboard/recorded')}
            >
              {/* {showProgress ? <LinearProgressWithLabel value={progress} submitted={submitted} /> : 'Add'} */}
              {showProgress ? (
                <Typography mr={2} sx={{ textAlign: "center" }}>
                  {submitted
                    ? "Submitted"
                    : progress < 91
                    ? `Uploading File..... ${progress}%`
                    : "Submitting Form"}
                </Typography>
              ) : (
                "Add"
              )}
              {showProgress ? (
                submitted ? (
                  ""
                ) : (
                  <CircularProgress color="secondary" />
                )
              ) : (
                ""
              )}
            </Button>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography color={positive ? 'green' : "red"} sx={{ textAlign: 'center', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
      </Page>
    </>
  );
};

export default AddBatch;
