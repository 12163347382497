import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider, Grid, Select, MenuItem, Button, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const LibraryQuestions = () => {

  const questions = [
    {
      id: 1,
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      options: ["1", "2", "3", "4"],
      correctOption: "4",
      explanation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      id: 2,
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      options: ["Option A", "Option B", "Option C", "Option D"],
      correctOption: "Option A",
      explanation: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    {
      id: 3,
      question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      options: ["Option A", "Option B", "Option C", "Option D"],
      correctOption: "Option A",
      explanation: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    }
  ];

  return (
    <Box sx={{ padding: '20px', display: 'flex', gap: 3 , backgroundColor: "white"}}>
      <Box sx={{ flex: 2 }}>
        <Typography variant="h4" gutterBottom>Library Questions</Typography>
        <Divider />

        <Typography variant="body1" sx={{ fontWeight: 'bold' }} mt={2} mb={2} gutterBottom>
          Questions
        </Typography>
        <Divider sx={{ mb: 2 }} />

        {questions.map((question, index) => (
          <React.Fragment key={question.id}>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <Box
                    sx={{
                      borderRadius: '50%',
                      border: '2px solid #ccc',
                      padding: '5px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ExpandMoreIcon />
                  </Box>
                }
                aria-controls={`panel${question.id}-content`}
                id={`panel${question.id}-header`}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                      Q.{index + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body1">
                      {question.question}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Box pl={3}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Options:</Typography>
                  <Grid container spacing={2}>
                    {question.options.map((option, idx) => (
                      <Grid item xs={6} sm={3} key={idx}>
                        <Typography variant="body2">
                          {String.fromCharCode(65 + idx)}) {option}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} mt={2}>
  Correct Option:  
</Typography> 
<Typography variant="body2">
  <strong>{String.fromCharCode(65 + question.options.indexOf(question.correctOption))})</strong> {question.correctOption}
</Typography>

                   
                
                  <Typography variant="body2" mt={2} sx={{ fontWeight: 'bold' }}>
                    Explanation:
                  </Typography>
                  <Typography variant="body2" mt={1}>
                    {question.explanation}
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider style={{ margin: '20px 0' }} />
          </React.Fragment>
        ))}
      </Box>

      <Box
        sx={{
          width: '30%',
          mt: 4,
          p: 3,
          borderRadius: 3,
          bgcolor: "#FBD6DD",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#333">
              Exam Category
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              defaultValue=""
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Exam Category</MenuItem>
              <MenuItem value="Central Exam">Central Exam</MenuItem>
              <MenuItem value="State Exam">State Exam</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#333">
              Exam Type
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              defaultValue=""
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Exam Type</MenuItem>
              <MenuItem value="IIT JEE">IIT JEE</MenuItem>
              <MenuItem value="NEET">NEET</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#333">
              Subject
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              defaultValue=""
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Subject</MenuItem>
              <MenuItem value="Physics">Physics</MenuItem>
              <MenuItem value="Chemistry">Chemistry</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom color="#333">
              Chapter
            </Typography>
            <Select
              fullWidth
              variant="outlined"
              defaultValue=""
              sx={{
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                },
              }}
              displayEmpty
            >
              <MenuItem value="" disabled>Select Chapter</MenuItem>
              <MenuItem value="Chapter 1: Units and Vectors">Chapter 1: Units and Vectors</MenuItem>
              <MenuItem value="Chapter 2: Kinematics">Chapter 2: Kinematics</MenuItem>
            </Select>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 3,
                borderRadius: 2,
                backgroundColor: '#F67382',
                color: '#fff',
                '&:hover': { backgroundColor: '#f06292' },
              }}
            >
              Add Question
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LibraryQuestions;
