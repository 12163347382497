import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { User } from '../../../context/context'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();


  const [fetchMessage, setFetchMessage] = useState()
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });


  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {

    try {
      const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminTeacher/adminLogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      const result = await res.json()
      //console.log(result)
      setFetchMessage(result.msg == 'Successfully loggedIn' ? 'Log In Successfully' : result.msg)
      if (result.msg === 'Successfully loggedIn') {
        if (result.role === 'admin') {
          localStorage.setItem("role", 'Admin');
          localStorage.setItem("idOfAdmin", result.adminId);
          localStorage.setItem("detailObject", JSON.stringify(result));
          localStorage.setItem("details", result.username);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = '/dashboard/app';
          localStorage.setItem("token", result.data);
          localStorage.setItem("isLoggedIn", true);
        } else if (result.role === 'subadmin') {
          localStorage.setItem("role", result.role);
          localStorage.setItem("idOfAdmin", result.adminId);
          localStorage.setItem('id', result.adminId)
          localStorage.setItem("detailObject", JSON.stringify(result));
          localStorage.setItem("details", result.username);
          // navigate('/dashboard/app', { replace: true });
          window.location.href = '/dashboard/app';
          localStorage.setItem("token", result.data);
          localStorage.setItem("isLoggedIn", true);
        }

      }



      // window.location = "/";

    } catch (error) {
      //console.log(error);
    }

  };
  const [label2, setLabel] = useState(true)
  // const onChange3 = (e) => {
  //   const { value } = e.target
  //   if (value == '') {
  //     setLabel(false)
  //   }
  //   //console.log(value)

  // }



  return (<>
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <RHFTextField name="email" label="Email address" InputLabelProps={{ shrink: label2 }} />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}
        sx={{ bgcolor: '#EA506B' }}
      >
        Login
      </LoadingButton>
    </FormProvider>
    <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography>
  </>
  );
}
