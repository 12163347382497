import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  TextField,
} from "@mui/material";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import { UserMoreMenu } from "../user";

import "./quiz.css";

const Quiz = () => {
  const access = JSON.parse(localStorage.getItem("detailObject"));
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  // Test Series Details
  const [quizDetails, setQuizDetails] = React.useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizes`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setQuizDetails(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching quiz details", error);
        setLoading(false);
      }
    }
    fetchUsers();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = quizDetails.filter((item) =>
    item.quiz_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const slicedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            sx={{ width: "90%" }}
          >
            <Typography className="page-heading">All Quizzes</Typography>

            {/* Search Input */}
            <TextField
              label="Search  Quiz"
              variant="outlined"
              sx={{ width: "40%" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: "300px",
                marginBottom: "10px",
                "& label": {
                  color: "black",
                },
                "& fieldset": {
                  borderColor: "black",
                },
              }}
            />

            {(access?.access?.includes("Add Quiz") ||
              access?.access.includes("all")) && (
              <Button
                variant="contained"
                sx={{
                  width: "20%",
                  alignSelf: "center",
                  background: "#EA506B",
                  "&:hover": { background: "red" },
                }}
                onClick={() => navigate(`/dashboard/addQuiz`)}
              >
                Add Quiz
              </Button>
            )}
          </Stack>
          <Divider />

          <Grid
            className="quiz-grid-container"
            container
            spacing={3}
            mt={2}
            sx={{ overflowY: "scroll", width: "100%" }}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Quiz Title</TableCell>
                    <TableCell align="center">No of Questions</TableCell>
                    <TableCell align="center">Duration</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Question Marks</TableCell>
                    <TableCell align="center">Negative Marking</TableCell>
                    <TableCell align="center">View</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedRows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{row?.quiz_title}</TableCell>
                      <TableCell align="center">{row?.no_ques}</TableCell>
                      <TableCell align="center">{row?.quiz_duration}</TableCell>
                      <TableCell align="center">
                        <span
                          style={{ color: row.is_active ? "green" : "red" }}
                        >
                          {row.is_active ? "Active" : "InActive"}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row?.eachQueMarks}</TableCell>
                      <TableCell align="center">
                        {row?.negativeMarks ? row?.negativeMarks : 0}
                      </TableCell>{" "}
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          sx={{
                            width: "100px",
                            alignSelf: "center",
                            background: "#EA506B",
                            borderRadius: "20px",
                            "&:hover": { background: "red" },
                          }}
                          onClick={() =>
                            navigate(
                              `/dashboard/quiz/${row?.quiz_title}/${row?._id}`
                            )
                          }
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <UserMoreMenu
                          title="Quiz"
                          id={row._id}
                          req="deleteQuiz"
                          quizName={row.quiz_title}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
};

export default Quiz;
