import React from 'react'
import * as Yup from 'yup';
import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, useResolvedPath } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, IconButton, InputAdornment, Typography, TextField, MenuItem, Select, InputLabel, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { User } from '../../../context/context'
import Page from '../../../components/Page';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './test.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,

        },
    },
};


function getStyles(name, teacher, theme) {
    return {
        fontWeight:
            teacher.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const AddTest = () => {
    const navigate = useNavigate();
    // const { setRole } = useContext(User)
    const { testSeriesId } = useParams()
    const [category, setCategory] = React.useState('');
    const [type, setType] = React.useState('');
    const [users, usersSet] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [streamCategory, setStreamCategory] = useState([])
    const theme = useTheme();


    const LoginSchema = Yup.object().shape({


        Test_title: Yup.string().required('Test Title is required'),
        Test_code: Yup.string().required('Test Code is required'),
        starting_date: Yup.string().required('Starting Date is required'),
        instructions: Yup.string().required('Instructions are required'),
        No_of_question: Yup.string().required('No of questions are required'),
        question_paper_type: Yup.string().required('Question Paper Type is required'),
        duration: Yup.string().required('Duration are required'),
        negativemarking: Yup.boolean().required('Required'),
        negativeMarks: Yup.number().when("negativemarking", (negativemarking, schema) => {
            return negativemarking
              ? schema.test(
                  "positive-and-greater-than-zero",
                  "Negative Marks must be a positive number greater than zero",
                  (value) => value > 0
                )
              : schema;
          }),
        totalMarks: Yup.string().required('Total Marks'),

    });

    const defaultValues = {

        Test_title: '',
        Test_code: '',
        starting_date: '',
        instructions: '',
        No_of_question: '',
        question_paper_type: '',
        duration: '',
        negativemarking: '',
        totalMarks: '',
        negativeMarks: 0,

    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });


    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;




    React.useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {
            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
        }

        fetchUsers();
    }, []);



    const [val, setVal] = useState(false)





    //Date
    const [focus, setFocused] = React.useState(false);
    const [showStartingDate, setShowStartingDate] = React.useState(false);

    //Stream
    useEffect(() => {
        const token = localStorage.getItem("token");
        const batch = 'Batch'
        async function fetchUsers() {

            const fullResponseCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Stream`, {
                method: 'GET',

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }

            });
            const responseJsonCatergory = await fullResponseCategory.json();
            setStreamCategory(responseJsonCatergory.data);
            setLoading(false)

        }

        fetchUsers();
    }, []);


    //Banner
    const [file, setFile] = useState()
    const [msg, setMsg] = useState('')
    const [focusThree, setFocusedThree] = React.useState(false);
    const [showBanner, setShowBanner] = React.useState(false);
    const changeHandler = async (event) => {
        setFile(event.target.files[0])
    };


    //Answer Template
    const [focusTwo, setFocusedTwo] = React.useState(false);
    const [showBannerTwo, setShowBannerTwo] = React.useState(false);
    const [answerTemplate, setAnswerTemplate] = useState()
    const changeHandlerTwo = async (event) => {
        setAnswerTemplate(event.target.files[0])
    };

    const [progress, setProgress] = useState();
    const [showNegative, setShowNegative] = useState(false);

    //Adding Details
    const onSubmit = async (data) => {


        if (file?.size && answerTemplate?.size) {

            setLoading(true)

            const formData = new FormData();
            formData.append('questionPaper', file);
            formData.append('answerTemplate', answerTemplate);
            formData.append('TestSeriesId', testSeriesId);
            formData.append('Test_title', data.Test_title);
            formData.append('Test_code', data.Test_code);
            formData.append('instructions', data.instructions);
            formData.append('No_of_question', data.No_of_question);
            formData.append('question_paper_type', data.question_paper_type);
            formData.append('duration', data.duration);
            formData.append('starting_date', data.starting_date);
            formData.append('negativemarking', data.negativemarking);
            formData.append('totalmarks', data.totalMarks);
            if( showNegative){
                formData.append('negativeMarks' , data.negativeMarks);
            }
            
            let token = localStorage.getItem("token");

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addTestForTestSeries`, formData, config).then((response) => {
                //console.log(response.data);
                toast.success("Test Added")
                setShowBanner(false)

                setShowStartingDate(false)
                if (response.data.msg === 'Added the Test for TestSeries') {
                    setLoading(false)
                    setTimeout(() => {

                        navigate(`/dashboard/test/testSeriesDetails/${testSeriesId}`)

                    }, 1000)
                    reset()
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        }



    }

    return (<>
        <Page title='Test Series'>
            <Stack spacing={3} ml={5}>
                <Typography variant='h3'>Add Test </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                    <Stack spacing={3} sx={{ width: '80%' }}>

                        <RHFTextField name="Test_title" label="Test Name" />
                        <RHFTextField name="Test_code" label="Test Code" />
                        <Stack direction='row' spacing={3}>   <RHFTextField name="starting_date"
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}
                            label="Starting Date"
                            onClick={() => setShowStartingDate(true)}
                            type={showStartingDate || focus ? "date" : "text"} />
                            <RHFTextField
                                name='question_paper_type'
                                label="Type Of Question Paper"

                                sx={{ width: '100%' }}
                                select
                            >
                                <MenuItem onClick={() => setType(true)} value='objective'>Objective</MenuItem>
                                <MenuItem onClick={() => setType(false)} value="subjective">Subjective</MenuItem>

                            </RHFTextField>
                            <Stack direction="column" sx={{ width: '100%' }}>

                                <RHFTextField
                                    name="questionPaper"
                                    error={!file && val}
                                    onChange={changeHandler}
                                    onFocus={() => setFocusedThree(true)}
                                    onBlur={() => setFocusedThree(false)}
                                    inputProps={{ accept: "application/pdf" }}
                                    helperText="Pdf Files Only"
                                    InputProps={{
                                        classes: {
                                            input: "CustomTextField"
                                        }
                                    }}
                                    label="Question Paper"
                                    onClick={() => setShowBanner(true)}
                                    type={showBanner || focusThree ? "file" : "text"}
                                />
                                {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                                {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                            </Stack>
                            <Stack direction="column" sx={{ width: '100%' }}>
                                <RHFTextField
                                    name="answerTemplate"
                                    error={!answerTemplate && val}
                                    onChange={changeHandlerTwo}
                                    onFocus={() => setFocusedTwo(true)}
                                    onBlur={() => setFocusedTwo(false)}
                                    inputProps={{ accept: "application/pdf" }}
                                    helperText="Pdf Files Only"
                                    InputProps={{
                                        classes: {
                                            input: "CustomTextField"
                                        }
                                    }}
                                    label="Answer Template"
                                    onClick={() => setShowBannerTwo(true)}
                                    type={showBannerTwo || focusTwo ? "file" : "text"}
                                />
                                {/* {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>Banner Requied</Typography>} */}
                                {!answerTemplate && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}
                            </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField name="totalMarks" label="Total Marks" type='number' />
                            <RHFTextField
                                name='negativemarking'
                                label="Negative Marking"
                                sx={{ width: '100%' }}
                                select
                                
                            >
                                <MenuItem value={true} onClick={() => setShowNegative(true)}>Yes</MenuItem>
                                <MenuItem value={false} onClick={() => setShowNegative(false)}>No</MenuItem>

                            </RHFTextField>
                            {showNegative && (
                                        <RHFTextField
                                          name="negativeMarks"
                                          label="Negative Marking"
                                          sx={{ width: "100%" }}
                                          type="number"
                                        />
                                    )}
                        </Stack>

                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }} spacing={2}>
                            <RHFTextField name="No_of_question" label="No Of Questions" type='number' />
                            <RHFTextField name="duration" label="Duration (In Minutes)" type='number' />
                            {/* <RHFTextField name="validity" label="Validity ()" /> */}
                            <RHFTextField name="instructions" label="Instructions" />

                        </Stack>




                        {/* <RHFTextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                /> */}
                    </Stack>

                    {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <RHFCheckbox name="remember" label="Remember me" />
                <Link variant="subtitle2" underline="hover" sx={{ color: '#EA506B' }}>
                    Forgot password?
                </Link>
            </Stack> */}

                    {/* progress Bar */}
                    <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span>{progress}% </span>
                        </div>
                    </div>

                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: '#EA506B', width: '80%', marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setMsg('Required')
                            setVal(true)
                            // setMsgDemoVideo('Demo Video Required')
                        }}
                    >
                        Add
                    </LoadingButton>

                </FormProvider>
                <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {/* <Typography sx={{ color: 'red', textAlign: 'center' }}>{fetchMessage}</Typography> */}
            </Stack>
        </Page>
    </>
    )
}

export default AddTest