import { Box, Button, Divider, Input, ListItem, ListItemText, Popover, Stack, Typography } from '@mui/material'
import React from 'react'
import SyncLoader from "react-spinners/SyncLoader";
import { useParams } from 'react-router-dom';
import { UserMoreMenu } from '../user';
import axios from 'axios';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LectureResourcesTable = ({ setLoadingTable }) => {
    const [loading, setLoading] = React.useState(true)
    const [users, usersSet] = React.useState()
    const { lectId, batchId } = useParams()
    const [isEdit, setIsEdit] = React.useState(false)

    const [lectureTitle, setLectureTitle] = React.useState()

    const [focus, setFocused] = React.useState(false);
    const [file, setFile] = React.useState()
    //Modal
    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setIsEdit(true);
    // const handleClose = () => setIsEdit(false);

    //Getting Details
    React.useEffect(() => {

        const token = localStorage.getItem("token");
        async function fetchUsers() {

            const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureResourceDetails?Lecture_id=${lectId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const responseJson = await fullResponse.json();
            usersSet(responseJson.data);
            setLoading(false)
            setLoadingTable(false)


        }

        fetchUsers();
    }, []);
    const handleChange = (e) => {
        const { value } = e.target
        // setLectureTitle(() => {
        //     return { title: value }
        // })
        setLectureTitle(value)

    }
    const changeHandler = async (event) => {
        setFile(event.target.files[0])


    };
    const [iid, setiid] = React.useState()
    //console.log('IIIId' + iid)
    return (
        <>
            {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ height: '100%' }}> <SyncLoader color='red' loading={loading} size={20} /></Stack>) :
                (<Box spacing={3} sx={{ width: '60%' }}>
                    <Typography variant='h4'>
                        Lecture Resources
                    </Typography>
                    {users?.length != 0 ? users?.map((item, i) => {


                        const changeHandler = async (event) => {

                            if (item?.resourceType == 'link') {
                                setFile(event.target.value)
                            } else if (item?.resourceType == 'file') {
                                setFile(event.target.files[0])
                            }
                        };
                        //console.log(file)
                        //console.log(item?.resourceType)
                        const handleUpdate = async () => {
                            setLoading(true)
                            const token = localStorage.getItem("token");
                            const authToken = token;
                            const formData = new FormData();
                            if (file) {
                                if (item?.resourceType == 'link') {
                                    formData.append('resurce_type', item?.resourceType)
                                    formData.append('link', file)

                                }
                            }
                            if (file) {
                                if (item?.resourceType == 'file') {
                                    formData.append('resurce_type', item?.resourceType)
                                    formData.append('file', file)

                                }
                            }
                            if (lectureTitle) {
                                formData.append('title', lectureTitle)
                            }
                            formData.append('batch_id', batchId)
                            formData.append('lecture_id', lectId)
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data',
                                    Authorization: `Bearer ${authToken}`,
                                },
                            };
                            const res = await axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateTheLectureResourceDetails/${item._id}`, formData, config);
                            {/* //console.log(res.msg) */ }
                            setLoading(false)
                            toast.success('Updated')
                            setTimeout(() => {
                                window.location.reload()
                            }
                                , 1000)
                        }
                        return <div key={i}>
                            {item.is_Verified ? (<><Stack key={i} direction='row' justifyContent="space-between">
                                <Stack direction="row" justifyContent="center" alignItems='center' spacing={2} pl={0} pt={2} mb={2} sx={{ position: 'relative' }}>
                                    {isEdit && iid === item._id ? (item?.resourceType == 'link' || item?.resourceType == 'yt_videos') ? (<Input
                                        onChange={changeHandler}
                                        // value={val}
                                        name='link'
                                        // label={item?.upload_file?.fileLoc}
                                        placeholder={item?.upload_file?.fileLoc}
                                        type='url'

                                    />) : (<>
                                        <input
                                            style={{
                                                display: "none"
                                            }}

                                            id="choose-file"
                                            type="file"
                                            onChange={changeHandler}

                                        />
                                        <label htmlFor="choose-file">

                                            <FileUploadIcon sx={{
                                                cursor: 'pointer', '&:hover': {
                                                    color: 'red'
                                                }
                                            }} />
                                        </label>
                                    </>) :
                                        (<a href={item.upload_file?.fileLoc} style={{ color: 'black' }}>
                                            {item?.resourceType == 'link' ? (<InsertLinkIcon fontSize='large' p={1} style={{ background: 'lightGray', height: '50px', width: '50px', padding: '10px', borderRadius: '10px' }} />) : item?.resourceType == 'pdf' ? (<PictureAsPdfIcon fontSize='large' p={1} style={{ background: 'lightGray', height: '50px', width: '50px', padding: '10px', borderRadius: '10px' }} />) : item?.resourceType == 'yt_videos' ? (<YouTubeIcon fontSize='large' p={1} style={{ background: 'lightGray', height: '50px', width: '50px', padding: '10px', borderRadius: '10px' }} />) : (<VideoFileIcon fontSize='large' p={1} style={{ background: 'lightGray', height: '50px', width: '50px', padding: '10px', borderRadius: '10px' }} />)}
                                        </a>)}
                                    <Stack>
                                        <Typography variant='h7' mt={0.8}>
                                            {isEdit && iid === item._id ? <Input
                                                onChange={handleChange}
                                                // value={value}
                                                name='subject'
                                                placeholder={item.title} /> :



                                                item.title}
                                        </Typography>

                                        {/* <Typography sx={{ fontSize: '10px' }}>
                                            <span style={{ fontWeight: '700' }}>Time:</span>  1h2m3s
                                        </Typography> */}
                                    </Stack>
                                    {/* <MoreVertIcon style={{ ...moreVertIcon }} aria-describedby={id} variant="contained" onClick={handleClick} /> */}

                                </Stack>
                                {isEdit && iid === item._id ? (<Button onClick={handleUpdate} variant='contained' sx={{ height: '10%', marginTop: '25px' }}>Update</Button>) : ''}
                                <Box onClick={() => setiid(item._id)}
                                    sx={{ pt: 3 }}
                                > <UserMoreMenu id={item._id} req='deleteLectureresourceDetails' isEdit={isEdit} setIsEdit={setIsEdit} title='lectureResourcetable' details={item}

                                    /></Box>

                            </Stack>
                                <Divider /></>) : null}


                        </div>
                    }) : (<Typography variant='h5'>No Resources</Typography>)}
                    <ToastContainer
                        position="bottom-center"
                        autoClose={1000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </Box>

                )
            }
        </>)
}

export default LectureResourcesTable