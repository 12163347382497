import React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Popover,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoCard from "../../../components/batch/InfoCard";
import { fShortenNumber } from "../../../utils/formatNumber";
import { bgcolor } from "@mui/system";
import Iconify from "../../../components/Iconify";
import "./style.css";

const Batch = () => {
  const navigate = useNavigate();

  const [users, usersSet] = React.useState([]);
  const access = JSON.parse(localStorage.getItem("detailObject"));

  const [teacherData, setTeacherData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [batchIdForPopover, setBatchIdForPopover] = React.useState();
  const role = localStorage.getItem("role");
  const teacherId = localStorage.getItem("id");
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);

      if (role === "Teacher") {
        const teacherResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails?teacher_id=${teacherId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response = await teacherResponse.json();
        setTeacherData(response.data);
        setLoading(false);
      }
    }
    fetchUsers();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleSetBatchId = (id) => {
    setBatchIdForPopover(id);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <>
          <Page title="Batch">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              pl={5}
              pr={3}
            >
              <Typography className="page-heading">All Batches</Typography>

              <TextField
                label="Search Batch"
                variant="outlined"
                margin="normal"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                sx={{
                  width: "300px",
                  height: "20px",
                  marginBottom: "70px",
                  "& label": {
                    color: "black",
                  },
                  "& fieldset": {
                    borderColor: "black",
                  },
                }}
              />
            </Stack>

            <Grid container pl={5} pr={3} spacing={4}>
              {(access?.access.includes("Add Batch") ||
                access?.access.includes("all")) && (
                <Grid item xs={12} sm={6} md={4}>
                  <InfoCard
                    title="Add"
                    data={null}
                    path="/dashboard/addBatch"
                    setLoading={setLoading}
                  />
                </Grid>
              )}
              {users
                ?.filter((user) =>
                  user.batch_name
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
                )
                .map((user, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <InfoCard
                      title="Info"
                      data={user}
                      path=""
                      setLoading={setLoading}
                      use="Batches"
                    />
                  </Grid>
                ))}
            </Grid>
          </Page>
        </>
      )}
    </>
  );
};

export default Batch;
