import { Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'

const ButtonStyle = {
    color: 'white',
    background: '#F95368',
    borderRadius: '15px',
    width: '90%',
    height: '32px',
    alignSelf: 'center',
    boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.15)',
    trasition: 'background 0.2s ease-out',
    '&:hover': {
        // background: 'linear-gradient(90deg, rgba(36,11,0,0.6001751042213761) 0%, rgba(250,47,47,0.9363095580028886) 31%)',
        background: 'red'
        // color: '#F95368'
    }
}
const PracticesCard = ({ value }) => {
    // //console.log(value)
    const CardStyle = {
        minHeight: '150px',
        minWidth: '31%',
        maxWidth: '31%',
        // flex: 1,
        flexWrap: 'nowrap',
        transition: 'all 0.3s',
        translate: `${value}px`,

    }
    return (
        <Card sx={{ ...CardStyle }}>
            <Stack p={1} justifyContent="space-between" sx={{ height: '100%' }}>
                <Typography color="#F95368" fontSize="small" sx={{ textAlign: 'start' }}>
                    English
                </Typography>
                <Typography color="#4A4A4A" fontSize="medium" sx={{ textAlign: 'start', fontWeight: '700' }}>
                    Doubt Clearin Session-III
                </Typography>
                <Typography color="#727070" fontSize="small" sx={{ textAlign: 'start' }}>
                    20 Questions
                </Typography>
                <Button variant="contained" className="btnFillup anglebg" sx={{ ...ButtonStyle }}>Get Permission</Button>
                <Typography color="#727070" fontSize="small" sx={{ textAlign: 'center', fontWeight: '600' }}>
                    100 Attempt
                </Typography>
            </Stack>
        </Card>
    )
}

export default PracticesCard