import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

//import "./general.css";

// ----------------------------------------------------------------------

export default function AddMentor() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('Name required'),
        title: Yup.string().required('title  required'),
       // mobile: Yup.string().required('mobile number is required'),
        // banner_url: Yup.string().required('Url No. is required'),
        // language: Yup.string().required('Language is required'),

        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        name: '',
        title: '',
        mobile: '',
        // banner_url: '',
        // language: '',
        is_active: '',
    };
    const [file, setFile] = useState()



    const handleChange = (value) => {
        //console.log(`${value}`);
    };
    const changeHandler = async (event) => {
        setFile(event.target.files)
        var fileName = document.getElementById('file').value.toLowerCase();
        if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
        else { setRestrict('JPG Or PDF') }
        //console.log(file)

    };
    //console.log(file)
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    // for progress bar

    const onSubmit = async (data) => {
        // //console.log(data)
        // var fileName = document.getElementById('file').value.toLowerCase();

        // if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) {


        // setRestrict('')
        // let myfile = [...file]
        if (file) {
            const formData = new FormData();

            setLoading(true)

            //console.log(file)
            Object.values(file).forEach(file => {
                formData.append("image", file);
            })
            formData.append('name', data.name);
            // formData.append('category', data.category);
           // formData.append('phone', data.mobile);
            // formData.append('subject', data.subject);
            formData.append('title', data.title);
            //formData.append('language', 'en');
            formData.append('status', data.is_active);
            // formData.append('fileName', file.name);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    //Set the progress value to show the progress bar
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            // document.querySelector(".mainDiv").style.display = "block";
            setProgress(0);
            axios.post(`${process.env.REACT_APP_LIVE_URL}/mentor/add-mentors`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.msg === 'Mentor created successfully.') {
                    toast.success(response.data.msg);
                    setLoading(false);
                    setPdf(false);
                    reset();
                    window.location.href = "/dashboard/mentors";
                }

            })
                .catch(e => {
                    //console.log(e);
                    toast.error(e);
                    setLoading(false);
                    setPdf(false);
                    reset();
                })
        }
        // } else {

        //     setRestrict('Please Select pdf or jpg')
        // }
    };

    //CategoryDetails
    // useEffect(() => {
    //     const token = localStorage.getItem("token");
    //     async function fetchUsers() {
    //         const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategory?type=Banner`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${token}`
    //             },

    //         });
    //         const responseJson = await fullResponse.json();

    //         usersSet(responseJson.data);
    //     }
    //     fetchUsers();
    // }, []);
    // //console.log(users)
    return (
      <>
        <Stack spacing={3} px={5}>
          <Typography variant="h3">Add Mentor</Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ width: "80%" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField name="name" label="name" />
                <RHFTextField name="title" label="designation" />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
                spacing={2}
              >
                <RHFTextField
                  name="file"
                  onChange={changeHandler}
                  id="file"
                  label="File"
                  error={!file && val}
                  helperText="Image Only"
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  // inputProps={{ multiple: true }}
                  InputProps={{
                    classes: {
                      input: "CustomTextField",
                    },
                  }}
                  onClick={() => setPdf(true)}
                  inputProps={{ accept: "image/*" }}
                  type={pdf || focus ? "file" : "text"}
                />

                <RHFTextField
                  name="is_active"
                  label="Status"
                  type="checkbox"
                  select
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </RHFTextField>
              </Stack>

              {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={loading}
                sx={{
                  bgcolor: "#EA506B",
                  marginTop: "5%",
                  "&:hover": { background: "red" },
                }}
                onClick={() => {
                  setVal(true);
                  setMsg("Required");
                }}
              >
                Add
              </LoadingButton>
            </Stack>
          </FormProvider>
          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
      </>
    );
}
