import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Input,
  MenuItem,
  TextField,
} from "@mui/material";
// components
import Page from "../../../components/Page";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { ExportCSV } from "../../../utils/ExportCSV";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// For Dialog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { CSVLink } from 'react-csv'; // for CSV file download
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "sno", label: "S.No", alignRight: false },
  { id: "couponCode", label: "Coupon Code", alignRight: false },
  { id: "couponType", label: "Coupon Type ", alignRight: false },
  { id: "couponValue", label: "Coupon Value ", alignRight: false },
  { id: "expirationDate", label: "Expire At", alignRight: false },
  { id: "couponAccess", label: "Access Type", alignRight: false },
  { id: "is_active", label: "Status", alignRight: false },
  { id: "action", label: "Action", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  // console.log(array);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.couponCode.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AllCoupon() {


  // Code for handling Dialog box
  const [download, setDownload] = useState(true);
  const [errorOTP, setErrorOtp] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      action: "Exporting All Users Data"
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
        data,
        config
      )
      .then((response) => {
      })
      .catch((e) => {
        //console.log(e);
      });

  }
  const verifyOtp = () => {
    //console.log(document.getElementById('name').value);
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      otp: document.getElementById('name').value
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        // //console.log(response);
        if (response.data.status == false) {
          setErrorOtp("Invalid Otp");
        }
        else {
          setDownload(false);
          setErrorOtp("Successfully Verified");
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  }



  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState();
  const [starting, setStarting] = useState(dayjs());
  const handleChange = (newValue) => {
    setStarting(newValue);
  };

  //Getting notes
  const [isActiveValue, setIsActiveValue] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCoupons`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      usersSet(responseJson.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;

  const filteredUsers = applySortFilter(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );
  const csvData = filteredUsers.map((item) => {
    return {
      couponId: item.couponId,
      couponValue: item.couponValue,
      couponType: item?.couponType,
      couponCode: item.couponCode,
      couponAccess: item.couponAccess,
      createdBy: item?.createdBy?.name,
      createrRole: item?.createdBy?.role,
      createdAt: item.created_at,
      expireAt: item.expirationDate ? item.expirationDate.slice(0, 10) : "", // Check if expirationDate exists
      isActive: item?.is_active,
    };
  })
  let helperArray = [];
  filteredUsers?.map((item) =>
    helperArray.push({
      ID: item?._id,
      File_url: item?.file_url?.fileLoc,
      Title: item?.title,
      Is_active: item?.is_active,
      Language: item?.language,
      Notes_type: item?.notes_type,
    })
  );

  const isUserNotFound = filteredUsers.length === 0;
  const [isEdit, setIsEdit] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  //Enable Coupon
  const enableCoupon = async (id, val) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await axios.put(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/enableOrDisableCoupon/${id}`,
      {
        is_active: val,
      },
      config
    );

    setLoading(false);
    toast.success("Updated");
    setTimeout(() => window.location.reload(), 800);
  };


  const access = JSON.parse(localStorage.getItem('detailObject'));

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "60vh" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Coupons">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Coupons
              </Typography>
              {(access?.access?.includes('Add Coupon') || access?.access.includes('all')) && <Button
                variant="contained"
                sx={{
                  width: "20%",
                  alignSelf: "center",
                  background: "#EA506B",
                  "&:hover": { background: "red" },
                }}
                onClick={() => navigate("/dashboard/addCoupon")}
              >
                Add Coupon
              </Button>}
            </Stack>

            <Card>
              <Stack
                pr={3}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName="Notes Data" /> */}

                <Button variant="outlined" onClick={() => { handleClickOpen(); sendOtp(); }}>
                  Export
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  {download ? (
                    <>
                      <DialogTitle>OTP Varification</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          OTP is send successfully to your regisered e-mail.
                        </DialogContentText>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="OTP Number"
                          type="number"
                          fullWidth
                          variant="standard"
                        />
                        <DialogContentText sx={{ color: "red", fontSize: '12px', padding: "3px" }}>
                          {errorOTP}
                        </DialogContentText>
                      </DialogContent>

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={verifyOtp}>Verify</Button>
                      </DialogActions>
                    </>
                  ) : (
                    <>
                      <DialogTitle>Download File</DialogTitle>
                      <DialogContent>
                        <CSVLink data={csvData} filename="AllCoupons.csv">Download</CSVLink>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => {
                          handleClose();
                          setDownload(true);
                        }}>Cancel</Button>

                      </DialogActions>
                    </>
                  )}
                </Dialog>

              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const {
                            id,
                            couponCode,
                            couponType,
                            _id,
                            couponValue,
                            expirationDate,
                            couponAccess,
                            is_active,
                            couponId,
                          } = row;
                          const isItemSelected =
                            selected.indexOf(couponCode) !== -1;
                          //console.log("activ statues" + is_active);

                          //Handling File Update
                          const changeHandler = async (event) => {
                            setFile(event.target.files[0]);
                          };

                          //Handling Value Updates
                          const onChange = (e, row, _id) => {
                            const { name, value } = e.target;
                            if (name == "is_active") {
                              setIsActiveValue(true);
                            }
                            setUpdatedValues((prev) => {
                              return { ...prev, [name]: value };
                            });
                          };
                          const handleUpdate = async () => {
                            setLoading(true);
                            let data;
                            data = { ...updatedValues };

                            makeReq(data);
                          };
                          const makeReq = async (data) => {
                            const token = localStorage.getItem("token");
                            const reqObject = {};
                            if (data.couponCode) {
                              reqObject.couponCode = data.couponCode;
                            }
                            if (data.couponType) {
                              reqObject.couponType = data.couponType;
                            }
                            if (data.couponValue) {
                              reqObject.couponValue = data.couponValue;
                            }
                            if (isActiveValue) {
                              reqObject.is_active = data.is_active;
                            }
                            const config = {
                              headers: {
                                "content-type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                            };
                            const res = await axios.put(
                              `${process.env.REACT_APP_LIVE_URL}/adminPanel/editCoupon/${couponId}`,
                              reqObject,
                              config
                            );

                            setLoading(false);
                            toast.success("Updated");
                            setTimeout(() => window.location.reload(), 800);
                          };

                          return (
                            <TableRow
                              hover
                              key={index}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, title)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2">
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === couponId ? (
                                      <Input
                                        onChange={(e) => onChange(e, row, _id)}
                                        // value={value}
                                        name="couponCode"
                                        placeholder={couponCode}
                                      />
                                    ) : (
                                      <span>{couponCode}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === couponId ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row)}
                                        // value={value}
                                        label={couponType}
                                        name="couponType"
                                        // placeholder={is_active ? 'Active' : 'Inactive'}
                                        size="small"
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        <MenuItem value="percentage">
                                          Percentage
                                        </MenuItem>
                                        <MenuItem value="fixed">Fixed</MenuItem>
                                      </TextField>
                                    ) : (
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {couponType}
                                      </span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === couponId ? (
                                      <Input
                                        onChange={(e) =>
                                          onChange(e, row, couponId)
                                        }
                                        // value={value}
                                        name="couponValue"
                                        placeholder={couponValue}
                                      />
                                    ) : (
                                      <span>{couponValue}</span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    <span>{expirationDate.slice(0,10)}</span>
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  {/* <Avatar alt={name} src={avatarUrl} /> */}
                                  <Typography variant="subtitle2" noWrap>
                                    {isEdit && selectedId === couponId ? (
                                      <TextField
                                        onChange={(e) => onChange(e, row)}
                                        // value={value}
                                        label={couponAccess}
                                        name="couponAccess"
                                        // placeholder={is_active ? 'Active' : 'Inactive'}
                                        size="small"
                                        select
                                        sx={{ width: "100px" }}
                                      >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="single">
                                          Single
                                        </MenuItem>
                                      </TextField>
                                    ) : (
                                      <span
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {couponAccess}
                                      </span>
                                    )}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color={is_active ? "error" : "success"}
                                  onClick={() =>
                                    enableCoupon(couponId, !is_active)
                                  }
                                >
                                  {is_active ? "Disable" : "Enable"}
                                </Button>
                              </TableCell>
                              {isEdit && selectedId === couponId ? (
                                <TableCell align="left">
                                  <Button
                                    variant="outlined"
                                    onClick={handleUpdate}
                                  >
                                    Update
                                  </Button>
                                </TableCell>
                              ) : null}

                              <TableCell
                                align="right"
                                onClick={() => setSelectedId(couponId)}
                              >
                                <UserMoreMenu
                                  id={couponId}
                                  req="deleteCoupon"
                                  isEdit={isEdit}
                                  setIsEdit={setIsEdit}
                                  setLoading={setLoading}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
              <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
}
