import * as Yup from "yup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// ----------------------------------------------------------------------

export default function AddQuiz() {
  const [loading, setLoading] = useState(false);
  const [focusThree, setFocusedThree] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);

  const navigate = useNavigate();

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  const RegisterSchema = Yup.object().shape({
    title: Yup.string().required("Title required"),
    description: Yup.string().required("Description is required"),
    duration: Yup.string().required("Duration is required"),
    // NoOfQues: Yup.string().required('No. Of Questions is required'),
    isNegative: Yup.boolean().required("Negative Marking is required"),
    isActive: Yup.string().required("Status is required"),
    language: Yup.string().required("Language is required"),
    eachQueMarks: Yup.string().required("Language is required"),
    negativeMarks: Yup.number().when("isNegative", (isNegative, schema) => {
      return isNegative
        ? schema.test(
          "positive-and-greater-than-zero",
          "Negative Marks must be a positive number greater than zero",
          (value) => value > 0
        ).max(Yup.ref("eachQueMarks"), "Negative Marks must not exceed Each Question Marks")
        : schema;
    }),
    resultType: Yup.string().required("Result Type is required"),
  });

  const defaultValues = {
    title: "",
    description: "",
    duration: "",
    // NoOfQues: '',
    isNegative: "",
    isActive: "",
    language: "",
    eachQueMarks: "",
    negativeMarks: 0,
    resultType: "",
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const [showNegative, setShowNegative] = useState(false);

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true);
    var formData = new FormData();
    // const obj = { ...data };
    // console.log(obj);
    formData.append("file", file);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("duration", data.duration);
    // formData.append('no_ques    ', data.NoOfQues);
    formData.append("isNegative", data.isNegative);
    formData.append("language", data.language);
    formData.append("isActive", data.isActive);
    formData.append("eachQueMarks", data.eachQueMarks);
    if (data.isNegative) {
      formData.append("negativeMarks", data.negativeMarks);
    }
    formData.append("resultType", data.resultType);
    // alert(formData)


    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/addQuiz`,
        formData,
        config
      )
      .then((response) => {
        // axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addQuiz`, formData, config).then((response) => {
        // axios.post(`http:localhost:5000/api/v1/adminPanel/addQuiz`, formData, config).then((response) => {
        //console.log(response.data);
        if (response.data.msg === "Quiz Details Added Successfully") {
          toast.success(response.data.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          reset();
          setTimeout(() => {
            navigate("/dashboard/quiz");
          }, 1000);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Quiz</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <RHFTextField name="title" label="Quiz Title" />
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="duration"
                label="Duration (in Min)"
                type="number"
              />
              {/* <RHFTextField name="NoOfQues" label="No. Of Questions" /> */}
            </Stack>
            <RHFTextField name="description" label="Description" />
            <RHFTextField
              name="file"
              error={!file && val}
              onChange={changeHandler}
              onFocus={() => setFocusedThree(true)}
              onBlur={() => setFocusedThree(false)}
              // inputProps={{ accept: "application/pdf" }}
              // helperText="Pdf Files Only"
              InputProps={{
                classes: {
                  input: "CustomTextField",
                },
              }}
              label="Banner"
              onClick={() => setShowBanner(true)}
              type={showBanner || focusThree ? "file" : "text"}
            />
            <RHFTextField
              name="language"
              label="Language"
              sx={{ width: "100%" }}
              select
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="hi">Hindi</MenuItem>
              <MenuItem value="enhi">Bilungual</MenuItem>
            </RHFTextField>
            <RHFTextField name="resultType" label="Result Type" select>
              <MenuItem value={true}>Manual Result</MenuItem>
              <MenuItem value={false}>Autogenerate Result</MenuItem>
            </RHFTextField>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="isNegative"
                label="Negative Marking"
                sx={{ width: "100%" }}
                select

              >
                <MenuItem value={true} onClick={() => setShowNegative(true)}>Yes</MenuItem>
                <MenuItem value={false} onClick={() => setShowNegative(false)}>No</MenuItem>
              </RHFTextField>
              <RHFTextField name="isActive" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="eachQueMarks"
                label="Each Question Marks"
                sx={{ width: "100%" }}
                type="number"
              />

              {showNegative && (
                <RHFTextField
                  name="negativeMarks"
                  label="Negative Marking"
                  sx={{ width: "100%" }}
                  type="number"
                />
              )}


            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#EA506B",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
