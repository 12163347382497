import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // Import useParams hook

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F67382",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#e56372",
  },
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const UpdateCommunity = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [communityName, setCommunityName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [maxMembers, setMaxMembers] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);

  useEffect(() => {
    const fetchCommunityData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL}/admin-community/community/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const communityData = response.data.data;

        setCommunityName(communityData.name);
        setMaxMembers(communityData.maximumMembers);
        setDescription(communityData.description);
        setSelectedMembers(communityData.members || []);
      } catch (error) {
        console.error("Error fetching community data:", error.message);
      }
    };

    if (id) {
      fetchCommunityData();
    }
  }, [id]);

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedMembers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedMembers, userId];
    } else {
      newSelected = selectedMembers.filter((id) => id !== userId);
    }

    setSelectedMembers(newSelected);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", communityName);
    formData.append("file", imageFile);
    formData.append("maximumMembers", maxMembers);
    formData.append("description", description);
    formData.append("members", JSON.stringify(selectedMembers));

    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/update-community/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Community updated successfully!", response.data);
      navigate("/dashboard/community");
    } catch (error) {
      console.error("Error updating community:", error.message);
    }
  };

  return (
    <Box p={6}>
      <Typography variant="h4" gutterBottom>
        Update Community
      </Typography>

      <form onSubmit={handleFormSubmit}>
        <CustomTextField
          fullWidth
          variant="outlined"
          label="Community Name"
          placeholder="Enter Community Name"
          value={communityName}
          onChange={(e) => setCommunityName(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Supported Files .jpg, .png"
          placeholder="Choose Image"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          InputProps={{
            startAdornment: (
              <input
                type="file"
                style={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  position: "absolute",
                }}
                accept="image/*"
                onChange={handleImageChange}
              />
            ),
          }}
          sx={{ marginBottom: 2 }}
        />

        <CustomTextField
          fullWidth
          variant="outlined"
          label="Maximum Members"
          placeholder="100 Members"
          value={maxMembers}
          onChange={(e) => setMaxMembers(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <CustomTextField
          fullWidth
          variant="outlined"
          label="Description"
          placeholder="Enter Description Here"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <Box display="flex" justifyContent="center">
          <CustomButton type="submit" variant="contained">
            Update Community
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
};

export default UpdateCommunity;
