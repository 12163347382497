import * as Yup from "yup";
import { useState, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// ------------------------------------------------------------------
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function AddCoupon() {
  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);
  const [starting, setStarting] = useState(dayjs());
  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const RegisterSchema = Yup.object().shape({
    couponCode: Yup.string().required("couponCode required"),
    couponType: Yup.string().required("couponType is required"),
    couponValue: Yup.string().required("couponValue is required"),
    couponAccess: Yup.string().required("couponAccess is required"),
    is_active: Yup.boolean().required("Status Required"),
  });
  const defaultValues = {
    couponCode: "",
    couponType: "",
    couponValue: "",
    couponAccess: "",
    is_active: "",
  };
  const handleChange = (newValue) => {
    setStarting(newValue);
  };
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // setLoading(true)
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const reqObject = {
      ...data,
      expirationDate: starting?.$d,
    };
    // console.log(reqObject);
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/addCoupon`,
        reqObject,
        config
      )
      .then((response) => {
        //console.log(response.data);
        if (response.data.msg === "Coupon Details Added Successfully") {
          toast.success(response.data.msg);
          setVal(false);
          setMsg("");
          setLoading(false);
          reset();
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };
  //console.log(starting.$d);
  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Coupon </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "70%" }}>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponCode" label="Coupon Code" />
              <RHFTextField name="couponType" label="Coupon Type" select>
                <MenuItem value="percentage">Percentage (%)</MenuItem>
                <MenuItem value="fixed">Fixed</MenuItem>
              </RHFTextField>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponValue" label="Coupon Value" />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  spacing={3}
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <DateTimePicker
                    label="Expire At"
                    value={starting.$d}
                    onChange={handleChange}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                </Stack>
              </LocalizationProvider>
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField name="couponAccess" label="Coupon Access" select>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="single">Single</MenuItem>
              </RHFTextField>
              <RHFTextField name="is_active" label="Status" select>
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </RHFTextField>
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#EA506B",
                marginTop: "5%",
                "&:hover": { background: "red" },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
      </Stack>
    </>
  );
}
