import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Page from "../components/Page";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axios from "axios";
import TablePagination from "@mui/material/TablePagination";
import SyncLoader from "react-spinners/SyncLoader";
import { ToastContainer, toast } from "react-toastify";
import { CSVLink } from "react-csv";

export default function Student() {
  const [selected, setSelected] = useState([]);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStatus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState("");
  const [showDownload, setShowDownload] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = React.useState(5);
  const [userarray, setUserarray] = useState([]);
  const [page, setPage] = React.useState(0); // Page starts from 0 for TablePagination

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      const context = this;
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        timeoutId = null;
        func.apply(context, args);
      }, delay);
    };
  };

  const debouncedFetchUsers = React.useCallback(
    debounce((searchText) => {
      const token = localStorage.getItem("token");
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`,
            {
              params: {
                page: page + 1, // Backend expects page starting from 1
                limit: pageSize,
                search: searchText,
              },
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setUsers(response?.data?.data || []);
          setCount(response?.data?.count || 0);
          setLoading(false);

          if (response.data.msg === "not an admin") {
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("details");
            navigate("/");
          }
        } catch (error) {
          console.error("Error fetching users:", error);
          console.error("Error details:", error.response);
          setLoading(false);
        }
      };

      fetchData();
    }, 2000), // Adjust debounce delay here (e.g., 500ms)
    [page, pageSize, navigate]
  );

  useEffect(() => {
    debouncedFetchUsers(searchText);
  }, [searchText, debouncedFetchUsers]);

  const handleSelectionModelChange = (params) => {
    setSelected(params.selectionModel);
  };

  const handleClickOpen = () => {
    sendOtp();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStatusUpdate = (userId, status) => {
    setStatusUser(userId);
    setStatusStatus(status);
    // setOpen(true);
  };

  const sendOtp = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      action: "Exporting All Users Data",
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/send-otp`,
        data,
        config
      )
      .catch((e) => {
        console.error(e);
      });
  };
  const handleUserArray = (id) => {
    setUserarray((prev) => {
      const updatedArray = [...prev, id]; // Create a new array with the added user ID
      Allowuser(updatedArray); // Pass the updated array to the Allowuser function
      return updatedArray; // Return the updated array to update the state
    });
  }


// const Allowuser= async (uid)=>{
//   const token = localStorage.getItem("token");
//   const config = {
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   try {
//     const res = await axios.post(
//                 `${process.env.REACT_APP_LIVE_URL}/adminPanel/logoutTheUserFromOtherDevices`,{ userIdArr: [uid] },
//                 config)
//               .then((response) => {
//                 console.log(response);
//               });
//               if (res.status >= 200 && res.status < 300) {
//                 toast.success("User Logout Successfully");
//                 window.location.reload();
//               } else {
//                 // Handle non-successful response
//               }
//             } catch (error) {
//               // Handle error
//               console.error("An error occurred:", error);
//             }
// }

const Allowuser = async (uid) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_LIVE_URL}/adminPanel/logoutTheUserFromOtherDevices`,
      { userIdArr: uid }, // Send the userId in an array
      config
    ).then(response => {
      console.log(response);  // Log the response
      return response;
    });

    if (res.status >= 200 && res.status < 300) {
      toast.success("User Logout Successfully");
      window.location.reload();
    } else {
      // Handle non-successful response (optional)
      toast.error("Failed to logout user");
    }
  } catch (error) {
    // Handle error
    console.error("An error occurred:", error);
    toast.error("An error occurred while logging out the user");
  }
};

  const verifyOtp = () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = {
      otp: document.getElementById("name").value,
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/verify-otp`,
        data,
        config
      )
      .then((response) => {
        if (response.data.status === false) {
          setErrorOtp("Invalid Otp");
        } else {
          if (showDownload) {
            setShowDownload(false);
            setDownload(false);
          } else {
            const data1 = {
              isActive: !statusStatus,
            };

            axios
              .post(
                `${process.env.REACT_APP_LIVE_URL}/adminTeacher/activeInactiveStudent/${statusUser}`,
                data1,
                config
              )
              .then((response) => {
                console.log(response);
              });

            handleClose();
            toast.success("Status Updated");
            setTimeout(() => window.location.reload(), 1000);
            setErrorOtp("");
          }
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    setPage(0)
  };

  const columns = [
    { field: "enrollId", headerName: "Enrollment No.", width: 150 },
    { field: "StudentName", headerName: "Name", width: 150 },
    { field: "mobileNumber", headerName: "Phone No.", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "signinType", headerName: "AuthType", width: 100 },
    // { field: "deviceName", headerName: "Device", width: 100 },
    
    {
      field: "action",
      headerName: "Action",
      
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={() =>
            navigate(`/dashboard/students/studentsProfile/${params.id}`)
          }
        >
          View
        </Button> 
        
      ),
   
    },
    {
      field: "deviceName",
      headerName: "Device",
      renderCell: (params) => (
        <>
          {params?.value ? (
           <Button
           variant="outlined"
           size="small"
           sx={{ padding: "5px 5px" }}  // Use sx for styling in MUI v5 or inline style if needed
           color="info"
           onClick={() => handleUserArray(params?.id)}
         >
         { params?.value}
         </Button>
          ) : ""}
        </>
      ),
    },
    
    {
      field: "is_active",
      headerName: "Status",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          color={params.value ? "success" : "error"}
          onClick={() => handleStatusUpdate(params.id, params.value)}
        >
          {params.value ? "Active" : "InActive"}
        </Button>
      ),
    },
  ];

  const CustomToolbar = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: "50px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button variant="outlined" onClick={handleClickOpen}>
          Export
        </Button>
      </div>
    );
  };

  const CustomExportToolbar = () => {
    return (
      <div>
        <GridToolbarExport />
      </div>
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };
 
  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Students">
          <Card>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              mb={3}
              px={3}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{ marginBottom: { xs: 2, sm: 0 } }}
              >
                All Users 
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: { xs: 2, sm: 0 },
                }}
              >
                <TextField
                  label="Search by Name"
                  variant="outlined"
                  size="small"
                  value={searchText}
                  onChange={handleSearchChange}
                  sx={{
                    width: "250px",
                    height: "20px",
                    "& label": {
                      color: "black",
                    },
                    "& fieldset": {
                      borderColor: "black",
                    },
                  }}
                />
              </div>
            </Stack>
            <DataGrid
              rows={users}
              columns={columns}
              hideFooterPagination
              pageSize={pageSize}
              page={page}
              onPageChange={handlePageChange}
              onPageSizeChange={handleRowsPerPageChange}
              pagination
              components={{
                Toolbar: CustomToolbar,
                ExportToolbar: CustomExportToolbar,
              }}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Card>

          <Dialog open={open} onClose={handleClose}>
            {download ? (
              <>
                <DialogTitle>OTP Verification</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    OTP is sent successfully to your registered e-mail.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="OTP Number"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                  <DialogContentText
                    sx={{
                      color: "red",
                      fontSize: "12px",
                      padding: "3px",
                    }}
                  >
                    {errorOTP}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={verifyOtp}>Verify</Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle>Download File</DialogTitle>
                <DialogContent>
                  <CSVLink data={users} filename="AllUsers.csv">
                    Download
                  </CSVLink>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      handleClose();
                      setDownload(true);
                    }}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>

          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
}
