import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";
import Iconify from "../../../components/Iconify";
import { Link, useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import axios from "axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { UserMoreMenu } from "../user";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BatchDetailsCard from "./components/BatchDetailsCard";
import RecordedLecturePlay from "./RecordedLecturePlay";
import "./style.css";
import { useState, useEffect } from "react";

// for list
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const ListItemStyle = {
  cursor: "pointer",
  transition: "",
  padding: "5px 10px",
  "&:hover": {
    bgcolor: "lightgray",
  },
};
const AccordionStyle = {
  // boxShadow: '1px 1px 7px 1px rgba(128,128,128,0.4)',
  background: "rgba(249, 172, 192, 0.15)",
  borderRadius: "18px",
  width: "90%",
  transition: "all 0.3s",
  position: "relative",
  transition: "all 0.2s",
  "&:hover": {
    background:
      "linear-gradient(180deg, rgba(255, 122, 130, 0.4) 0%, rgba(255, 122, 130, 0.104) 100%)",
  },
};

const BatchDetails = () => {
  // const theme = useTheme();
  const access = JSON.parse(localStorage.getItem("detailObject"));

  const navigate = useNavigate();
  const [users, usersSet] = React.useState();
  const [teachers, setTeachers] = React.useState();
  const { Id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [lectureId, setLectureId] = React.useState();
  const [iid, setiid] = React.useState();
  const [fetchMessage, setFetchMessage] = React.useState();
  const [assignedTeacherEmail, setAssignedTeacherEmail] = React.useState([]);
  const [showAccordian, setShowAccordian] = React.useState("0"); //for accordian

  // for edit teacher
  const [editTeacher, setEditTeacher] = useState(false);

  //Popover
  // const [openCo, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [batchName, setBatchName] = React.useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setLectureId(id);
    setAssignedTeacherEmail(value);
    //console.log(lectureId);
  };

  //Adding teacher
  const addteacher = async () => {
    const token = localStorage.getItem("token");

    const authToken = token;
    const data = {
      teacherEmail: assignedTeacherEmail,
    };
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/addTeacherToLecture/${lectureId}`,
        data,
        config
      )
      .then((response) => {
        //console.log(response.data.msg);
        setFetchMessage(response.data.msg);
        if (response.data.msg === "Added the teacher") {
          toast.success("Added the Teacher");
          setTimeout(() => {
            window.location.reload();
          }, 600);
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const [teacherLectureDetails, setTeacherLectureDetails] = React.useState();
  React.useEffect(() => {
    const token = localStorage.getItem("token");
    //Admin Side Lecture Details
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLecturedetails?BatchId=${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // console.log(responseJson.data);
      usersSet(responseJson.data);

      //Teacher Side Lecture Details

      const TeacherDetailsResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getLectureDetailsAssigned?BatchId=${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const TeacherDetailsResponseJson = await TeacherDetailsResponse.json();
      setTeacherLectureDetails(TeacherDetailsResponseJson.data);
      setLoading(false);
      const teacherResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/teacherList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const teacher = await teacherResponse.json();
      setTeachers(teacher.data);
    }

    fetchUsers();
  }, []);

  //Youtube Link
  const [youtubeVideo, setYoutubeVideo] = useState(false);

  // For Go live token storage
  // const reDectUrl = "https://admin-panel.upschindi.online/oauth/callback";
  const reDectUrl = "http://localhost:3000/oauth2callback";
  //  const reDectUrl = "http://dev.upschindi.online/oauth/callback";
  // const reDectUrl = window.location.href;
  const [liveStatus, setLiveStatus] = useState("");
  const [youtubeStatus, setYoutubeStatus] = useState("");
  const [url, setUrl] = useState("");
  const CryptoJS = require("crypto-js");

  const youtubeGomain = async () => {
    // Set a variable in local storage
    localStorage.setItem("Id", Id);
    localStorage.setItem("batchName", batchName);

    const youtubeGo = async () => {
      const token = localStorage.getItem("token");

      const authToken = token;

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL}/adminTeacher/authorize?reDectUrl=${reDectUrl}`,
          config
        );
        const newUrl = response.data.data;
        setUrl(newUrl);
        //console.log(response);
      } catch (error) {
        //console.log(error);
      }
    };

    await youtubeGo();

    if (url !== "") {
      window.open(url, "_blank");
      //window.location.href = url;
    }
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    if (code) {
      // Do something with the authorization code
      //console.log("My Code", code);
    }
  };

  // End of Go Live token Storage

  const role = localStorage.getItem("role");

  // for list
  const [openk, setOpenk] = React.useState(false);

  const handleClickk = () => {
    setOpenk(!openk);
  };

  // //Delete Lecture
  // const handleDelete = async (id) => {
  //     //console.log(id);
  // };
  // //console.log(batchName);

  const [videoPlay, setVideoPlay] = React.useState(false);
  return (
    <>
      {" "}
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color="red" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Lecture">
          <Grid container spacing={1} sx={{ height: "80vh" }}>
            {/* sx={{ height: "100%" , margin :"15px" , boxSizing: 'border-box'}} */}
            <Grid item xs={12} sm={12} md={8}>
              <Box spacing={3} ml={5}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={3}
                  sx={{ width: "90%" }}
                >
                  <Typography className="page-heading">All Lectures</Typography>
                  {/* {role === "Admin" && (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "white",
                        border: '1px solid lightgray',
                        borderRadius: '10px'
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItemButton onClick={handleClickk}>
                        <ListItemText primary="Add Lecture" />
                        {openk ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openk} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton
                            onClick={() =>
                              navigate(
                                `/dashboard/batchDetails/addLecture/${Id}/${batchName}`
                              )
                            }
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary="App live/ All Recorded" />
                          </ListItemButton>
                          <ListItemButton
                            onClick={() => youtubeGomain()}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary="Youtube Live" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  )}
                  {role === "subadmin" && (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItemButton onClick={handleClickk}>
                        <ListItemText primary="Add Lecture" />
                        {openk ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse in={openk} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton
                            onClick={() =>
                              navigate(
                                `/dashboard/batchDetails/addLecture/${Id}/${batchName}`
                              )
                            }
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary="App live/ All Recorded" />
                          </ListItemButton>
                          <ListItemButton
                            onClick={() => youtubeGomain()}
                            sx={{ pl: 4 }}
                          >
                            <ListItemText primary="Youtube Live" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  )} */}
                  {(access?.access.includes("Add Lecture") ||
                    access?.access.includes("all")) && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "20%",
                        alignSelf: "center",
                        background: "#EA506B",
                        "&:hover": { background: "red" },
                      }}
                      onClick={() => {
                        let newName = "";
                        Array.from(batchName).forEach((elem) => {
                          if (elem !== "/") newName += elem;
                          else newName += "_";
                        });
                        navigate(
                          `/dashboard/batchDetails/addLecture/${Id}/${newName}`
                        );
                      }}
                    >
                      Add Lecture
                    </Button>
                  )}
                </Stack>
                <div>
                  {role === "Admin" && (
                    <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                      {users.length > 0 ? (
                        users.map((item, index) => {
                          // debugger;
                          const [teacherName] = item.teacher;

                          // for initials show 1st accordian
                          // if(showAccordian==='0'){
                          //     setShowAccordian('1');
                          //     setiid(item._id);
                          // }

                          let date = item.starting_date.split(" ")[0];
                          let today = new Date();
                          let todayDate =
                            today.getDate() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            today.getFullYear();
                          let check = todayDate.split("-")[0];
                          let checkMonth = todayDate.split("-")[1];

                          let golive;

                          if (check < 10) {
                            if (checkMonth > 9) {
                              let day = "0" + today.getDate();
                              let todayDate =
                                day +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let day = "0" + today.getDate();
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                day + "-" + month + "-" + today.getFullYear();
                              golive = todayDate == date;
                            }
                          } else {
                            if (checkMonth > 9) {
                              let todayDate =
                                today.getDate() +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                today.getDate() +
                                "-" +
                                month +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            }
                          }
                          let newLink = "";
                          // console.log(item?.link);
                          Array.from(item?.link).forEach((elem) => {
                            if (elem !== "/") newLink += elem;
                            else newLink += "@";

                            // console.log('newLin', newLink)
                          });
                          // console.log(item?.link)
                          const encodedYtUrl = encodeURIComponent(newLink);
                          // console.log(encodedYtUrl);
                          // console.log(item.description);
                          // let lectDescription = '';
                          // if( item?.description.includes("<p>")){
                          //   const helperString = item?.description.split(">")[1]
                          //   lectDescription = helperString.split("<")[0];
                          // }else{
                          //   lectDescription = item?.description;
                          // }
                          const helperString = item?.description?.split(">")[1];
                          const lectDescription = helperString?.split("<")[0];
                          // console.log(item);
                          // console.log(`/dashboard/livestream/${item.lecture_title}/${item._id}/${Id}/${lectDescription}`)

                          return (
                            //#region 2
                            <Box
                              key={index}
                              sx={{ marginBottom: "25px", ...AccordionStyle }}
                            >
                              <Box
                                pl={5}
                                pr={3}
                                pt={2}
                                pb={2}
                                onClick={() => {
                                  setiid(item._id);
                                  // setShowAccordian(!showAccordian);
                                  // handleClickCollapse(item._id);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  position: "relative",
                                }}
                              >
                                <Stack spacing={0.5}>
                                  <Typography
                                    color="#FB5259"
                                    sx={{
                                      fontSize: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Lecture {index + 1}{" "}
                                    {item?.lecture_type == "YT"
                                      ? "( YouTube )"
                                      : ""}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Topic: {item.lecture_title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Subject: {item.subject?.title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "15px", fontWeight: "600" }}
                                  >
                                    Date:
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        color: "gray",
                                      }}
                                    >
                                      {" "}
                                      {date}
                                    </span>
                                  </Typography>
                                </Stack>
                                {
                                  //#region 1

                                  item?.lecture_type != "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%", height: "110px" }}
                                    >
                                      {item.LiveOrRecorded == "Live" && (
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "60%",
                                            alignSelf: "center",
                                            background: "#EA506B",
                                            borderRadius: "20px",
                                            "&:hover": { background: "red" },
                                          }}
                                        >
                                          <Link
                                            to={
                                              item.LiveOrRecorded == "Live"
                                                ? // ? `/dashboard/livestream/${item.lecture_title}/${item._id}/${Id}/${item?.description}`
                                                  `/dashboard/livestream/${item.lecture_title}/${item._id}/${Id}/${lectDescription}`
                                                : "/dashboard/app"
                                            }
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {" "}
                                            {item.LiveOrRecorded === "Live"
                                              ? "Go Live"
                                              : "View"}
                                          </Link>
                                        </Button>
                                      )}
                                      {item.LiveOrRecorded == "Recorded" && (
                                        <>
                                          <a
                                            href={item?.material?.fileLoc}
                                            // href={item?.link}
                                            style={{
                                              width: "60%",
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              sx={{
                                                width: "100%",
                                                height: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                            >
                                              View
                                            </Button>
                                          </a>
                                        </>
                                      )}
                                    </Stack>
                                  )
                                  //#endregion 1
                                }

                                {
                                  //#region go live button 1
                                  item?.lecture_type == "YT" && (
                                    <div className="button-align">
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space"
                                        sx={{ width: "100%", height: "100%" }}
                                      >
                                        <a
                                          // href={item?.link}
                                          // href={`/dashboard/youtubeLive/${item?._id}/${item?.lecture_title}/?ytUrl=${CryptoJS.AES.encrypt(item?.link, 'myKey').toString().toLowerCase()}`}
                                          href={`/dashboard/youtubeLive/${item?._id}/${item?.lecture_title}/${lectDescription}/${encodedYtUrl}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "60%",
                                            color: "white",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {item.LiveOrRecorded == "Recorded" ? (
                                            <Button
                                              variant="contained"
                                              // disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                              onClick={() => setVideoPlay(true)}
                                            >
                                              View
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                              // onClick={() => setVideoPlay(true)}
                                              // onClick={() => {
                                              //   // console.log(`${item?._id}/${item?.link}/${item?.lecture_title}`);
                                              //   console.log(`/dashboard/youtubeLive/${item?._id}/${item?.link}/${item?.lecture_title}`);
                                              //   // navigate(`/dashboard/youtubeLive/${item?._id}/${item?.link}/${item?.lecture_title}`)
                                              // }}
                                            >
                                              Go Live
                                            </Button>
                                          )}
                                        </a>
                                      </Stack>
                                    </div>
                                  )
                                  //#endregion
                                }
                              </Box>

                              <Collapse
                                in={item._id == iid}
                                timeout="auto"
                                unmountOnExit
                              >
                                {item._id == iid && (
                                  <Stack
                                    pl={5}
                                    pr={5}
                                    spacing={1}
                                    sx={{
                                      bgcolor: "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "0 0 18px 18px",
                                    }}
                                  >
                                    <Typography mt={2}>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Assigned Teacher :
                                      </span>{" "}
                                      {teacherName
                                        ? teacherName?.FullName
                                        : "No Teacher Assigned"}
                                    </Typography>
                                    <Typography>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Description :
                                      </span>{" "}
                                      {item.description.replace(
                                        /(<([^>]+)>)/gi,
                                        ""
                                      )}
                                    </Typography>
                                    <Stack direction="row" spacing={3} pb={3}>
                                      {!editTeacher ? (
                                        <>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: "#EA506B",
                                              "&:hover": { background: "red" },
                                            }}
                                            onClick={setEditTeacher(
                                              !editTeacher
                                            )}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Edit Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <TextField
                                            label="Assign Teacher"
                                            InputLabelProps={{
                                              style: { color: "black" },
                                            }}
                                            sx={{ width: "100%" }}
                                            onChange={(e) =>
                                              handleChange(e, item._id)
                                            }
                                            select
                                          >
                                            {teachers?.map((teac, i) => {
                                              return (
                                                <MenuItem
                                                  key={i}
                                                  value={teac.email}
                                                >
                                                  {teac.FullName}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: "#EA506B",
                                              "&:hover": { background: "red" },
                                            }}
                                            onClick={addteacher}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Update Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      )}
                                    </Stack>
                                  </Stack>
                                )}
                              </Collapse>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "47px",
                                  right: "1%",
                                }}
                              >
                                {" "}
                                <UserMoreMenu
                                  title="BatchDetails"
                                  lectId={item._id}
                                  id={item?._id}
                                  req="deleteLectureDetails"
                                  batchId={Id}
                                  setLoading={setLoading}
                                />
                              </Box>
                            </Box>
                            //  #endregion 2
                          );
                        })
                      ) : (
                        <Typography variant="h4">No Lecture</Typography>
                      )}
                    </Stack>
                  )}
                  {role === "subadmin" && (
                    <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                      {users.length > 0 ? (
                        users.map((item, index) => {
                          // console.log(item);
                          debugger;
                          const [teacherName] = item.teacher;

                          // for initials show 1st accordian
                          // if(showAccordian==='0'){
                          //     setShowAccordian('1');
                          //     setiid(item._id);
                          // }

                          let date = item.starting_date.split(" ")[0];
                          let today = new Date();
                          let todayDate =
                            today.getDate() +
                            "-" +
                            (today.getMonth() + 1) +
                            "-" +
                            today.getFullYear();
                          let check = todayDate.split("-")[0];
                          let checkMonth = todayDate.split("-")[1];

                          let golive;

                          if (check < 10) {
                            if (checkMonth > 9) {
                              let day = "0" + today.getDate();
                              let todayDate =
                                day +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let day = "0" + today.getDate();
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                day + "-" + month + "-" + today.getFullYear();
                              golive = todayDate == date;
                            }
                          } else {
                            if (checkMonth > 9) {
                              let todayDate =
                                today.getDate() +
                                "-" +
                                (today.getMonth() + 1) +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            } else {
                              let month = "0" + (today.getMonth() + 1);
                              let todayDate =
                                today.getDate() +
                                "-" +
                                month +
                                "-" +
                                today.getFullYear();
                              golive = todayDate == date;
                            }
                          }
                          return (
                            //#region 2
                            <Box
                              key={index}
                              sx={{ marginBottom: "25px", ...AccordionStyle }}
                            >
                              <Box
                                pl={5}
                                pr={3}
                                pt={2}
                                pb={2}
                                onClick={() => {
                                  setiid(item._id);
                                  // setShowAccordian(!showAccordian);
                                  // handleClickCollapse(item._id);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  position: "relative",
                                }}
                              >
                                <Stack spacing={0.5}>
                                  <Typography
                                    color="#FB5259"
                                    sx={{
                                      fontSize: "22px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Lecture {index + 1}{" "}
                                    {item?.lecture_type == "YT"
                                      ? "( YouTube )"
                                      : ""}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Topic: {item.lecture_title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "18px", fontWeight: "500" }}
                                  >
                                    Subject: {item.subject?.title}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: "15px", fontWeight: "600" }}
                                  >
                                    Date:
                                    <span
                                      style={{
                                        fontWeight: "400",
                                        color: "gray",
                                      }}
                                    >
                                      {" "}
                                      {date}
                                    </span>
                                  </Typography>
                                </Stack>
                                {
                                  //#region 1

                                  item?.lecture_type != "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%", height: "100%" }}
                                    >
                                      {item.LiveOrRecorded == "Live" && (
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "60%",
                                            alignSelf: "center",
                                            background: "#EA506B",
                                            borderRadius: "20px",
                                            "&:hover": { background: "red" },
                                          }}
                                        >
                                          <Link
                                            to={
                                              item.LiveOrRecorded == "Live"
                                                ? `/dashboard/livestream/${item.lecture_title}/${item._id}/${Id}/${item?.description}`
                                                : "/dashboard/app"
                                            }
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {" "}
                                            {item.LiveOrRecorded === "Live"
                                              ? "Go Live"
                                              : "View"}
                                          </Link>
                                        </Button>
                                      )}
                                      {item.LiveOrRecorded == "Recorded" && (
                                        <>
                                          <a
                                            href={item?.material?.fileLoc}
                                            // href={item?.link}
                                            style={{
                                              width: "60%",
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                            >
                                              View
                                            </Button>
                                          </a>
                                        </>
                                      )}
                                    </Stack>
                                  )
                                  //#endregion 1
                                }

                                {
                                  //#region go live button 1
                                  item?.lecture_type == "YT" && (
                                    <div className="button-align">
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ width: "100%", height: "100%" }}
                                      >
                                        <a
                                          href={item?.link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "60%",
                                            color: "white",
                                            textDecoration: "none",
                                          }}
                                        >
                                          {item.LiveOrRecorded == "Recorded" ? (
                                            <Button
                                              variant="contained"
                                              // disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                height: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                              onClick={() => setVideoPlay(true)}
                                            >
                                              View
                                            </Button>
                                          ) : (
                                            <Button
                                              variant="contained"
                                              disabled={!golive}
                                              sx={{
                                                width: "100%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                              onClick={() => setVideoPlay(true)}
                                            >
                                              Go Live
                                            </Button>
                                          )}
                                        </a>
                                      </Stack>
                                    </div>
                                  )
                                  //#endregion
                                }
                              </Box>

                              <Collapse
                                in={item._id == iid}
                                timeout="auto"
                                unmountOnExit
                              >
                                {item._id == iid && (
                                  <Stack
                                    pl={5}
                                    pr={5}
                                    spacing={1}
                                    sx={{
                                      bgcolor: "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "0 0 18px 18px",
                                    }}
                                  >
                                    <Typography mt={2}>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Assigned Teacher :
                                      </span>{" "}
                                      {teacherName
                                        ? teacherName?.FullName
                                        : "No Teacher Assigned"}
                                    </Typography>
                                    <Typography>
                                      {" "}
                                      <span style={{ fontWeight: "700" }}>
                                        Description :
                                      </span>{" "}
                                      {item.description.replace(
                                        /(<([^>]+)>)/gi,
                                        ""
                                      )}
                                    </Typography>
                                    <Stack direction="row" spacing={3} pb={3}>
                                      {!editTeacher ? (
                                        <>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: "#EA506B",
                                              "&:hover": { background: "red" },
                                            }}
                                            onClick={setEditTeacher(
                                              !editTeacher
                                            )}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Edit Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <TextField
                                            label="Assign Teacher"
                                            InputLabelProps={{
                                              style: { color: "black" },
                                            }}
                                            sx={{ width: "100%" }}
                                            onChange={(e) =>
                                              handleChange(e, item._id)
                                            }
                                            select
                                          >
                                            {teachers?.map((teac, i) => {
                                              return (
                                                <MenuItem
                                                  key={i}
                                                  value={teac.email}
                                                >
                                                  {teac.FullName}
                                                </MenuItem>
                                              );
                                            })}
                                          </TextField>
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "40%",
                                              alignSelf: "center",
                                              background: "#EA506B",
                                              "&:hover": { background: "red" },
                                            }}
                                            onClick={addteacher}
                                          >
                                            <Link
                                              to="#"
                                              style={{
                                                color: "white",
                                                textDecoration: "none",
                                              }}
                                            >
                                              {" "}
                                              Update Teacher
                                            </Link>
                                          </Button>
                                        </>
                                      )}
                                    </Stack>
                                  </Stack>
                                )}
                              </Collapse>
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "47px",
                                  right: "1%",
                                }}
                              >
                                {" "}
                                <UserMoreMenu
                                  title="BatchDetails"
                                  lectId={item._id}
                                  id={item?._id}
                                  req="deleteLectureDetails"
                                  batchId={Id}
                                  setLoading={setLoading}
                                />
                              </Box>
                            </Box>
                            //  #endregion 2
                          );
                        })
                      ) : (
                        <Typography variant="h4">No Lecture</Typography>
                      )}
                    </Stack>
                  )}
                  {
                    //#region 3
                    role == "Teacher" && (
                      <Stack sx={{ width: "100%", borderRadius: "10px" }}>
                        {teacherLectureDetails?.length > 0 ? (
                          teacherLectureDetails.map((item, index) => {
                            const [teacherName] = item.teacher;

                            {
                              /* //console.log(item.starting_date); */
                            }
                            let date = item.starting_date.split(" ")[0];
                            let today = new Date();
                            let todayDate =
                              today.getDate() +
                              "-" +
                              (today.getMonth() + 1) +
                              "-" +
                              today.getFullYear();
                            let check = todayDate.split("-")[0];
                            let checkMonth = todayDate.split("-")[1];

                            let golive;

                            if (check < 10) {
                              if (checkMonth > 9) {
                                let day = "0" + today.getDate();
                                let todayDate =
                                  day +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              } else {
                                let day = "0" + today.getDate();
                                let month = "0" + (today.getMonth() + 1);
                                let todayDate =
                                  day + "-" + month + "-" + today.getFullYear();
                                golive = todayDate == date;
                              }
                            } else {
                              if (checkMonth > 9) {
                                let todayDate =
                                  today.getDate() +
                                  "-" +
                                  (today.getMonth() + 1) +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              } else {
                                let month = "0" + (today.getMonth() + 1);
                                let todayDate =
                                  today.getDate() +
                                  "-" +
                                  month +
                                  "-" +
                                  today.getFullYear();
                                golive = todayDate == date;
                              }
                            }
                            {
                              /* //console.log(`/dashboard/batch/batchDetails/recordedLecture/${item?.material?.fileLoc}`) */
                            }
                            //console.log(golive);
                            return (
                              <Box
                                key={index}
                                sx={{ marginBottom: "25px", ...AccordionStyle }}
                              >
                                <Box
                                  pl={5}
                                  pr={3}
                                  pt={2}
                                  pb={2}
                                  onClick={() => {
                                    setiid(item._id);
                                    // setShowAccordian(!showAccordian);
                                    // handleClickCollapse();
                                  }}
                                  sx={{
                                    cursor: "pointer",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    position: "relative",
                                  }}
                                >
                                  <Stack spacing={0.5}>
                                    <Typography
                                      color="#FB5259"
                                      sx={{
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Lecture {index + 1}{" "}
                                      {item?.lecture_type == "YT"
                                        ? "( YouTube )"
                                        : ""}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "18px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Topic: {item.lecture_title}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Date:
                                      <span
                                        style={{
                                          fontWeight: "400",
                                          color: "gray",
                                        }}
                                      >
                                        {" "}
                                        {date}
                                      </span>
                                    </Typography>
                                  </Stack>
                                  {item?.lecture_type != "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%" }}
                                    >
                                      {item.LiveOrRecorded == "Live" && (
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "60%",
                                            alignSelf: "center",
                                            background: "#EA506B",
                                            borderRadius: "20px",
                                            "&:hover": { background: "red" },
                                          }}
                                        >
                                          <Link
                                            to={
                                              item.LiveOrRecorded == "Live"
                                                ? `/dashboard/livestream/${item.lecture_title}/${item._id}/${Id}/${item?.description}`
                                                : "/dashboard/app"
                                            }
                                            style={{
                                              color: "white",
                                              textDecoration: "none",
                                            }}
                                          >
                                            {" "}
                                            {item.LiveOrRecorded === "Live"
                                              ? "Go Live"
                                              : "View"}
                                          </Link>
                                        </Button>
                                      )}
                                      {item.LiveOrRecorded == "Recorded" && (
                                        <a
                                          href={item?.material?.fileLoc}
                                          style={{
                                            width: "60%",
                                            color: "white",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <Button
                                            variant="contained"
                                            sx={{
                                              width: "100%",
                                              alignSelf: "center",
                                              background: "#EA506B",
                                              borderRadius: "20px",
                                              "&:hover": { background: "red" },
                                            }}
                                          >
                                            View
                                          </Button>
                                        </a>
                                      )}
                                    </Stack>
                                  )}
                                  {item?.lecture_type == "YT" && (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{ width: "25%" }}
                                    >
                                      <a
                                        href={item?.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                          width: "60%",
                                          color: "white",
                                          textDecoration: "none",
                                        }}
                                      >
                                        <Button
                                          variant="contained"
                                          disabled={!golive}
                                          sx={{
                                            width: "100%",
                                            alignSelf: "center",
                                            background: "#EA506B",
                                            borderRadius: "20px",
                                            "&:hover": { background: "red" },
                                          }}
                                          onClick={() => setVideoPlay(true)}
                                        >
                                          Go Live 1
                                        </Button>
                                      </a>
                                    </Stack>
                                  )}
                                </Box>

                                <Collapse
                                  in={item._id == iid}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  {item._id == iid && (
                                    <Stack
                                      pl={5}
                                      pr={5}
                                      spacing={1}
                                      sx={{
                                        bgcolor: "rgba(145, 158, 171, 0.24)",
                                        borderRadius: "0 0 18px 18px",
                                      }}
                                    >
                                      <Typography mt={2}>
                                        {" "}
                                        <span style={{ fontWeight: "700" }}>
                                          Assigned Teacher :
                                        </span>{" "}
                                        {teacherName
                                          ? teacherName?.FullName
                                          : "No Teacher Assigned"}
                                      </Typography>
                                      <Typography>
                                        {" "}
                                        <span style={{ fontWeight: "700" }}>
                                          Description :
                                        </span>{" "}
                                        {item.description}
                                      </Typography>
                                      <Stack direction="row" spacing={3} pb={3}>
                                        {teacherName ? (
                                          ""
                                        ) : (
                                          <>
                                            <TextField
                                              label="Assign Teacher"
                                              InputLabelProps={{
                                                style: { color: "black" },
                                              }}
                                              sx={{ width: "100%" }}
                                              onChange={(e) =>
                                                handleChange(e, item._id)
                                              }
                                              select
                                            >
                                              {teachers?.map((teac, i) => {
                                                return (
                                                  <MenuItem
                                                    key={i}
                                                    value={teac.email}
                                                  >
                                                    {teac.FullName}
                                                  </MenuItem>
                                                );
                                              })}
                                            </TextField>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                width: "40%",
                                                alignSelf: "center",
                                                background: "#EA506B",
                                                "&:hover": {
                                                  background: "red",
                                                },
                                              }}
                                              onClick={addteacher}
                                            >
                                              <Link
                                                to="#"
                                                style={{
                                                  color: "white",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                {" "}
                                                Add Teacher
                                              </Link>
                                            </Button>
                                          </>
                                        )}
                                      </Stack>
                                    </Stack>
                                  )}
                                </Collapse>
                                <Box
                                  sx={{
                                    position: "absolute",
                                    top: "47px",
                                    right: "1%",
                                  }}
                                >
                                  {" "}
                                  <UserMoreMenu
                                    title="BatchDetails"
                                    lectId={item._id}
                                    id={item?._id}
                                    req="deleteLectureDetails"
                                    batchId={Id}
                                    setLoading={setLoading}
                                  />
                                </Box>
                              </Box>
                            );
                          })
                        ) : (
                          <Typography variant="h4">No Lecture</Typography>
                        )}
                      </Stack>
                    )
                    // #endregion3
                  }
                  <ToastContainer
                    position="bottom-center"
                    autoClose={1000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />

                  {/* <Typography variant='h4' sx={{ color: 'green', textTransform: 'capitalize', textAlign: 'center', width: '70%' }}>{fetchMessage}</Typography> */}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {/* <Box spacing={3} ml={5}>
                <BatchDetailsCard batchId={Id} setBatchName={setBatchName} />
              </Box> */}
              <BatchDetailsCard batchId={Id} setBatchName={setBatchName} />
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};

export default BatchDetails;
