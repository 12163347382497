import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { debounce } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F67382",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#e56372",
  },
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const NewCommunity = () => {
  const [communityName, setCommunityName] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [maxMembers, setMaxMembers] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalUsers, setTotalUsers] = useState(0);
  const [communityType, setCommunityType] = useState("");

  const navigate = useNavigate();

  const handleImageChange = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleCheckboxChange = (userId) => {
    const selectedIndex = selectedMembers.indexOf(userId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedMembers, userId];
    } else {
      newSelected = selectedMembers.filter((id) => id !== userId);
    }

    setSelectedMembers(newSelected);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", communityName);
    formData.append("file", imageFile);
    formData.append("maximumMembers", maxMembers);
    formData.append("description", description);
    formData.append("members", JSON.stringify(selectedMembers));
    formData.append("type", communityType); // Append the type to formData

    try {
            const token = localStorage.getItem("token");

      const response = await axios.post(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/add-community`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Community created successfully!", response.data);
      toast.success("Community created successfully!");
      navigate("/dashboard/community");
    } catch (error) {
      console.error("Error creating community:", error.message);
    }
  };

  const fetchUsers = async (search = "", page = 1, limit = 5) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails`,
        {
          params: { search, page, limit },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUserData(response.data.data);
      setTotalUsers(response.data.count);
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      setUserData([]);
      setTotalUsers(0);
    }
  };

  useEffect(() => {
    fetchUsers(searchTerm, page + 1, rowsPerPage);
  }, [searchTerm, page, rowsPerPage]);

  const handleSearchChange = debounce((event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  }, 500);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box p={6}>
      <Typography variant="h4" gutterBottom>
        New Community
      </Typography>

      <form onSubmit={handleFormSubmit}>
        <CustomTextField
          fullWidth
          variant="outlined"
          label="Community Name"
          placeholder="Enter Community Name"
          value={communityName}
          onChange={(e) => setCommunityName(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <TextField
          fullWidth
          variant="outlined"
          label="Supported Files .jpg, .png"
          placeholder="Choose Image"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          InputProps={{
            startAdornment: (
              <input
                type="file"
                style={{
                  width: 0,
                  height: 0,
                  opacity: 0,
                  position: "absolute",
                }}
                accept="image/*"
                onChange={handleImageChange}
              />
            ),
          }}
          sx={{ marginBottom: 2 }}
        />

        <CustomTextField
          fullWidth
          variant="outlined"
          label="Maximum Members"
          placeholder="100 Members"
          value={maxMembers}
          onChange={(e) => setMaxMembers(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <CustomTextField
          fullWidth
          variant="outlined"
          label="Description"
          placeholder="Enter Description Here"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <FormControl fullWidth variant="outlined" sx={{ marginBottom: 2 }}>
          <InputLabel id="community-type-label">Type</InputLabel>
          <Select
            labelId="community-type-label"
            value={communityType}
            onChange={(e) => setCommunityType(e.target.value)}
            label="Type"
          >
            <MenuItem value="public">Public</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
            <MenuItem value="private">Private</MenuItem>
          </Select>
        </FormControl>

        <Typography variant="h6" gutterBottom>
          Members
        </Typography>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{ marginBottom: 2 }}
        >
          <Typography variant="body1" color="textSecondary">
            {totalUsers} Total Users Available
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search Member"
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon style={{ color: "#F67382" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Mobile Number</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData &&
                userData.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedMembers.includes(user.id)}
                        onChange={() => handleCheckboxChange(user.id)}
                      />
                    </TableCell>
                    <TableCell>{user.StudentName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.mobileNumber}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalUsers}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Box display="flex" justifyContent="center">
          <CustomButton type="submit" variant="contained">
            Create Community
          </CustomButton>
        </Box>
      </form>

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 9999 }}
      />
    </Box>
  );
};

export default NewCommunity;
