import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';

// ----------------------------------------------------------------------

export default function AddSubject() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        is_active: Yup.boolean().required('Status required'),
        // lastName: Yup.string().required('Last name required'),
        // category: Yup.string().required('Category is required'),
        // data: Yup.string().required('Data is required'),
        // is_active: Yup.string().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        is_active: '',
        // category: '',
        // data: '',
        // is_active: null,
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        setLoading(true);
        console.log(data);
        let token = localStorage.getItem("token");
        // //console.log(token);
        // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RpbmdAZ21haWwuY29tIiwiaWF0IjoxNjYzOTQzMTc5LCJleHAiOjE2NjM5NDQ5Nzl9.ix9ZXfVEqHrH20-bnMxHQD9c4Z0Z3plfkJWu3YiisKY'
        try {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addSubject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            const result = await res.json()
            toast.success(result.msg)

            if (result.msg === 'The Subject Details added successfully') {
                setLoading(false)
                reset()
                // navigate('/dashboard', { replaced: true })
            }
        } catch (error) {
            //console.log(error);
        }

    };

    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Subject</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>


                    <RHFTextField name="title" label="Title" />

                    <RHFTextField name="is_active" label="Status" type='checkbox' select>
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                    </RHFTextField>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: '#EA506B', marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Stack>
    </>
    );
}
