import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  TextField,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styled } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import AssignStudentsDialog from "./AssignMember";

const CustomCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    backgroundColor: "#F67382",
    borderRadius: 3,
  },
  "& .MuiSvgIcon-root path": {
    fill: "white",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
});
const roleOptions = [
  { value: "member", label: "Member" },
  { value: "admin", label: "Admin" },
];
const OptionsComponent = ({
  role,
  memberId,
  communityId,
  permissions,
  onClose,
}) => {
  const [permissionState, setPermissionState] = useState({
    sendMessage: false,
    sendMedia: false,
    photo: false,
    video: false,
    gif: false,
    audio: false,
    links: false,
    polls: false,
    pinMessage: false,
    comments: false,
    pdf: false,
  });


  /*
  photo: true,
        video: true,
        gif: true,
        audio: true,
        links: true,
        polls: true,
        pinMessage: true,
        comments: true,
        sendMessage: true,
        isBlocked: false,
  */

  const [selectedRole, setSelectedRole] = useState(role || "member");

  useEffect(() => {
    if (permissions && selectedRole !== "admin") {
      setPermissionState({
        sendMessage: permissions.sendMessage || false,
        sendMedia: permissions.sendMedia || false,
        photo: permissions.photo || false,
        video: permissions.video || false,
        gif: permissions.gif || false,
        audio: permissions.audio || false,
        links: permissions.links || false,
        polls: permissions.polls || false,
        pinMessage: permissions.pinMessage || false,
        comments: permissions.comments || false,
        pdf: permissions.pdf || false,
      });
    } else {
      setPermissionState({
        sendMessage: false,
        sendMedia: false,
        photo: false,
        video: false,
        gif: false,
        audio: false,
        links: false,
        polls: false,
        pinMessage: false,
        comments: false,
        pdf:false,
      });
    }
  }, [permissions, selectedRole]);

  const handlePermissionChange = (event) => {
    setPermissionState({
      ...permissionState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleUpdatePermissions = () => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/update-permissions`,
        {
          memberId,
          communityId,
          updates: {
            ...permissionState,
            role: selectedRole,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Permissions updated successfully", response.data);
        onClose();
        toast.success("Permission Updated Successfully")
      })
      .catch((error) => {
        console.error("Error updating permissions", error);
      });
  };




  const handleRemoveMember = () => {
    const token = localStorage.getItem("token");

    axios
      .put(
        `${process.env.REACT_APP_LIVE_URL}/admin-community/remove-member/${communityId}`,
        { memberId: [memberId] },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("Member removed successfully", response.data);
        onClose();
        toast.success("Member removed successfully")
      })
      .catch((error) => {
        console.error("Error removing member", error);
      });
  };

  return (
    <Box
      sx={{
        width: 300,
        bgcolor: "background.paper",
        borderRadius: 2,
        p: 2,
      }}
    >
      <Typography variant="h6" sx={{ mb: 2 }}>
        Options
      </Typography>
      <FormGroup>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Typography variant="subtitle1" sx={{ color: "#F67382" }}>
            Role
          </Typography>
          <Select
            value={selectedRole}
            onChange={handleRoleChange}
            variant="outlined"
            size="small"

            sx={{ width: 100, bgcolor: "white", borderRadius: 1 }}
          >
            {/* Replace roleOptions with your actual role options */}
            {roleOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Divider sx={{ mb: 2 }} />

        {selectedRole !== "admin" && (
          <>
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={permissionState.sendMessage}
                  onChange={handlePermissionChange}
                  name="sendMessage"
                />
              }
              label="Send Text Messages"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={permissionState.sendMedia}
                  onChange={handlePermissionChange}
                  name="sendMedia"
                />
              }
              label="Send Media"
            />
            {permissionState.sendMedia && (
              <>
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.photo}
                      onChange={handlePermissionChange}
                      name="photo"
                    />
                  }
                  label="Photos"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.video}
                      onChange={handlePermissionChange}
                      name="video"
                    />
                  }
                  label="Videos"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.gif}
                      onChange={handlePermissionChange}
                      name="gif"
                    />
                  }
                  label="Stickers & Gifs"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.audio}
                      onChange={handlePermissionChange}
                      name="audio"
                    />
                  }
                  label="Audio"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.links}
                      onChange={handlePermissionChange}
                      name="links"
                    />
                  }
                  label="Links"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.pdf}
                      onChange={handlePermissionChange}
                      name="pdf"
                    />
                  }
                  label="PDF"
                  sx={{ ml: 2 }}
                />
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={permissionState.polls}
                      onChange={handlePermissionChange}
                      name="polls"
                    />
                  }
                  label="Polls"
                  sx={{ ml: 2 }}
                />
              </>
            )}
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={permissionState.pinMessage}
                  onChange={handlePermissionChange}
                  name="pinMessage"
                />
              }
              label="Pin Messages"
            />
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={permissionState.comments}
                  onChange={handlePermissionChange}
                  name="comments"
                />
              }
              label="Allow Comments"
            />
          </>
        )}

        <Button
          variant="contained"
          sx={{
            bgcolor: "#F67382",
            color: "white",
            mb: 1,
            width: "70%",
            marginTop: "20px",
          }}
          onClick={handleUpdatePermissions}
        >
          Update Permissions
        </Button>

        <Button
          variant="contained"
          sx={{
            bgcolor: "#F67382",
            color: "white",
            width: "70%",
            marginTop: "20px",
          }}
          onClick={handleRemoveMember}
        >
          {selectedRole === "admin" ? "Remove Admin" : "Remove Member"}
        </Button>
      </FormGroup>
    </Box>
  );
};




const MemberList = ({ title, count, members, onOptionsClick }) => (
  <Box flex={1} p={2}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6" sx={{ color: "#F67382" }}>
        {title}
      </Typography>
      <Typography variant="subtitle1">{count}</Typography>
    </Box>
    <List>
      {members.length > 0 ? (
        members.map((member, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton edge="end" onClick={() => onOptionsClick(member)}>
                <MoreVertIcon />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar src={member.user.profilePhoto} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" component="span">
                    {member.user.FullName}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          No {title.toLowerCase()} available
        </Typography>
      )}
    </List>
  </Box>
);

const CommunityDetails = () => {
  const [openOptions, setOpenOptions] = useState(false);
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [members, setMembers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [membersCount, setMembersCount] = useState(0);
  const [adminsCount, setAdminsCount] = useState(0);
  const [communityName, setCommunityName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchMembersData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL}/admin-community/get-members-by-community/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status) {
          const membersData = response.data.data;
          const adminsData = membersData.filter(
            (member) => member.permissions.role === "admin"
          );
          const membersDataFiltered = membersData.filter(
            (member) =>
              member.permissions.role === "" ||
              member.permissions.role === "member"
          );
          setMembers(membersDataFiltered);
          setAdmins(adminsData);
          setMembersCount(membersDataFiltered.length);
          setAdminsCount(adminsData.length);
          setCommunityName(response.data.communityName || "Community");
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching members data", error);
      }
    };

    fetchMembersData();
  }, [id,openOptions]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionsClick = (member) => {
    setSelectedMember(member);
    setOpenOptions(true);
  };

  const handleCloseOptions = () => {
    setOpenOptions(false);
    setSelectedMember(null);
  };

  const handleOpenAssignDialog = () => {
    setOpenAssignDialog(true);
  };

  const handleCloseAssignDialog = () => {
    setOpenAssignDialog(false);
  };

  const filteredMembers = members.filter((member) =>
    member.user.FullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredAdmins = admins.filter((admin) =>
    admin.user.FullName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4">{communityName}</Typography>
        <Button
          variant="contained"
          sx={{ bgcolor: "#F67382", color: "white" }}
          onClick={handleOpenAssignDialog}
        >
          Assign Members
        </Button>
      </Box>
      <Box
        my={2}
        p={2}
        bgcolor="pink"
        borderRadius={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">
          {filteredMembers.length + filteredAdmins.length} Users
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search Member"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ bgcolor: "white", borderRadius: 1 }}
        />
      </Box>
      <Divider />
      <Box display="flex">
        <MemberList
          title="Admins"
          count={filteredAdmins.length}
          members={filteredAdmins}
          onOptionsClick={handleOptionsClick}
        />
        <Divider orientation="vertical" flexItem />
        <MemberList
          title="Members"
          count={filteredMembers.length}
          members={filteredMembers}
          onOptionsClick={handleOptionsClick}
        />
      </Box>

      <Dialog
        open={openOptions}
        onClose={handleCloseOptions}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Options</DialogTitle>
        <DialogContent>
          <OptionsComponent
            role={selectedMember?.permissions.role}
            memberId={selectedMember?.user._id}
            communityId={id}
            permissions={selectedMember?.permissions}
            onClose={handleCloseOptions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOptions}>Close</Button>
        </DialogActions>
      </Dialog>

      <AssignStudentsDialog
        open={openAssignDialog}
        onClose={handleCloseAssignDialog}
        communityId={id}
      />
    </Box>
  );
};

export default CommunityDetails;
