import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, Navigate, useLocation, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import { useContext } from 'react';
import { User } from '../../context/context';
import { replace } from 'lodash';
import { useState } from 'react';
// import SyncLoader from "react-spinners/SyncLoader";
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: 'rgba(255, 137, 158, 0.12)',
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const navigate = useNavigate()
  const name = localStorage.getItem("details")
  const role = localStorage.getItem("role")
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const [loading, setLoading] = useState()
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleLogout = () => {
    setLoading(true)
    setTimeout(() => navigate('/'), 1000)
    setTimeout(() => {
      localStorage.removeItem("token")
      localStorage.removeItem("isLoggedIn")
      localStorage.removeItem("details")
      localStorage.removeItem("detailObject")
      localStorage.removeItem("role")
      localStorage.removeItem("id")
      localStorage.removeItem("idOfAdmin")
      localStorage.removeItem("accessArray")
    }, 800)
  }
  const details = JSON.parse(localStorage.getItem('detailObject'))

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column', },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/profile">
          <AccountStyle>
            <Avatar src={details?.profilePhoto} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <LoadingButton target="_blank" variant='contained' loading={loading} sx={{
            background: 'transparent', color: '#EA506B', border: '1px solid #EA506B', width: "80%", marginTop: '-80px', '&:hover': {
              background: "#EA506B", color: 'white'

            }
          }}
            onClick={handleLogout}>
            Logout
          </LoadingButton>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <>
      {/* {loading ? (<Stack justifyContent='center' alignItems='center' sx={{ position: 'absolute', height: '100vh', width: '100%', }}> <SyncLoader color='red' loading={loading} size={20} /></Stack>) : ''} */}
      <RootStyle>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </RootStyle></>

  );
}
